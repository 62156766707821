import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const Hint = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const COLUMN_NAMES = {
  NA: 'Not Applicable',
  REQUIRED: 'Required',
  OPTIONAL: 'Optional',
};

export const ROWSPERPAGE = [10, 15, 25, 50, 100];
// export const ERRORS = {
//   TOTAL_QTY: "Total quantity can not be null or 0",
//   QTY: "Available quantity can not be null",
//   QTY_INVALID: "Available quantity can not be more than total quantity",
// };

export const PAGES = {
  INVENTORY: 'Inventory Management',
  EQUIPMENT: 'Equipment',
  LOCATION: 'Locations',
};

export const COLUMNS = {
  ETY_NAME: 'EntityName',
  ETY_TYPE: 'EntityType',
  LEVEL: 'Level',
  ETY_STATUS: 'EntityStatus',
  ETY_COMMENT: 'EntityComment',
  // TOTAL_QTY: "TotalQuantity",
  // QTY: "AvailableQuantity",
  CLONE: 'Clone',
};

export const LABELS = {
  ETY_NAME: 'Name',
  ETY_TYPE: 'Type',
  LEVEL: 'Level',
  ETY_STATUS: 'Status',
  ETY_COMMENT: 'Comments',
  // TOTAL_QTY: "Quantity",
  // QTY: "Available Quantity",
  CLONE: 'Clone',
};

export const LINKS = {
  HOME: 0,
  EQUIPMENT: 1,
  LOCATION: 2,
  MATERIAL: 3,
  OPERATIONS: 4,
  ADMIN: 5,
  REPORTS: 6,
  RELEASENOTES: 7,
  KIOSK: 8,
  WORKFLOW: 9,
  CORPSERVICE: 10,
};

export const BINARY_MSG = {
  YES: ' Updated to True',
  NO: ' Updated to False',
};
