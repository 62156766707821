const BuildTypes = (filters) => {
  //     AND t.EntityType IN ('SSD', 'Handler', 'Tester')
  const types = filters.filter((x) => x.key === 'EntityType');
  let typeFilter = types.map((x) => "'" + x.value + "'").toString();
  return types.length ? 'AND t.EntityType IN (' + typeFilter + ')' : '';
};

const BuildLocations = (filters) => {
  //     AND v.Path LIKE '%test%'
  const locations = filters.filter((x) => x.key === 'Location');
  let locationFilter = locations
    .map((x) => "v.Path LIKE '%" + x.value + "%'")
    .join(' OR ');
  return locations.length ? 'AND (' + locationFilter + ')' : '';
};

const BuildColumns = (cols) => {
  //     AND mt.EntityMetaType_DBID IN (71, 124, 130, 110)
  return cols.length
    ? 'AND (mt.EntityMetaType_DBID IN (' +
        cols.toString() +
        ") OR mt.EntityMetaType = 'EntityStatus')"
    : '';
};

const BuildText = (text, cols, params) => {
  // AND (
  //     MATCH(e.EntityName) AGAINST ('*${params.text}*' IN BOOLEAN MODE)
  //     OR MATCH(m.EntityMetaValue) AGAINST ('*${params.text}*' IN BOOLEAN MODE)
  //     OR MATCH(t.EntityType) AGAINST ('*${params.text}*' IN BOOLEAN MODE)
  //     OR v.Path LIKE '%${params.text}%'
  //     )

  // remove text search on name for material and epo
  return text
    ? `   AND (${
        params?.level !== params.epoLevel &&
        params?.level !== params.materialLevel
          ? `e.Entity_DBID IN (SELECT Entity_DBID FROM tEntity WHERE EntityName LIKE'%${text}%') OR `
          : params?.level === params.materialLevel
            ? `CONCAT('MAT',LPAD(e.Entity_DBID,8,'0')) LIKE '%${text}%' OR e.Entity_DBID LIKE '%${text}%' OR`
            : params?.level === params.epoLevel
              ? `CONCAT('EPO',LPAD(e.Entity_DBID,8,'0')) LIKE '%${text}%' OR e.Entity_DBID LIKE '%${text}%' OR`
              : ''
      }
        e.Entity_DBID IN (SELECT Entity_DBID FROM tEntityMeta WHERE EntityMetaValue LIKE'%${text}%' AND EntityMetaType_DBID IN (${cols.toString()}))
          OR e.Entity_DBID IN (SELECT Entity_DBID FROM tEntity e INNER JOIN tEntityType t ON t.EntityType_DBID = e.EntityType_DBID WHERE EntityType LIKE'%${text}%')
          OR e.Entity_DBID IN (SELECT Entity_DBID FROM ventitynamehierarchy WHERE TrimmedPath LIKE '%${text}%')
          )`
    : ``;
};

const BuildFilters = (filters) => {
  // AND e.Entity_DBID IN (SELECT Entity_DBID FROM tEntityMeta WHERE (EntityMetaType_DBID = 71 AND (EntityMetaValue = 'h4.1.0.11' OR EntityMetaValue = 'F7.29.4.1')))
  // AND e.Entity_DBID IN (SELECT Entity_DBID FROM tEntityMeta WHERE (EntityMetaType_DBID = 110 AND (EntityMetaValue = '2.3')))

  const validFilters = filters.filter(
    (x) => !['Location', 'EntityType'].includes(x.key)
  );

  var output = validFilters.reduce(function (o, cur) {
    // Get the index of the key-value pair.
    var occurs = o.reduce(function (n, item, i) {
      return item.key === cur.key ? i : n;
    }, -1);

    // If the name is found,
    if (occurs >= 0) {
      // append the current value to its list of values.
      o[occurs].label = o[occurs].label.concat(cur.label);

      // Otherwise,
    } else {
      // add the current item to o (but make sure the value is an array).
      var obj = {
        key: cur.key,
        label: [cur.label],
      };
      o = o.concat([obj]);
    }

    return o;
  }, []);

  let query = '';

  output.forEach((o) => {
    let conditions = o.label
      .map((x) => "EntityMetaValue = '" + x + "'")
      .join(' OR ');
    query +=
      "AND e.Entity_DBID IN (SELECT Entity_DBID FROM tEntityMeta WHERE (EntityMetaType_DBID = (SELECT EntityMetaType_DBID FROM tEntityMetaType WHERE EntityMetaType = '" +
      o.key +
      "') AND (" +
      conditions +
      ')))';
  });

  return query;
};

const BuildQuery = (params) => {
  let baseQuery = `WITH query1 AS (SELECT e.Entity_DBID, e.EntityName, t.EntityType, t.EntityType_DBID, ${
    params.level === params.epoLevel ? 'e.Level_DBID' : 't.Level_DBID'
  }, mt.EntityMetaType_DBID, mt.EntityMetaType, m.EntityMetaValue, v.Path AS Location, h.ParentEntity_DBID AS ParentId, tdt.DataType
    FROM tEntity e INNER JOIN tEntityType t ON t.EntityType_DBID = e.EntityType_DBID
    LEFT JOIN tEntityMeta m ON m.Entity_DBID = e.Entity_DBID LEFT JOIN tEntityMetaType mt ON mt.EntityMetaType_DBID = m.EntityMetaType_DBID
    LEFT JOIN ventitynamehierarchy v ON v.Entity_DBID = e.Entity_DBID
    LEFT JOIN tDataTypes tdt ON tdt.DataType_DBID = mt.DataType_DBID
    LEFT JOIN thierarchy h ON h.Entity_DBID = e.Entity_DBID`;

  let conditionQuery =
    params.level === params.epoLevel
      ? `WHERE (e.Level_DBID = ${params.level})`
      : `WHERE (t.Level_DBID = ${params.level} AND e.Level_DBID != ${params.epoLevel}
      OR (${params.level} IS NULL AND t.Level_DBID != ${params.materialLevel} AND t.Level_DBID != ${params.locationLevel} AND t.Level_DBID != ${params.siteLevel} AND t.Level_DBID != ${params.geoLevel} AND e.Level_DBID != ${params.epoLevel}))`;

  let order = params.desc ? 'DESC' : 'ASC';

  let rowCount = params.export
    ? ''
    : `LIMIT ${params.page * params.rows} , ${params.rows}`;

  let sortQuery = ['EntityName', 'EntityType', 'Location'].includes(
    params.orderBy
  )
    ? params.orderBy === 'EntityName' &&
      (params.level === params.epoLevel ||
        params.level === params.materialLevel)
      ? `),

  query2 as (SELECT DISTINCT (Entity_DBID) FROM query1 ORDER BY Entity_DBID ${order} ${rowCount})

  select q.*, (SELECT COUNT(DISTINCT Entity_DBID) FROM query1) Total from query1 q inner join query2 q2 on q.Entity_DBID = q2.Entity_DBID ORDER BY Entity_DBID ${order};
`
      : `),

  query2 as (SELECT DISTINCT (Entity_DBID) ,${params.orderBy} FROM query1 ORDER BY TRIM(REPLACE(${params.orderBy}, '_', 0)) ${order} ${rowCount})

  select q.*, (SELECT COUNT(DISTINCT Entity_DBID) FROM query1) Total from query1 q inner join query2 q2 on q.Entity_DBID = q2.Entity_DBID ORDER BY ${params.orderBy} ${order};
`
    : `), 
  VALS1 as (SELECT (@sort_number:=@sort_number + 1)  sort, q.Entity_DBID, q.EntityName FROM query1 q, (SELECT @sort_number:=0) AS t
  where EntityMetaType = '${params.orderBy}' Order by EntityMetaValue ),

  VALS2 as (Select 0 sort, q.Entity_DBID, q.EntityName FROM query1 q where q.Entity_DBID NOT IN(Select v.Entity_DBID from VALS1 v)),

  COMBINE as (select * from VALS1 UNION select * from VALS2 order by sort ${order} ${rowCount})

  select q.*, c.sort, (SELECT COUNT(DISTINCT Entity_DBID) FROM query1) Total from query1 q inner join combine c on q.Entity_DBID = c.Entity_DBID order by c.sort ${order}, Entity_DBID;
`;

  return `${baseQuery}
    ${conditionQuery}
    ${BuildLocations(params.filters)}
    ${BuildColumns(params.cols)}
    ${BuildFilters(params.filters)}
    ${BuildTypes(params.filters)}
    ${BuildText(params.text, params.cols, params)}
       ${sortQuery}
  
    `;
};

const BuildGroupQuery = (params, level, orderBy, order) => {
  level = level.length ? level : 'Equipment';
  // create ctes
  let query = 'WITH';
  params.forEach((p) => {
    query +=
      ' cte' +
      p.value +
      ' AS (SELECT e.Entity_DBID, EntityMetaValue FROM tEntityMeta m INNER JOIN tEntity e ON m.Entity_DBID = e.Entity_DBID ' +
      "INNER JOIN tEntityType t ON e.EntityType_DBID = t.EntityType_DBID WHERE t.Level_DBID = (SELECT MAX(Level_DBID) FROM tLevel WHERE `Level` = '" +
      level +
      "') AND EntityMetaType_DBID = " +
      p.value +
      '),';
  });

  // remove last comma
  query = query.slice(0, query.length - 1);

  // select statement
  query += ' SELECT DISTINCT';
  params.forEach((p) => {
    query += ' `' + p.id + '`,';
  });

  // adding Total column
  query += ' `Total` FROM (SELECT';

  params.forEach((p) => {
    query += ' cte' + p.value + ".EntityMetaValue '" + p.id + "',";
  });

  // adding Total column
  query += ' COUNT(*) Total FROM cte' + params[0].value;

  // left joins
  params.forEach((p, i) => {
    if (i > 0) {
      query +=
        ' LEFT OUTER JOIN cte' +
        p.value +
        ' ON cte' +
        params[0].value +
        '.Entity_DBID = cte' +
        p.value +
        '.Entity_DBID';
    }
  });

  // left join group
  if (params.length > 1) {
    query += ' GROUP BY cte' + params[0].value + '.EntityMetaValue,';
    params.forEach((p, i) => {
      if (i > 0) {
        query += ' cte' + p.value + '.EntityMetaValue,';
      }
    });
    // remove last comma
    query = query.slice(0, query.length - 1);
  }

  // adding UNION
  if (params.length > 1) {
    query += ' UNION ALL SELECT';

    params.forEach((p) => {
      query += ' cte' + p.value + ".EntityMetaValue '" + p.id + "',";
    });

    // adding Total column
    query += ' COUNT(*) Total FROM cte' + params[0].value;
  }

  // right joins
  params.forEach((p, i) => {
    if (i > 0) {
      query +=
        ' RIGHT OUTER JOIN cte' +
        p.value +
        ' ON cte' +
        params[0].value +
        '.Entity_DBID = cte' +
        p.value +
        '.Entity_DBID';
    }
  });

  // right join group
  if (params.length > 1) {
    query += ' GROUP BY cte' + params[0].value + '.EntityMetaValue,';
    params.forEach((p, i) => {
      if (i > 0) {
        query += ' cte' + p.value + '.EntityMetaValue,';
      }
    });
    // remove last comma
    query = query.slice(0, query.length - 1);
  }

  // GROUP BY cte6.EntityMetaValue, cte110.EntityMetaValue) a
  // ORDER BY `Model`, `BIOS Version`, `Total`

  // group by
  if (params.length === 1) {
    query += ' GROUP BY';
    params.forEach((p) => {
      query += ' cte' + p.value + '.EntityMetaValue,';
    });

    // remove last comma
    query = query.slice(0, query.length - 1);
  }

  // add alias
  query += ') a';

  // add null filter for first column
  // query += " WHERE `" + params[0].id + "` IS NOT NULL";

  // add order
  query += ' ORDER BY `' + orderBy + '` ' + order;

  // add orders
  // params.forEach((p) => {
  //   query += " `" + p.id + "`,";
  // });

  // add Total column
  // query += ", `Total` ";

  return query;
};

export {
  BuildQuery,
  BuildTypes,
  BuildLocations,
  BuildColumns,
  BuildFilters,
  BuildGroupQuery,
};
