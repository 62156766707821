import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Api } from './interface';
import Stack from 'react-bootstrap/Stack';
import Tree from './tree';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Files, Trash } from 'react-bootstrap-icons';
import { IsDuplicate, IsRole } from './validator';
import { TableSortLabel } from '@mui/material';
import { ROWSPERPAGE } from './constants';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getComparator, handleResize, oven } from './common';
import { Hint } from './constants.js';
import { ButtonGroup } from 'react-bootstrap';
import NoAccess from './noAccess';

class Templates extends Component {
  constructor(props) {
    super(props);

    this.tableCellHover = [
      {
        '&:hover': {
          backgroundColor: '#BDBDBD',
        },
      },
    ];

    this.selectedLevel;

    this.state = {
      rowsPerPage: this.bake('rowsPerPage')
        ? Number(this.bake('rowsPerPage'))
        : 15,
      orderBy: this.bake('orderBy') ?? 'EntityType',
      order: this.bake('order') ?? 'asc',
      searchText: this.bake('searchText') ?? '',

      items: [],
      metaTypes: [],
      templates: [],
      newFieldId: -1,
      currentEntityType: '',
      currentEntityTypeId: -1,
      isOpen: false,
      editing: false,
      cloning: false,
      deleting: false,
      rows: [],
      filteredRows: [],
      maxRows: 15,
      page: 0,
      newEntityType: '',
      levels: [],
      currentLevel: '',
      newLevelId: -1,
      currentClone: {},
      columns: [
        { id: 'EntityType', label: 'Name', minWidth: 170 },
        { id: 'Level', label: 'Level', minWidth: 100 },
        { id: 'Count', label: 'Count', minWidth: 100 },
        { id: 'Clone', label: 'Clone', minWidth: 100, align: 'center' },
      ],
    };
  }

  bake = (g, c, v) => {
    if (typeof g === 'string') {
      return oven({ level: 'templates' }, false, g, c);
    } else {
      return oven({ level: 'templates' }, true, c, v);
    }
  };

  componentDidMount() {
    Api({ sp: 'getEntityTypes', json: {} }).then((response) => {
      this.setColumns();

      // Filter response by searchText saved to cookies
      let filteredRows = this.filterRowsByText(response, this.state.searchText);

      this.setState({ rows: response, filteredRows: filteredRows });

      handleResize();
    });

    Api({ sp: 'getMetaTypes', json: {} }).then((response) => {
      this.setState({ metaTypes: response });
    });

    Api({ sp: 'getTemplates', json: {} }).then((response) => {
      this.setState({ templates: response });
    });

    Api({ sp: 'getLevels', json: {} }).then((response) => {
      this.setState({
        levels: response,
        currentLevel: response[0],
        newLevelId: response[0].Level_DBID,
      });

      this.selectedLevel = response[0];
    });
  }

  componentDidUpdate() {
    handleResize();
  }

  levelSelector = () => {
    return (
      <Autocomplete
        autoHighlight={true}
        className="type-ahead"
        id="level"
        value={this.selectedLevel}
        options={this.state.levels}
        getOptionLabel={(option) => option.Level}
        hiddenlabel="true"
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select/Type" />
        )}
        onChange={(_event, selected) => {
          this.setState({
            newLevelId: selected !== null ? selected.Level_DBID : -1,
          });

          this.selectedLevel = selected;
        }}
      />
    );
  };
  setColumns = () => {
    if (IsRole(['Equipment Manager', 'Admin'])) {
      this.setState({
        columns: [
          ...this.state.columns,
          {
            id: 'Delete',
            label: 'Delete',
            minWidth: 100,
            align: 'center',
          },
        ],
      });
    }
  };

  renameTemplate = (val) => {
    val = val.trim();
    let oldVal = this.state.currentEntityType;
    if (!IsDuplicate('Template', val, this.state.rows, oldVal)) {
      Api({
        sp: 'renameEntityType',
        json: {
          name: val,
          entityTypeId: this.state.currentEntityTypeId,
        },
      }).then(() => {
        let rows = this.state.rows;
        rows.forEach((row) => {
          if (row.EntityType_DBID === this.state.currentEntityTypeId) {
            row.EntityType = val;
          }
        });

        this.setState({
          currentEntityType: val,
          items: rows,
        });
        toast.success(oldVal + ' renamed to ' + val);
        this.endRenaming();
      });
    } else {
      this.endRenaming();
    }
  };

  startRenaming = () => {
    this.setState({ renaming: true });
  };

  endRenaming = () => {
    this.setState({ renaming: false });
  };

  showModal = () => {
    this.selectedLevel = null;
    this.setState({
      newEntityType: '',
      newLevelId: -1,
      isOpen: true,
    });
  };

  hideModal = () => {
    this.setState({ isOpen: false });
  };

  startEditing = () => {
    this.setState({ editing: true });
  };

  endEditing = () => {
    Api({ sp: 'getTemplates', json: {} }).then((response) => {
      this.setState({ templates: response, editing: false });
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    // Save selected rows per page to cookies
    this.bake('rowsPerPage', parseInt(event.target.value, 10));

    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';

    // Save selected sort column & order to cookies
    this.bake('orderBy', property);
    this.bake('order', isAsc ? 'desc' : 'asc');

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  endCloning = () => {
    Api({ sp: 'getTemplates', json: {} }).then((response) => {
      this.setState({ templates: response, cloning: false });
    });
  };

  addEntityType = () => {
    if (!IsDuplicate('Template', this.state.newEntityType, this.state.rows)) {
      Api({
        sp: 'addEntityType',
        json: {
          name: this.state.newEntityType,
          levelId: this.state.newLevelId,
        },
      }).then((response) => {
        let newItems = this.state.rows.concat(response[0]);

        // Filter response by search bar text
        let filteredRows = this.filterRowsByText(
          newItems,
          this.state.searchText
        );

        this.setState({
          isOpen: false,
          newEntityType: '',
          rows: newItems,
          filteredRows: filteredRows,
          newLevelId: this.state.levels[0].Level_DBID,
        });
        this.selectedLevel = this.state.levels[0];
        toast.success(response[0].EntityType + ' created successfully');
      });
    }
  };

  updateColumn = (data) => {
    let list = this.flattenTree(data, 0);

    Api({
      sp: 'updateTemplate',
      json: { items: list, typeId: this.state.currentEntityTypeId },
    }).then(() => {
      //toast.success(name + " is now " + col + " for " + this.state.currentEntityType, { position: toast.POSITION.TOP_RIGHT });
    });
  };

  flattenTree = (data, id) => {
    let list = [];
    data.forEach((d) => {
      list.push({ name: d.title, id: d.EntityMetaType_DBID, parentId: id });
      if (d.children.length) {
        list.push(...this.flattenTree(d.children, d.EntityMetaType_DBID));
      }
    });
    return list;
  };

  cardClick = (item) => {
    this.setState({
      currentEntityType: item.EntityType,
      currentEntityTypeId: item.EntityType_DBID,
      editing: true,
    });
  };

  filterRowsByText(rows, text) {
    let filteredRows = rows.filter((r) => {
      let objVals = Object.values(r);

      return objVals.some((v) => {
        let strVal = String(v).toLowerCase();

        return strVal.includes(text.toLowerCase());
      });
    });

    return filteredRows;
  }

  filterList(filter) {
    // Save search text to cookies & state
    this.bake('searchText', filter);
    this.setState({ searchText: filter });

    // Filter response by search bar text
    let filteredRows = this.filterRowsByText(this.state.rows, filter);
    this.setState({ filteredRows: filteredRows, page: 0 });
  }

  cloneTemplate = (event, item) => {
    event.stopPropagation();
    this.selectedLevel = this.state.levels[0];
    this.setState({
      newLevelId: this.state.levels[0].Level_DBID,
      cloning: true,
      currentClone: item,
    });
  };

  cloneEntityType = () => {
    Api({
      sp: 'cloneEntityType',
      json: {
        name: this.state.newEntityType,
        levelId: this.state.newLevelId,
        cloneId: this.state.currentClone.EntityType_DBID,
      },
    }).then((response) => {
      let rows = this.state.rows.concat(response[0]);

      // Filter response by search bar text
      let filteredRows = this.filterRowsByText(
        this.state.filteredRows.concat(response[0]),
        this.state.searchText
      );

      this.setState({
        cloning: false,
        rows: rows,
        filteredRows: filteredRows,
      });
      toast.success(
        this.state.currentClone.EntityType +
          ' cloned as ' +
          this.state.newEntityType
      );
    });
  };

  confirmDelete = (e, row) => {
    e.stopPropagation();

    this.setState({
      currentEntityType: row.EntityType,
      currentEntityTypeId: row.EntityType_DBID,
      deleting: true,
    });
  };

  deleteEntityType = () => {
    //event.stopPropagation();
    let entityType = this.state.currentEntityType;
    let entityTypeId = this.state.currentEntityTypeId;
    Api({ sp: 'deleteEntityType', json: { id: entityTypeId } }).then(() => {
      let rows = this.state.rows.filter(
        (x) => x.EntityType_DBID !== entityTypeId
      );
      let filteredRows = this.state.filteredRows.filter(
        (x) => x.EntityType_DBID !== entityTypeId
      );
      this.setState({
        rows: rows,
        filteredRows: filteredRows,
        currentEntityType: '',
        currentEntityTypeId: -1,
        deleting: false,
      });
      toast.success(entityType + ' deleted successfully');
    });
  };

  isSaveButtonDisabled() {
    return this.state.newEntityType.length < 3 || this.selectedLevel === null;
  }

  render() {
    return IsRole(
      [
        'Admin',
        'Equipment Manager',
        'Material Manager',
        'Dev',
        'Material Storage',
      ],
      this.props.roles
    ) ? (
      <div className="main-container">
        <div className="mt-2 d-flex justify-content-between">
          {/* Page Title */}
          <h2>Templates</h2>

          {/* Add Template Button */}
          {IsRole(['Equipment Manager', 'Admin']) ? (
            <ButtonGroup className="mb-2">
              <Button
                variant="primary"
                className="ms-auto text-nowrap"
                onClick={this.showModal}
              >
                Add Template
              </Button>
            </ButtonGroup>
          ) : null}
        </div>

        <Stack className="pb-2" direction="horizontal" gap={3}>
          {/* Search Bar */}
          <Form.Control
            value={this.state.searchText}
            type="text"
            placeholder="Search"
            onChange={(e) => this.filterList(e.target.value)}
            autoFocus
          />
        </Stack>

        {/* Material Table */}
        <Paper sx={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            className="tableContainer"
            id="tab-container"
          >
            <Table stickyHeader aria-label="sticky table">
              {/* Column Headers */}
              <TableHead>
                <TableRow>
                  {this.state.columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sortDirection={
                        this.state.orderBy === column.id
                          ? this.state.order
                          : false
                      }
                      className="table-header"
                    >
                      {/* Non-sortable & sortable column headers */}
                      {['Clone', 'Delete'].includes(column.id) ? (
                        <span>{column.label}</span>
                      ) : (
                        <TableSortLabel
                          active={this.state.orderBy === column.id}
                          direction={
                            this.state.orderBy === column.id
                              ? this.state.order
                              : 'asc'
                          }
                          onClick={(e) => this.handleRequestSort(e, column.id)}
                        >
                          <span className="pr-2">{column.label}</span>
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* Table Content */}
              <TableBody>
                {this.state.filteredRows
                  .slice()
                  .sort(getComparator(this.state.order, this.state.orderBy))
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((row) => {
                    return (
                      <TableRow
                        className="pointer"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.EntityType_DBID}
                        onClick={() => this.cardClick(row)}
                      >
                        {this.state.columns.map((column) => {
                          const value = row[column.id];
                          return column.id === 'Clone' ? (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              onClick={(e) => this.cloneTemplate(e, row)}
                              sx={this.tableCellHover}
                            >
                              <Files color="blue" size={18} />
                            </TableCell>
                          ) : column.id === 'Delete' ? (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              onClick={(e) => this.confirmDelete(e, row)}
                              sx={this.tableCellHover}
                            >
                              <Trash color="red" size={18} />
                            </TableCell>
                          ) : (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage="Rows:"
            rowsPerPageOptions={ROWSPERPAGE}
            component="div"
            count={this.state.filteredRows.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>

        <Modal
          dialogClassName="template-modal"
          show={this.state.editing}
          onHide={this.endEditing}
        >
          <Modal.Header closeButton="true">
            <Modal.Title>
              <div className="renameLabel">
                {IsRole(['Admin', 'Equipment Manager']) ? 'Manage ' : null}{' '}
                Template Fields -
              </div>
              &nbsp;
              {IsRole(['Equipment Manager']) ? (
                this.state.renaming ? (
                  <div className="renameInput">
                    <Form.Control
                      type="text"
                      className="inputBoxText"
                      autoFocus
                      placeholder="Template Name"
                      defaultValue={this.state.currentEntityType}
                      onBlur={(e) => this.renameTemplate(e.target.value)}
                    />
                  </div>
                ) : (
                  <Hint
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    title="Click to rename template"
                  >
                    <span
                      className="pointer child-link"
                      onClick={() => this.startRenaming()}
                    >
                      {this.state.currentEntityType}
                    </span>
                  </Hint>
                )
              ) : (
                this.state.currentEntityType
              )}{' '}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tree
              template={this.state.templates.filter(
                (t) => t.EntityType === this.state.currentEntityType
              )}
              meta={this.state.metaTypes}
              entityTypeId={this.state.currentEntityTypeId}
              colChange={this.updateColumn}
              readOnly={!IsRole(['Equipment Manager', 'Admin'])}
            />
          </Modal.Body>
        </Modal>

        <Modal show={this.state.isOpen} onHide={this.hideModal}>
          <Modal.Header>
            <Modal.Title>Add Entity Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2">
              Enter new Entity Type:
              <Form.Control
                type="text"
                placeholder="Entity Type"
                onChange={(e) =>
                  this.setState({ newEntityType: e.target.value.trim() })
                }
              />
            </div>
            <div className="mb-2">
              Select Level:
              <this.levelSelector />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.hideModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                this.addEntityType();
              }}
              disabled={this.isSaveButtonDisabled()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.cloning} onHide={this.endCloning}>
          <Modal.Header>
            <Modal.Title>
              Cloning {this.state.currentClone.EntityType}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2">
              Enter new Entity Type:
              <Form.Control
                type="text"
                placeholder="Entity Type"
                onChange={(e) =>
                  this.setState({ newEntityType: e.target.value })
                }
              />
            </div>
            <div className="mb-2">
              Select Level:
              <this.levelSelector />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.endCloning}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                this.cloneEntityType();
              }}
              disabled={this.state.newEntityType.length < 3 ? true : false}
            >
              Clone
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete confirmation */}
        <Modal
          size="lg"
          show={this.state.deleting}
          onHide={() => this.setState({ deleting: false })}
          className="entity-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>
              Confirm deletion of {this.state.currentEntityType}
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => this.setState({ deleting: false })}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                this.deleteEntityType();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    ) : (
      <NoAccess />
    );
  }
}
export default Templates;
