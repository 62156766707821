import { useState } from 'react';
import { Api } from './interface';

export default function HealthCheck() {
  const [isHealthy, setIsHealthy] = useState(false);

  Api({ sp: 'getImgServer', json: {} }).then(
    (data) => {
      setIsHealthy(!!data);
    },
    () => {
      setIsHealthy(false);
    }
  );
  return (
    <>
      {JSON.stringify({
        // application is healthy if the page loads
        application: 'healthy',
        // API and database are available if the stored procedure loads
        api: isHealthy ? 'healthy' : 'error',
        database: isHealthy ? 'healthy' : 'error',
      })}
    </>
  );
}
