import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { DebounceInput } from 'react-debounce-input';
import { Api, SendEmail } from './interface';
import { IsRole, isInteger } from './validator';
import { bake } from './common';
// import Cookies from "js-cookie";

const TransactionRequest = (props) => {
  const [request, setRequest] = useState([]);
  const [users, setUsers] = useState(props.users);
  const [locations, setLocations] = useState(props.locations);
  const [newLocation, setNewLocation] = useState({ Path: '' });
  const [newOwner, setNewOwner] = useState({ UserName: 'Not Selected' });
  const [selectedEntities, setSelectedEntities] = useState([{ id: -1 }]);
  const [fullfilledList, setFulfilledList] = useState([]);
  const [groupedFieldEntitiesMap, setGroupedFieldEntitiesMap] = useState([]);
  const [bulkAssign, setBulkAssign] = useState(false);
  const [bulkSNs, setBulkSNs] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [entities, setEntities] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [requestTotal, setRequestTotal] = useState(0);

  const [transactionIdentifier, setTransactionIdentifier] = useState([]);
  const [nonSSDReservedQuantity, setNonSSDReservedQuantity] = useState(null);
  const [disableFlag, setDisableFlag] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'))['oid'];
  const requestTotalRef = useRef(requestTotal);
  const setRequestTotalRef = (data) => {
    requestTotalRef.current = data;
    setRequestTotal(data);
  };

  // shipping fields if request transaction will move to external site
  const [shippingFields, setShippingFields] = useState([
    { Field: 'company', Label: 'Company', FieldMeta: 'CompanyName' },
    { Field: 'contact', Label: 'Contact Name', FieldMeta: 'ContactName' },
    { Field: 'email', Label: 'Contact Email', FieldMeta: 'Email' },
    { Field: 'address', Label: 'Address', FieldMeta: 'Address' },
    {
      Field: 'shippingNumber',
      Label: 'Shipping Number',
      FieldMeta: 'ShippingNumber',
    },
  ]);

  useEffect(() => {
    setUsers(props.users);
    setLocations(props.locations);
    setReadOnly(
      !(
        IsRole(['Material Storage', 'Planner']) ||
        props.currentRow['CreatedOID'] === user ||
        props.currentRow['CurrentOID'] === user
      )
    );
    getData();
  }, [props.currentRow]);

  const getData = () => {
    Api({
      sp: 'getTransactionRequestData',
      json: { transactionId: props.currentRow['Transaction_DBID'] },
    }).then((response) => {
      let req = request;
      req.NewParent = locations.find(
        (loc) => loc.Entity_DBID === response[0].ParentEntity_DBID
      );
      req.NewOwner = users.find(
        (item) => item.User_DBID === response[0].User_DBID
      );

      req.OldOwner = users.find(
        (item) => item.User_DBID === response[0].Prev_User_DBID
      );
      req.Transaction_DBID = props.currentRow['Transaction_DBID'];
      req.Comments = response[0].Comments;
      req.reserve = [];
      let totalqty = 0;
      if (props.matType === 'SSD') {
        response.forEach((x) => {
          totalqty = totalqty + parseInt(x.Reserved ?? 0);
          req.reserve.push({
            EntityMetaValue: x.EntityMetaValue,
            Identifier: x.EntityMetaValue,
            Reserved: x.Reserved,
            RequestedQty: x.Reserved,
            Selected: 0,
          });
        });
      }

      setRequestTotalRef(totalqty);
      setRequest(req);
      setNewOwner(req.NewOwner);
      setEntities(response);
      if (props.matType !== 'SSD') {
        setTransactionIdentifier([
          {
            EntityMetaType: response[0]['EntityMetaType'],
            EntityMetaValue: response[0]['EntityMetaValue'],
          },
          {
            EntityMetaType: 'Requested Quantity',
            EntityMetaValue: response[0]['Total'],
          },
        ]);
        setNonSSDReservedQuantity(response[0]['Total']);
        setEntities([]);
      }
      getTransactionRequestField();
    });
  };

  const getTransactionRequestField = () => {
    Api({
      sp: 'getTransactionRequestField',
      json: {
        locationId: request?.NewParent.Entity_DBID,
        ownerId: request?.OldOwner.User_DBID,
        transactionId: request?.Transaction_DBID,
        matType: props.matType,
      },
    }).then((response) => {
      let list = [];
      response.forEach((r) => {
        list[r.EntityMetaValue] = [];
        r.id =
          props.matType === 'SSD'
            ? r.EntityMetaValue + ' - (' + r.Identifier + ')'
            : r.Identifier + ' (Quantity: ' + r.Quantity + ')';
      });
      response = response.sort((a, b) => {
        return a.id > b.id ? 1 : -1;
      });

      setSelectedEntities(response);
      setGroupedFieldEntitiesMap(list);
    });
  };

  const updateShippingField = (val, updateField) => {
    let newShippingFields = shippingFields;
    newShippingFields = newShippingFields.map((field) => {
      return field.Field === updateField.Field
        ? { ...field, Value: val }
        : field;
    });
    setShippingFields(newShippingFields);
  };

  const fulfillList = (val) => {
    let list = groupedFieldEntitiesMap;

    if (props.matType !== 'SSD') {
      let req = request;

      val = val.map((v) => {
        return { ...v, Label: v.Identifier };
      });

      req.reserve = [];
      val.forEach((v) => {
        if (
          req.reserve?.filter((x) => x.Entity_DBID === v.Entity_DBID)
            ?.length === 0
        ) {
          req.reserve.push({
            ...v,
            RequestedQty: 0,
            Reserved: 0,
            Selected: 0,
          });
        }
      });
      setEntities(req.reserve);
      setSelectedList(req.reserve);
      setRequest(req);
    } else {
      val = val.map((v) => {
        return { ...v, RequestedQty: 1 };
      });
      val.forEach((v) => {
        if (
          list[v.EntityMetaValue]?.filter(
            (x) => x.Entity_DBID === v.Entity_DBID
          )?.length === 0
        ) {
          list[v.EntityMetaValue].push(v);
        }
      });
      setFulfilledList(list);
      setSelectedList(val);
    }
  };

  const updateRequestOwner = (val) => {
    let r = request;
    r.NewOwner = val;
    setNewOwner(val);
    setRequest(r);
  };

  const updateRequestLocation = (val) => {
    let r = request;
    r.NewParent = val;
    setRequest(r);
    setNewLocation(val);
  };

  const updateRequestComment = (val) => {
    let r = request;
    r.Comments = val.target.value;
    setRequest(r);
  };

  const updateRequestReserved = (val, item) => {
    if (!isInteger(val)) {
      toast.error('Value must be a number');
      return;
    }
    val = Number(val);
    if (val < 0) {
      toast.error('Value cannot be negative');
      return;
    }
    let reservedQty = item.Reserved;
    let requestedTotal = val;
    if (props.matType !== 'SSD') {
      reservedQty = nonSSDReservedQuantity;
      request?.reserve?.forEach((r) => {
        if (r.Identifier !== item.Identifier) {
          requestedTotal = requestedTotal + r.RequestedQty;
        }
      });
    }
    if (requestedTotal > reservedQty) {
      toast.error(
        'Cannot reserve more units than requested (' + reservedQty + ')'
      );
      setDisableFlag(true);
      return;
    }
    let r = request;
    let totalqty = 0;

    r.reserve?.forEach((x) => {
      if (x.Identifier === item.Identifier) {
        x.RequestedQty = val;
      }
      totalqty = totalqty + parseInt(x.RequestedQty);
    });
    setRequest(r);
    setRequestTotalRef(totalqty);
    setDisableFlag(false);
  };

  const checkFulfillDisable = (option) => {
    return props.matType === 'SSD'
      ? selectedList?.filter(
          (x) => x.EntityMetaValue === option.EntityMetaValue
        )?.length ===
          request?.reserve?.filter(
            (x) => x.EntityMetaValue === option.EntityMetaValue
          )[0]?.RequestedQty ||
          selectedList?.filter((x) => x.Entity_DBID === option.Entity_DBID)
            ?.length === 1
      : request?.reserve?.filter((r) => r.Entity_DBID === option.Entity_DBID)
          ?.length === 1;
  };

  const bulkAssignSNs = () => {
    let splitBulkSNs = bulkSNs.split(/[\s|\t|,|;]+/);
    let serials = selectedEntities;
    let list =
      selectedList.length > 0 ? fullfilledList : groupedFieldEntitiesMap;
    let selectList = selectedList;

    splitBulkSNs.forEach((b) => {
      let sn = serials.filter((s) => s.Identifier === b);
      if (sn.length === 1) {
        sn = sn[0];
        if (
          list[sn.EntityMetaValue]?.filter(
            (s) => s.Entity_DBID === sn.Entity_DBID
          ).length === 0
        ) {
          list[sn.EntityMetaValue].push(sn);
        }
        if (
          selectList?.filter((s) => s.Entity_DBID === sn.Entity_DBID).length ===
          0
        ) {
          selectList.push(sn);
        }
      }
    });

    setSelectedList(selectList);
    setFulfilledList(list);
  };

  const getConfirmDisabled = () => {
    if (!request) {
      return false;
    } else {
      let fulfillCheck = true;
      request.reserve?.forEach((r) => {
        if (
          r?.RequestedQty !==
          selectedList?.filter((x) => x.EntityMetaValue === r.EntityMetaValue)
            .length
        ) {
          fulfillCheck = false;
        }
      });
      let totalCheck =
        requestTotalRef.current > 0 &&
        requestTotalRef.current === selectedList?.length;

      let requestCheck = true;
      if (!newLocation?.Entity_DBID || !newOwner) {
        requestCheck = false;
      }

      // skip location check if "External User" for External Site
      if (newOwner.UserName === 'External User') {
        requestCheck = true;
      }

      return props.matType === 'SSD'
        ? fulfillCheck && requestCheck && totalCheck
        : requestCheck &&
            requestTotalRef.current > 0 &&
            requestTotalRef.current <= nonSSDReservedQuantity;
    }
  };

  const confirmRequest = () => {
    let newParent;
    // set "External Site" as New Location for shipping request
    if (newOwner?.UserName === 'External User') {
      newParent = locations.find(
        (parent) => parent.Entity_DBID === parseInt(bake('externalSiteId'), 10)
      );
    } else newParent = request.NewParent;
    let json = {
      Comments: request.Comments,
      transactionId: request.Transaction_DBID,
      NewOwner: request.NewOwner,
      NewParent: newParent,
      OldOwner: request.OldOwner,
      reserve: request.reserve,
      selectedList: selectedList?.filter((row) => row.RequestedQty !== 0),
      matType: props.matType,
      totalVal: requestTotalRef.current,
      // check if request fulfillment is for external transaction
      shippingCheck: request.NewOwner.UserName === 'External User',
    };
    // add shipping details if necessary
    shippingFields.forEach((field) => {
      json = { ...json, [field.Field]: field.Value };
    });

    Api({
      sp: 'completeTransactionRequest',
      json: json,
    }).then((response) => {
      let transactionId = response[0].Transaction_DBID;
      toast.success(
        'Request ' + transactionId.toString().padStart(8, '0') + ' completed'
      );

      props.closeModal(true);

      SendEmail('instantEmailGiveGotRequest', {
        emailSubject:
          'Request Fulfilled ' + transactionId.toString().padStart(8, '0'),
        transactionId: transactionId,
        status: 'Request → Give It',
        action: 'Give It',
      });
    });
  };

  const declineRequest = () => {
    if (
      request.reserve.some((x) => x.RequestedQty !== 0) ||
      selectedList?.length
    ) {
      toast.error('No units must be selected and/or the total should be 0');
    } else if (!request.Comments || request.Comments.length < 3) {
      toast.error('Please enter a comment');
    } else {
      let json = {
        Comments: request.Comments,
        transactionId: request.Transaction_DBID,
        NewOwner: request.NewOwner,
        NewParent: request.NewParent,
        OldOwner: request.OldOwner,
        reserve: request.reserve,
      };

      Api({
        sp: 'declineTransaction',
        json: json,
      }).then((response) => {
        toast.success(
          'Transaction ' +
            String(response[0].Transaction_DBID).padStart(8, 0) +
            ' declined successfully'
        );

        props.closeModal(true);
      });
    }
  };

  return (
    <>
      <Modal
        show={props.editing}
        onHide={() => props.closeModal()}
        className="entity-modal"
        size="lg"
      >
        <Modal.Header closeButton="true">
          <Modal.Title>
            Fulfill Request{' '}
            {props.currentRow['Transaction_DBID'].toString().padStart(8, '0')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="request-container">
            <div>
              <b>Current Owner: </b> {props.currentRow['Current Owner']}
            </div>
            <div>
              <b>Current Location: </b>{' '}
              {props.currentRow['Current Location'] ?? ''}
            </div>
            {transactionIdentifier?.length
              ? transactionIdentifier.map((identifier, i) => {
                  return (
                    <div key={i}>
                      <b>{identifier['EntityMetaType']}: </b>{' '}
                      {identifier['EntityMetaValue'] ?? ''}
                    </div>
                  );
                })
              : null}
            <div className="give-table">
              <table className="table give">
                <thead>
                  <tr>
                    {props.headers?.map((header, i) => {
                      return header.requestModal ? (
                        <th key={i}>{header.label}</th>
                      ) : null;
                    })}
                    <th>Quantity</th>
                    {!readOnly && props.matType === 'SSD' ? (
                      <th>Selected</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {!entities || !entities?.length ? (
                    <p>
                      <i>Select units to display information</i>
                    </p>
                  ) : null}
                  {entities?.map((item, id) => (
                    <tr key={id}>
                      <td>{item.Identifier}</td>
                      <td
                        style={{
                          height: '70px',
                        }}
                      >
                        {!readOnly ? (
                          <>
                            <DebounceInput
                              value={
                                request?.reserve?.filter(
                                  (x) => x.Identifier === item.Identifier
                                )[0]?.RequestedQty
                              }
                              className="form-control w-50"
                              onChange={(event) => {
                                if (event.target.value !== '') {
                                  updateRequestReserved(
                                    event.target.value,
                                    item
                                  );
                                }
                              }}
                            />
                            {item.Quantity ? (
                              <p className="maxQty">
                                Current Quantity: {item.Quantity}
                              </p>
                            ) : null}
                          </>
                        ) : (
                          request?.reserve?.filter(
                            (x) => x.Identifier === item.Identifier
                          )[0].RequestedQty
                        )}
                      </td>
                      {!readOnly && props.matType === 'SSD' ? (
                        <td>
                          {
                            selectedList.filter(
                              (x) => x.EntityMetaValue === item.EntityMetaValue
                            ).length
                          }
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <div className="mb-2">
              <h6>New Owner</h6>
              {!readOnly ? (
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="parent-entity"
                  options={
                    IsRole('Material Storage')
                      ? users
                      : users.filter((u) => u.UserName !== 'Material Storage')
                  }
                  value={newOwner}
                  getOptionLabel={(option) => option.UserName}
                  renderOption={(props, option) => (
                    <li {...props} key={option.User_DBID}>
                      <span>{option.UserName}</span>
                    </li>
                  )}
                  hiddenlabel="true"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Owner"
                    />
                  )}
                  onChange={(_event, newOwner) => {
                    updateRequestOwner(newOwner);
                  }}
                />
              ) : (
                newOwner?.UserName
              )}
            </div>
            {/* Do not display location dropdown for external shipping request */}
            {request?.NewOwner?.UserName !== 'External User' ? (
              <div className="mb-2">
                <h6>New Location</h6>
                {!readOnly ? (
                  <Autocomplete
                    autoHighlight={true}
                    className="type-ahead"
                    id="parent-entity"
                    options={locations}
                    value={newLocation}
                    getOptionLabel={(option) => option.Path}
                    hiddenlabel="true"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Location"
                      />
                    )}
                    onChange={(_event, newParent) => {
                      updateRequestLocation(newParent);
                    }}
                  />
                ) : (
                  newLocation?.Path
                )}
              </div>
            ) : null}

            {/* Display location dropdown for external shipping request */}
            {request?.NewOwner?.UserName === 'External User' ? (
              <>
                {shippingFields.map((field, i) => {
                  return (
                    <div className="mb-2" key={i}>
                      <h6>{field.Label}</h6>
                      <Form.Control
                        className="w-100 overflow-hidden"
                        as="textarea"
                        rows="1"
                        onChange={(e) =>
                          updateShippingField(e.target.value, field)
                        }
                        onBlur={(e) => {
                          updateShippingField(e.target.value, field);
                        }}
                        value={field.Value || ''}
                      />
                    </div>
                  );
                })}
              </>
            ) : null}
            <div className="mb-2" hidden={readOnly}>
              <h6>Select Units</h6>
              <Autocomplete
                autoHighlight={true}
                multiple
                className="type-ahead"
                id="parent-entity"
                options={selectedEntities}
                value={selectedList}
                getOptionLabel={(option) => option.id}
                hiddenlabel="true"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Units"
                  />
                )}
                onChange={(_event, newUnits) => {
                  fulfillList(newUnits);
                }}
                getOptionDisabled={(option) => checkFulfillDisable(option)}
                includeInputInList={true}
              />
            </div>

            <div className="mb-2">
              <h6>Comments</h6>
              {!readOnly ? (
                <DebounceInput
                  className="form-control"
                  value={request?.Comments ?? ''}
                  rows="3"
                  element="textarea"
                  onChange={(e) => updateRequestComment(e)}
                />
              ) : (
                request?.Comments
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="outer">
            <div className="confirm" hidden={readOnly}>
              <Button
                variant="outline-danger"
                onClick={() => declineRequest()}
                className="footer-button"
              >
                Decline
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => setBulkAssign(true)}
                className="footer-button"
                hidden={props.matType !== 'SSD'}
              >
                Bulk Assign
              </Button>
              <Button
                variant="primary"
                disabled={!getConfirmDisabled() || disableFlag}
                onClick={(e) => {
                  e.currentTarget.disabled = true;
                  confirmRequest();
                }}
                className="footer-button"
              >
                {/* Change button display to "Ship" if it's an external request transaction */}
                {newOwner?.UserName === 'External User' ? (
                  <>Ship</>
                ) : (
                  <>Give It</>
                )}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={bulkAssign}
        onHide={() => setBulkAssign(false)}
        className="entity-modal"
      >
        <Modal.Header closeButton="true">
          <Modal.Title>Bulk Assign Serial Numbers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="bulk-group-input">
            <Form.Label>Enter serial numbers</Form.Label>
            <Form.Control
              autoFocus
              as="textarea"
              rows={3}
              onChange={(e) => setBulkSNs(e.target.value)}
              value={bulkSNs}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setBulkAssign(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              bulkAssignSNs();
              setBulkAssign(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TransactionRequest;
