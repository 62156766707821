import { TableSortLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import { toast } from 'react-toastify';
import { getComparator, handleResize, oven } from './common';
import { ROWSPERPAGE } from './constants';
import { Hint } from './constants.js';
import { Api } from './interface';
import { IsDuplicate, IsRole } from './validator';
import BasicModal from './basicModal';
import NoAccess from './noAccess';

class EntityConfig extends Component {
  constructor(props) {
    super(props);

    this.textInput = React.createRef();
    this.selectedUOM;

    this.trueFalse = [
      { id: 0, value: false, text: 'False' },
      { id: 1, value: true, text: 'True' },
    ];
    this.selectedIsUnique = this.trueFalse[0];
    this.allowMultiple = this.trueFalse[0];
    this.currentDataType;
    this.tableCellHover = [
      {
        '&:hover': {
          backgroundColor: '#BDBDBD',
        },
      },
    ];

    this.state = {
      rowsPerPage: this.bake('rowsPerPage-Fields')
        ? Number(this.bake('rowsPerPage-Fields'))
        : 15,
      orderBy: this.bake('orderBy-Fields') ?? 'EntityMetaType',
      order: this.bake('order-Fields') ?? 'asc',
      searchText: this.bake('searchText-Fields') ?? '',

      items: [],
      dataTypes: [],
      newMetaType: '',
      newDescription: '',
      newDataTypeId: -1,
      multiple: false,
      currentField: {},
      currentOptions: [],
      units: [],
      newUnitId: -1,
      isOpen: false,
      editing: false,
      deleting: false,
      newOptions: [],
      rows: [],
      filteredRows: [],
      maxRows: 15,
      page: 0,
      fields: [],
      isUnique: false,
      selectedUniqueBy: { EntityMetaType: 'N/A (Global Unique)' },
      columns: [
        { id: 'EntityMetaType', label: 'Name', minWidth: 170 },
        { id: 'DataType', label: 'Type', minWidth: 170 },
        { id: 'UnitMeasure', label: 'UoM', minWidth: 170 },
        { id: 'IsUnique', label: 'Unique', minWidth: 170 },
        { id: 'UniqueEntityMetaType', label: 'Unique By', minWidth: 170 },
        { id: 'Description', label: 'Description', minWidth: 100 },
        { id: 'TemplateCount', label: 'Template Count', minWidth: 100 },
      ],
    };
  }

  bake = (g, c, v) => {
    if (typeof g === 'string') {
      return oven(this.props, false, g, c);
    } else {
      return oven(this.props, true, c, v);
    }
  };

  componentDidMount() {
    Api({ sp: 'getMetaTypes', json: {} }).then((response) => {
      this.setColumns();

      response.forEach((x) => {
        x.IsUnique = x.IsUnique ? 'True' : 'False';
        x.AllowMultiple = x.AllowMultiple ? 'True' : 'False';
      });

      let fields = [
        {
          EntityMetaType: 'N/A (Global Unique)',
        },
      ].concat(response);

      // Filter response by searchText saved to cookies
      let filteredRows = this.filterRowsByText(response, this.state.searchText);

      this.setState({
        items: response,
        rows: response,
        filteredRows: filteredRows,
        fields: fields,
      });

      handleResize();
    });

    Api({ sp: 'getDataTypes', json: {} }).then((response) => {
      this.setState({
        dataTypes: response,
        newDataTypeId: response[0].DataType_DBID,
      });
      this.currentDataType = response[0];
    });

    Api({ sp: 'getUnitsOfMeasure', json: {} }).then((response) => {
      // response.unshift({ UnitMeasure_DBID: -1, UnitMeasure: "" });
      this.setState({
        units: response,
        newUnitId: -1, // response[0].UnitMeasure_DBID,
      });
      this.selectedUOM = response[0];
    });
  }

  componentDidUpdate() {
    handleResize();
  }

  setColumns = () => {
    if (IsRole(['Equipment Manager', 'Admin'])) {
      this.setState({
        columns: [
          ...this.state.columns,
          {
            id: 'Delete',
            label: 'Delete',
            minWidth: 100,
            align: 'center',
          },
        ],
      });
    }
  };

  startRenaming = () => {
    this.setState({ renaming: true });
  };

  endRenaming = () => {
    this.setState({ renaming: false });
  };

  showModal = () => {
    let dt = this.state.dataTypes[0].DataType_DBID;
    this.selectedUOM = null;
    this.selectedIsUnique = this.trueFalse[0];
    this.allowMultiple = this.trueFalse[0];
    this.currentDataType = null;

    this.setState({
      isOpen: true,
      newDataTypeId: dt,
      newDescription: '',
      newOptions: [],
      newUnitId: -1,
    });
  };

  hideModal = () => {
    this.setState({ isOpen: false });
  };

  startEdit = () => {
    this.setState({ editing: true });
  };

  endEdit = () => {
    setTimeout(
      () =>
        this.setState({ editing: false, currentOptions: [], currentField: {} }),
      100
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    // Save selected rows per page to cookies
    this.bake('rowsPerPage-Fields', parseInt(event.target.value, 10));

    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  addMetaType = () => {
    if (!IsDuplicate('Field', this.state.newMetaType, this.state.rows)) {
      Api({
        sp: 'addMetaType',
        json: {
          name: this.state.newMetaType,
          desc: this.state.newDescription,
          typeId: this.state.newDataTypeId,
          options: this.state.newOptions,
          unique: this.state.isUnique ? 1 : 0,
          uniqueById: this.state.selectedUniqueBy.EntityMetaType_DBID,
          unitId: this.state.newUnitId,
          multiple: this.state.multiple ? 1 : 0,
        },
      }).then((response) => {
        response.forEach((x) => {
          x.IsUnique = x.IsUnique ? 'True' : 'False';
        });

        //todo add logic to add unit of measure text

        let rows = this.state.rows.concat(response[0]);
        rows.sort((a, b) =>
          a.EntityMetaType.toLowerCase() > b.EntityMetaType.toLowerCase()
            ? 1
            : -1
        );

        // Filter response by search bar text
        let filteredRows = this.filterRowsByText(rows, this.state.searchText);

        filteredRows.sort((a, b) =>
          a.EntityMetaType.toLowerCase() > b.EntityMetaType.toLowerCase()
            ? 1
            : -1
        );
        this.setState({
          isOpen: false,
          rows: rows,
          filteredRows: filteredRows,
          isUnique: false,
          multiple: false,
        });
        toast.success(response[0].EntityMetaType + ' successfully created');
      });
    }
  };

  filterRowsByText(rows, text) {
    let filteredRows = rows.filter((r) => {
      let objVals = Object.values(r);

      return objVals.some((v) => {
        let strVal = String(v).toLowerCase();

        return strVal.includes(text.toLowerCase());
      });
    });

    return filteredRows;
  }

  filterList(filter) {
    // Save search text to cookies & state
    this.bake('searchText-Fields', filter);
    this.setState({ searchText: filter });

    // Filter response by search bar text
    let filteredRows = this.filterRowsByText(this.state.rows, filter);
    this.setState({ filteredRows: filteredRows, page: 0 });
  }

  updateDataType(item) {
    let opts = [];
    let dateTypeId = -1;
    if (item !== null) {
      opts =
        this.getDataType(this.state.dataTypes, item.DataType_DBID)[0] ===
        'Multiple Choice'
          ? [{ value: '' }]
          : [];
      dateTypeId = item.DataType_DBID;
    }

    this.setState({ newDataTypeId: dateTypeId, newOptions: opts });
    this.currentDataType = item;
  }

  updateUnit(item) {
    this.setState({
      newUnitId: item !== null ? item.UnitMeasure_DBID : null,
    });
    this.selectedUOM = item;
  }

  updateUniqueField = (val) => {
    this.setState({
      selectedUniqueBy: val,
    });
  };

  cardClick(field) {
    field.unique = field.IsUnique === 'True' ? 1 : 0;
    field.multiple = field.AllowMultiple === 'True' ? 1 : 0;

    Api({
      sp: 'getMetaOptionsByField',
      json: { id: field.EntityMetaType_DBID },
    }).then((response) => {
      this.setState({
        currentField: field,
        currentOptions: response,
        editing: true,
      });
    });
  }

  flagValue = (val) => {
    return val === 0 ? 'False' : 'True';
  };

  confirmDelete = (e, field) => {
    e.stopPropagation();
    this.setState({ currentField: field, deleting: true });
  };

  updateOptions(val, index) {
    let opts = this.state.newOptions;
    opts[index].value = val;
    this.setState({ newOptions: opts });
  }

  addOption() {
    let opts = this.state.newOptions;
    opts.push({ value: '' });
    this.setState({ newOptions: opts });
  }

  addCurrentOption() {
    let opts = this.state.currentOptions;
    opts.push({
      EntityMetaType_DBID: this.state.currentField.EntityMetaType_DBID,
    });
    this.setState({ currentOptions: opts });
  }

  // assign ids between updates
  updateCurrentOptions(val, opt) {
    if (val === opt.EntityMetaOption) {
      return;
    }
    let opts = this.state.currentOptions;
    const index = opts.indexOf(opt);

    // remove the empty input box with no data. Skip call to sproc
    if (val === '' && !opt.EntityMetaOption_DBID) {
      opts.splice(index, 1);
      toast.success('Option successfully updated');
      this.setState({ currentOptions: opts });
    } else {
      Api({
        sp: 'updateMetaOptions',
        json: {
          typeId: opt.EntityMetaType_DBID,
          optionId: opt.EntityMetaOption_DBID ? opt.EntityMetaOption_DBID : -1,
          val: val,
        },
      }).then((response) => {
        if (val === '') {
          opts.splice(index, 1);
        } else {
          opts[index].EntityMetaOption_DBID = response[0].EntityMetaOption_DBID;
          opts[index].EntityMetaOption = val;
        }
        toast.success('Option successfully updated');
        this.setState({ currentOptions: opts });
      });
    }
  }

  getDataType = (a, b) =>
    a.reduce((r, o) => (o.DataType_DBID == b ? r.concat(o.DataType) : r), []);

  deleteField() {
    let field = this.state.currentField;
    let metaType = field.EntityMetaType;
    Api({
      sp: 'deleteField',
      json: { id: field.EntityMetaType_DBID },
    }).then(() => {
      let rows = this.state.rows.filter(
        (x) => x.EntityMetaType_DBID !== field.EntityMetaType_DBID
      );
      let filteredRows = this.state.filteredRows.filter(
        (x) => x.EntityMetaType_DBID !== field.EntityMetaType_DBID
      );
      this.setState({
        rows: rows,
        filteredRows: filteredRows,
        deleting: false,
        currentField: {},
      });
      toast.success(metaType + ' successfully deleted');
    });
  }

  renameField = (val) => {
    val = val.trim();
    let prevName = this.state.currentField.EntityMetaType;
    if (!IsDuplicate('Field', val, this.state.rows, prevName)) {
      Api({
        sp: 'updateFieldName',
        json: { id: this.state.currentField.EntityMetaType_DBID, val: val },
      }).then(() => {
        let rows = this.state.rows;
        rows.filter(
          (x) =>
            x.EntityMetaType_DBID ===
            this.state.currentField.EntityMetaType_DBID
        )[0].EntityMetaType = val;
        let filteredRows = this.state.filteredRows;
        filteredRows.filter(
          (x) =>
            x.EntityMetaType_DBID ===
            this.state.currentField.EntityMetaType_DBID
        )[0].EntityMetaType = val;
        this.setState({ rows: rows, filteredRows: filteredRows });
        toast.success(prevName + ' renamed to ' + val);
        this.endRenaming();
      });
    } else {
      this.endRenaming();
    }
  };

  changeDescription = (val) => {
    Api({
      sp: 'updateFieldDescription',
      json: { id: this.state.currentField.EntityMetaType_DBID, val: val },
    }).then(() => {
      let rows = this.state.rows;
      rows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].Description = val;
      let filteredRows = this.state.filteredRows;
      filteredRows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].Description = val;
      this.setState({ rows: rows, filteredRows: filteredRows });
      toast.success(
        this.state.currentField.EntityMetaType + ' successfully updated'
      );
    });
  };

  changeUnit = (val) => {
    Api({
      sp: 'updateFieldUnit',
      json: { id: this.state.currentField.EntityMetaType_DBID, val: val },
    }).then(() => {
      let unit =
        val === 0
          ? { UnitMeasure: null }
          : this.state.units.filter(
              (x) => x.UnitMeasure_DBID === parseInt(val)
            )[0];

      let rows = this.state.rows;
      rows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].UnitMeasure_DBID = val;
      rows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].UnitMeasure = unit.UnitMeasure;

      let filteredRows = this.state.filteredRows;
      filteredRows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].UnitMeasure_DBID = val;
      filteredRows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].UnitMeasure = unit.UnitMeasure;

      this.setState({ rows: rows, filteredRows: filteredRows });

      toast.success(
        this.state.currentField.EntityMetaType + ' successfully updated'
      );
    });
  };

  changeUnique = (val) => {
    Api({
      sp: 'updateFieldUnique',
      json: { id: this.state.currentField.EntityMetaType_DBID, val: val },
    }).then(() => {
      let unique = val;
      val = parseInt(val) ? 'True' : 'False';
      let rows = this.state.rows;
      rows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].IsUnique = val;
      rows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].unique = unique;
      let filteredRows = this.state.filteredRows;
      filteredRows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].IsUnique = val;
      filteredRows.filter(
        (x) =>
          x.EntityMetaType_DBID === this.state.currentField.EntityMetaType_DBID
      )[0].unique = unique;
      this.setState({ rows: rows, filteredRows: filteredRows });
      toast.success(
        this.state.currentField.EntityMetaType + ' successfully updated'
      );
    });
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';

    // Save selected sort column & order to cookies
    this.bake('orderBy-Fields', property);
    this.bake('order-Fields', isAsc ? 'desc' : 'asc');

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  isSaveButtonDisabled() {
    return (
      this.state.newMetaType.length < 3 ||
      this.state.newDescription.length < 3 ||
      this.state.newUnitId === null ||
      this.selectedIsUnique === null ||
      this.allowMultiple === null ||
      this.currentDataType === null
    );
  }

  render() {
    let addOptionButton;
    addOptionButton = this.state.newOptions.length ? (
      <Button onClick={() => this.addOption()}>Add Option</Button>
    ) : null;

    let addOptionButton2 = this.state.currentOptions.length ? (
      <Button onClick={() => this.addCurrentOption()}>Add Option</Button>
    ) : null;

    return IsRole(
      [
        'Admin',
        'Equipment Manager',
        'Material Manager',
        'Dev',
        'Material Storage',
      ],
      this.props.roles
    ) ? (
      <div className="main-container">
        <div className="mt-2 d-flex justify-content-between">
          {/* Page Title */}
          <h2>Fields</h2>

          {/* Add Field Button */}
          {IsRole(['Equipment Manager', 'Admin']) ? (
            <ButtonGroup className="mb-2">
              <Button
                variant="primary"
                className="ms-auto text-nowrap"
                onClick={this.showModal}
              >
                Add Field
              </Button>
            </ButtonGroup>
          ) : null}
        </div>

        <Stack className="pb-2" direction="horizontal" gap={3}>
          {/* Search Bar */}
          <Form.Control
            value={this.state.searchText}
            type="text"
            placeholder="Search"
            onChange={(e) => this.filterList(e.target.value)}
            autoFocus
          />
        </Stack>

        {/* Material Table */}
        <Paper sx={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            className="tableContainer"
            id="tab-container"
          >
            <Table stickyHeader aria-label="sticky table">
              {/* Column Headers */}
              <TableHead>
                <TableRow>
                  {this.state.columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sortDirection={
                        this.state.orderBy === column.id
                          ? this.state.order
                          : false
                      }
                      className="table-header"
                    >
                      {/* Non-sortable & sortable column headers */}
                      {column.id === 'Delete' ? (
                        <span>{column.label}</span>
                      ) : (
                        <TableSortLabel
                          active={this.state.orderBy === column.id}
                          direction={
                            this.state.orderBy === column.id
                              ? this.state.order
                              : 'asc'
                          }
                          onClick={(e) => this.handleRequestSort(e, column.id)}
                        >
                          <span className="pr-2">{column.label}</span>
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* Table Content */}
              <TableBody>
                {this.state.filteredRows
                  .slice()
                  .sort(getComparator(this.state.order, this.state.orderBy))
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((row) => {
                    return (
                      <TableRow
                        className="pointer"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.EntityMetaType_DBID}
                        onClick={() => this.cardClick(row)}
                      >
                        {this.state.columns.map((column) => {
                          const value = row[column.id];
                          return column.id === 'Delete' ? (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              onClick={(e) => this.confirmDelete(e, row)}
                              sx={this.tableCellHover}
                            >
                              <Trash color="red" size={18} />
                            </TableCell>
                          ) : (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage="Rows:"
            rowsPerPageOptions={ROWSPERPAGE}
            component="div"
            count={this.state.filteredRows.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>

        {/* Add Field Modal */}
        <Modal
          className="add-field"
          onEntered={() => this.textInput.current.focus()}
          show={this.state.isOpen}
          onHide={this.hideModal}
        >
          <Modal.Header>
            <Modal.Title>Add Configuration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2">
              Enter new Entity Configuration Field:
              <Form.Control
                ref={this.textInput}
                type="text"
                placeholder="Field Name"
                onBlur={(e) =>
                  this.setState({ newMetaType: e.target.value.trim() })
                }
              />
            </div>
            <div className="mb-2">
              Enter Field Description:
              <Form.Control
                type="text"
                placeholder="Field Description"
                onBlur={(e) =>
                  this.setState({ newDescription: e.target.value })
                }
              />
            </div>
            <div className="mb-2">
              Unit of Measure:
              <Autocomplete
                autoHighlight={true}
                className="type-ahead"
                id="unit-measure"
                options={this.state.units}
                getOptionLabel={(option) => option.UnitMeasure}
                hiddenlabel="true"
                value={this.selectedUOM}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Unit of Measure"
                  />
                )}
                onChange={(_event, newUOM) => {
                  this.updateUnit(newUOM);
                }}
              />
            </div>
            <div className="mb-2">
              <div className="d-flex justify-content-between">
                <div>Field Value is Unique:</div>
                <div>Value is Unique by Field:</div>
              </div>
              <div className="d-flex justify-content-between">
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead w-100"
                  id="is-unique"
                  options={this.trueFalse}
                  disableClearable
                  getOptionLabel={(option) => option.text}
                  hiddenlabel="true"
                  value={this.selectedIsUnique}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Is Unique"
                    />
                  )}
                  onChange={(_event, selected) => {
                    this.setState({
                      isUnique: selected?.value ?? false,
                      selectedUniqueBy: this.state.fields[0],
                    });
                    this.allowMultiple = { id: 0, value: false, text: 'False' };
                    this.selectedIsUnique = selected;
                  }}
                />
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead w-100"
                  id="unique-by"
                  options={this.state.fields}
                  getOptionLabel={(option) => option.EntityMetaType}
                  hiddenlabel="true"
                  value={this.state.selectedUniqueBy}
                  disabled={!this.state.isUnique}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Unique By Field"
                    />
                  )}
                  onChange={(_event, val) => {
                    this.updateUniqueField(val);
                  }}
                />
              </div>
            </div>
            {this.selectedIsUnique?.value === false ? (
              <div className="mb-2">
                Allow Multiple Entries:
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="allow-multiple"
                  options={this.trueFalse}
                  disableClearable
                  getOptionLabel={(option) => option.text}
                  hiddenlabel="true"
                  value={this.allowMultiple}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Multiple Entries"
                    />
                  )}
                  onChange={(_event, selected) => {
                    this.setState({
                      multiple: selected !== null ? selected.value : false,
                    });
                    this.allowMultiple = selected;
                  }}
                />
              </div>
            ) : null}
            <div className="mb-2">
              Select Field Data Type:
              <Autocomplete
                autoHighlight={true}
                className="type-ahead"
                id="data-type"
                options={this.state.dataTypes}
                getOptionLabel={(option) => option.DataType}
                hiddenlabel="true"
                value={this.currentDataType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Data Type"
                  />
                )}
                onChange={(_event, selected) => {
                  this.updateDataType(selected);
                }}
              />
            </div>
            {this.state.newOptions.map((child, j) => {
              return (
                <div key={j}>
                  <Form.Control
                    placeholder="Option"
                    className="form-control mb-1 mt-2"
                    onBlur={(e) => this.updateOptions(e.target.value, j)}
                  />
                </div>
              );
            })}
            {addOptionButton}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.hideModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                this.addMetaType();
              }}
              disabled={this.isSaveButtonDisabled()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal */}
        <Modal show={this.state.editing} onHide={this.endEdit}>
          <Modal.Header closeButton={true}>
            <Modal.Title>
              <div className="renameLabel">
                {IsRole(['Equipment Manager', 'Admin']) ? 'Edit' : null}
                &nbsp;
              </div>

              {this.state.renaming ? (
                <div className="renameInput">
                  <Form.Control
                    type="text"
                    className="inputBoxText"
                    autoFocus
                    placeholder="Template Name"
                    defaultValue={this.state.currentField.EntityMetaType}
                    onBlur={(e) => this.renameField(e.target.value)}
                  />
                </div>
              ) : (
                <Hint
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  title="Click to rename field"
                >
                  <span
                    className="pointer child-link"
                    onClick={() => this.startRenaming()}
                  >
                    {this.state.currentField.EntityMetaType}
                  </span>
                </Hint>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!IsRole(['Equipment Manager', 'Admin']) ? (
              <Stack className="pt-2" direction="vertical">
                <span className="fieldDetail">
                  {this.state.currentField.EntityMetaType}
                </span>
                <h6 className="fieldDetail">Unit of Measure:</h6>
                <span className="fieldDetail">
                  {this.state.currentField.Description}
                </span>
                <h6 className="fieldDetail">Field Value is Unique:</h6>
                <span className="fieldDetail">
                  {this.flagValue(this.state.currentField.unique)}
                </span>
              </Stack>
            ) : (
              <div>
                Field Description:
                <Form.Control
                  className="form-control"
                  placeholder="Field Description"
                  onBlur={(e) => this.changeDescription(e.target.value)}
                  defaultValue={this.state.currentField.Description}
                />
                Unit of Measure:
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="unit-measure-edit"
                  value={
                    this.state.currentField.UnitMeasure?.length
                      ? { UnitMeasure: this.state.currentField.UnitMeasure }
                      : { UnitMeasure: '' }
                  }
                  options={this.state.units}
                  getOptionLabel={(option) => option.UnitMeasure}
                  hiddenlabel="true"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Unit of Measure"
                    />
                  )}
                  onChange={(_event, newValue) => {
                    this.changeUnit(
                      newValue === null ? 0 : newValue.UnitMeasure_DBID
                    );
                  }}
                />
              </div>
            )}

            {this.state.currentOptions.length
              ? this.state.currentOptions.map((child, j) => {
                  return !IsRole(['Equipment Manager', 'Admin']) ? (
                    <div key={j}>
                      <span className="fieldDetail">
                        {child.EntityMetaOption}
                      </span>
                    </div>
                  ) : (
                    <div key={child.EntityMetaOption_DBID}>
                      <Stack className="pb-2" direction="horizontal" gap={2}>
                        <Form.Control
                          placeholder="Option"
                          className="form-control mb-1 mt-2"
                          onBlur={(e) =>
                            this.updateCurrentOptions(e.target.value, child)
                          }
                          defaultValue={child.EntityMetaOption}
                        />
                        {this.state.currentOptions.length > 1 &&
                        this.state.currentOptions.filter(
                          (x) => x.EntityMetaOption && x.EntityMetaOption !== ''
                        ).length > 1 ? (
                          <Hint
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            title="Click to delete entry"
                          >
                            <Trash
                              className="pointer"
                              color="red"
                              onClick={() =>
                                this.updateCurrentOptions('', child)
                              }
                              size={18}
                            />
                          </Hint>
                        ) : null}
                      </Stack>
                    </div>
                  );
                })
              : ''}
            {IsRole(['Equipment Manager', 'Admin']) ? addOptionButton2 : null}
          </Modal.Body>
        </Modal>

        {/* Delete Confirmation Modal*/}
        <BasicModal
          show={this.state.deleting}
          handleClose={() => this.setState({ deleting: false })}
          modalTitleText={
            'Confirm Deletion Of ' + this.state.currentField.EntityMetaType
          }
          modalBodyText={'This will permanently delete the field.'}
          closeButtonText={'Cancel'}
          submitButtonText={'Confirm'}
          handleSubmit={() => this.deleteField()}
        />
      </div>
    ) : (
      <NoAccess />
    );
  }
}
export default EntityConfig;
