import { useEffect, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { ArrowDownSquareFill } from 'react-bootstrap-icons';
import { Api } from './interface';

const Location = (props) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    Api({
      sp: 'getLocationByEntity',
      json: { id: props.item.Entity_DBID },
    }).then((hierarchy) => {
      setLocations(hierarchy[0].Path.split(' > '));
    });
  }, [props.item]);

  return (
    <div className="text-center p-2 location-container">
      <h4>Location</h4>
      <hr />
      <Stack className="location-data">
        {locations.map((x, i) => {
          let arrow =
            i !== 0 ? (
              <div className="text-center">
                <ArrowDownSquareFill
                  size={24}
                  className="location-arrow m-2"
                  color="blue"
                />
              </div>
            ) : null;
          return (
            <div key={i}>
              {arrow}
              <div className="btn btn-outline-primary">{x}</div>
            </div>
          );
        })}
      </Stack>
    </div>
  );
};

export default Location;
