import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Api } from './interface';

const GroupSelect = (props) => {
  const [types, setTypes] = useState([]);
  const [locations, setLocations] = useState([
    { Path: 'No Location Selected' },
  ]);
  const [grouping] = useState(props.grouping);
  const [field, setField] = useState(undefined);
  const [location, setLocation] = useState({ Path: 'No Location Selected' });
  const [owner, setOwner] = useState({ UserName: 'No Owner Selected' });
  const [users, setUsers] = useState([{ UserName: 'No Owner Selected' }]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 50,
  });

  useEffect(() => {
    Api({
      sp: 'getGroupByMeta',
      json: { entityType: props.matType },
    }).then((response) => {
      setTypes(response);
      setField(response[0]);
    });
    Api({
      sp: 'getNonMaterialPaths',
      json: {},
    }).then((locations) => {
      setLocations(locations);
      setLocation(
        locations.filter((x) => x.Entity_DBID === props.locationId)[0]
      );
    });
    Api({
      sp: 'getUsers',
      json: {},
    }).then((users) => {
      setUsers(users);
      setOwner(users.filter((x) => x.User_DBID === props.ownerId)[0]);
    });
  }, []);

  const updateLocation = (val) => {
    setLocation(val);
  };

  const updateOwner = (val) => {
    setOwner(val);
  };

  const getConfirmDisabled = () => {
    return !field || !location || !owner;
  };

  return types.length ? (
    <Modal
      size="lg"
      show={grouping}
      onHide={() => props.closeModal()}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Group View Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>
          Group by Field : <em>{field?.EntityMetaType}</em>
        </h6>
        <br />
        <h6>Filter by Location</h6>
        <Autocomplete
          autoHighlight={true}
          filterOptions={filterOptions}
          disablePortal
          id="location-lookup"
          size="small"
          options={locations}
          disableClearable
          value={location ?? null}
          sx={{ width: '100%' }}
          getOptionLabel={(option) => {
            return option.Path;
          }}
          onChange={(event, newValue) => {
            updateLocation(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <br />
        <h6>Owner</h6>
        <Autocomplete
          autoHighlight={true}
          filterOptions={filterOptions}
          disablePortal
          id="location-lookup"
          size="small"
          options={users}
          disableClearable
          value={owner ?? null}
          sx={{ width: '100%' }}
          getOptionLabel={(option) => {
            return option.UserName;
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.User_DBID}>
              <span>{option.UserName}</span>
            </li>
          )}
          onChange={(event, newValue) => {
            updateOwner(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() =>
            props.update({
              EntityMetaType_DBID: -1,
              GroupLocationFilter: -1,
              User_DBID: -1,
            })
          }
        >
          Clear Groups
        </Button>
        <Button
          variant="primary"
          disabled={getConfirmDisabled()}
          onClick={() =>
            props.update({
              EntityMetaType_DBID: field.EntityMetaType_DBID,
              GroupLocationFilter: location.Entity_DBID,
              User_DBID: owner.User_DBID,
              owner: owner,
            })
          }
        >
          Show Groups
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default GroupSelect;
