import React from 'react';
//import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';

import { Button } from 'react-bootstrap';

export default function FilterDelete(props) {
  return (
    <Modal
      show={props.open}
      onHide={() => props.close()}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Delete Filter: {props.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>This filter will be deleted. Current view will not be affected.</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            props.close();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            props.delete();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
