import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Stack } from 'react-bootstrap';
import {
  ArrowCounterclockwise,
  Download,
  Folder2Open,
  FolderPlus,
  FolderSymlink,
  List,
  Share,
  Trash,
  Archive,
  Funnel,
  FunnelFill,
  Cart,
  PlusLg,
  Upload,
  ArchiveFill,
  UpcScan,
  PencilSquare,
} from 'react-bootstrap-icons';
import { DebounceInput } from 'react-debounce-input';
import { toast } from 'react-toastify';
import { Hint, ROWSPERPAGE } from './constants';
import { datePickerToShortDate, getDateTime, isDate, shortDate } from './date';
import EntityBulkUpload from './entityBulkUpload';
import EntityCreate from './entityCreate';
import ExportCSV from './exportCSV';
import FilterCRUD from './filterCRUD';
import FilterDelete from './filterDelete';
import GroupSelect from './groupSelect';
import { Api, Cosmos, Query } from './interface';
import { BuildGroupQuery } from './searchQuery';
import { IsRole } from './validator';
import { handleResize, oven, clearStorage } from './common';
import ActionContext from './actionContext';
import EntityBulkEdit from './entityBulkEdit.js';
import TransactionRequestCreate from './transactionRequestCreate';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { SortableHeader } from './header';
import {
  restrictToFirstScrollableAncestor,
  restrictToHorizontalAxis,
} from '@dnd-kit/modifiers';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import NavTabs from './navTabs.js';
import EntityLocationScan from './entityLocationScan.js';

export default function AdvancedSearch(props) {
  const [loadedFilter, setLoadedFilter] = useState(null);
  const [loadedFilterName, setLoadedFilterName] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loadedFilterId, setLoadedFilterId] = useState(null);
  const [currentFilterAction, setCurrentFilterAction] = useState(null);
  const [currentModal, setCurrentModal] = useState(null);
  const [actionContextAnchor, setActionContextAnchor] = useState(null);
  const [headerContextAnchor, setHeaderContextAnchor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [savedFields, setSavedFields] = useState([]);
  const [savedFilters, setSavedFilters] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentRow, setCurrentRow] = useState(null);
  const [currentColumn, setCurrentColumn] = useState(null);
  const [columns, setColumns] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [anchorSelection, setAnchorSelection] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [levels, setLevels] = useState([]);
  const [groupedColumns, setGroupedColumns] = useState([]);
  const [currentRowHover, setCurrentRowHover] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [contextRow, setContextRow] = useState(null);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [groupLocationFilter, setGroupLocationFilter] = useState(null);
  const [requestOwnerId, setRequestOwnerId] = useState(null);
  const [cartActive, setCartActive] = useState(false);
  const [request, setRequest] = useState({ reserved: [] });
  const [currentEntityType, setCurrentEntityType] = useState(null);
  const [uploaded, setUploaded] = useState([]);
  const [duplicates, setDuplicates] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [giveIts, setGiveIts] = useState(null);
  const [hovering, setHovering] = useState(false);
  const [defaultColumns, setDefaultColumns] = useState([
    { id: 'Name', value: -1, label: 'Name' },
  ]);
  const [archiveMode, setArchiveMode] = useState(false);
  const archivalFieldOrderStart = -998;
  const archivalFieldOrderEnd = -999;
  // const [savedRows, setSavedRows] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const locationState = useOutletContext();

  const currentModalRef = useRef(currentModal);
  const setCurrentModalRef = (data) => {
    currentModalRef.current = data;
    setCurrentModal(data);
  };

  const menuCol = {
    id: 'Menu',
    value: null,
    label: 'Actions',
    align: 'center',
    isFixed: true,
  };

  const groupColumns = [
    {
      id: 'EntityMetaType',
      value: -1,
      label: 'Field',
      //align: "center",
    },
    {
      id: 'EntityMetaValue',
      value: -2,
      label: 'Value',
      //align: "center",
    },
    {
      id: 'Available',
      value: -3,
      label: 'Available',
      //align: "center",
    },
    {
      id: 'Reserved',
      value: -4,
      label: 'Reserved',
      //align: "center",
    },
    {
      id: 'Total',
      value: -6,
      label: 'Total',
      //align: "center",
    },
    {
      id: 'EntityOwner',
      value: -5,
      label: 'Owner',
      //align: "center",
    },
  ];

  const bake = (g, c, v) => {
    if (typeof g === 'string') {
      return oven(props, false, g, c);
    } else {
      return oven(props, true, c, v);
    }
  };

  useEffect(() => {
    const rpp = parseInt(bake('rowsPerPage'), 10);
    bake('rowsPerPage', isNaN(rpp) ? 15 : rpp);
    setSearchText(bake('searchText') ?? '');
    setRowsPerPage(isNaN(rpp) ? 15 : rpp);
    setCurrentColumn(null);
    Api({
      sp: 'getDefaultFields',
      json: {},
    }).then((cols) => {
      let defaultCols = cols
        .filter((x) =>
          props.type
            ? x.Level === (props.level === '' ? 'Equipment' : props.level) &&
              x.EntityType === props.type
            : x.Level === (props.level === '' ? 'Equipment' : props.level)
        )
        .map((x) => ({
          id: x.EntityMetaType,
          value: x.EntityMetaType_DBID,
          label: x.EntityMetaType,
        }));
      setDefaultColumns(defaultCols);

      Api({
        sp: 'getEntityTypes',
        json: {},
      }).then((response) => {
        const type = props.type
          ? response.filter((x) => x.EntityType === props.type)[0]
              .EntityType_DBID
          : null;
        setCurrentEntityType(type);
        const sfid = searchParams.get('sfid');
        if (sfid) {
          Api({
            sp: 'getUserFilterById',
            json: { id: sfid },
          }).then((response) => {
            setSearchParams('');
            loadFilter(
              JSON.parse(response[0].Filter),
              response[0].FilterName,
              sfid,
              type,
              true
            );
          });
        } else {
          fetchData(true, type, defaultCols);
        }
      });
    });
  }, []);

  useEffect(() => {
    handleResize();
  });

  // handle site location filter update
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    getPage({});
  }, [locationState.locationFilter]);

  const buildFilterObject = (cols) => {
    return {
      fields: columns.filter((x) => x.id !== 'Menu') ?? [],
      filters: bake('filters') ?? [],
      order: bake('order') ?? 'asc',
      orderBy: bake('orderBy') ?? cols[0].id,
      rows: bake('rowsPerPage') ? Number(bake('rowsPerPage')) : 15,
      text: bake('searchText') ?? '',
    };
  };

  const saveFilter = (name) => {
    const filter = buildFilterObject(columns);
    Api({
      sp: 'saveFilter',
      json: {
        level: props.level.length ? props.level : 'Equipment',
        type: props.type,
        name: name,
        filter: filter,
      },
    }).then(() => {
      toast.success('Filter Saved');
      setCurrentModalRef(null);
      setLoadedFilter(filter);
      setLoadedFilterName(name);
    });
  };

  const loadFilter = (
    filter = loadedFilter,
    name = loadedFilterName,
    filterId,
    type,
    shared = false
  ) => {
    bake(
      'fields',
      filter.fields.filter((x) => x.id !== 'Menu')
    );
    bake('filters', filter.filters);
    bake('searchText', filter.text);
    bake('orderBy', filter.orderBy);
    bake('order', filter.order);
    bake('rowsPerPage', filter.rows);
    toast.success('Filter Loaded');
    setCurrentModalRef(null);
    setSavedFields(filter.fields.filter((x) => x.id !== 'Menu'));
    setSavedFilters(filter.filters);
    setOrder(filter.order);
    setOrderBy(filter.orderBy);
    setRowsPerPage(filter.rows);
    setSearchText(filter.text);
    if (!shared) {
      setLoadedFilter(filter);
      setLoadedFilterName(name);
      setLoadedFilterId(filterId);
    }
    fetchData(true, type);
  };

  const deleteFilter = () => {
    Api({
      sp: 'deleteFilter',
      json: {
        level: props.level.length ? props.level : 'Equipment',
        name: loadedFilterName,
      },
    }).then(() => {
      toast.success('Filter Deleted');
      setLoadedFilter(null);
      setLoadedFilterName(null);
      setLoadedFilterId(null);
      setCurrentModalRef(null);
    });
  };

  const shareFilter = () => {
    navigator.clipboard.writeText(
      window.location.href + '?sfid=' + loadedFilterId
    );
    toast.success('Link copied');
  };

  const openFilterModal = (action) => {
    setCurrentModalRef('FilterCRUD');
    setCurrentFilterAction(action);
  };

  const filterAction = (filter, action) => {
    if (action === 'save') {
      saveFilter(filter.FilterName);
    } else {
      loadFilter(
        JSON.parse(filter.Filter),
        filter.FilterName,
        filter.UserFilter_DBID
      );
    }
  };

  const handleTextChange = (event) => {
    // Save search text to cookies
    bake('searchText', event.target.value);
    setSearchText(event.target.value);
    setPage(0);

    getPage({ text: event.target.value, page: 0 });
  };

  const handleClickOutside = (event) => {
    if (
      ![...event.target.classList].some((x) => x.includes('row')) &&
      ![...event.srcElement.classList].some((x) => x.includes('row')) &&
      // Handle click on nested path element within the Actions menu svg
      !(event.target.tagName === 'path') &&
      // do not clear selection if menu is open or modals are open
      !event.target.dataset.modal &&
      !currentModalRef.current &&
      !event.target.classList.contains('MuiMenuItem-root')
    ) {
      setSelectedRows([]);
      setAnchorSelection(undefined);
    }
  };

  const keyDown = () => {};

  const fetchData = (initial, currentType, defaultCols, enableArchive) => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', keyDown, true);
    let manager =
      (['Material'].includes(props.level) ? 'Material' : 'Equipment') +
      ' Manager';

    setGroupId(bake('groupId') ? (Number(bake('groupId')) ?? -1) : -1);
    setGroupLocationFilter(
      bake('groupLocationFilter')
        ? (Number(bake('groupLocationFilter')) ?? -1)
        : -1
    );
    setRequestOwnerId(
      bake('requestOwnerId') ? (Number(bake('requestOwnerId')) ?? -1) : -1
    );

    let canEdit =
      props.level === 'Lab'
        ? IsRole([
            'Material Manager',
            'Equipment Manager',
            'Admin',
            'Planner',
            'Material Storage',
          ])
        : props.level === 'Location'
          ? IsRole(['Admin', 'Equipment Manager'])
          : ['Material'].includes(props.level)
            ? IsRole(['Material Manager', 'Planner', 'Admin'])
            : IsRole(['ES', manager, 'Admin']);

    setCanEdit(canEdit);

    // get levels for page filtering
    Api({
      sp: 'getLevels',
      json: { level: props.level },
    }).then((response) => {
      setLevels(response);

      // Get data based on view, page, result limit, and filters
      getPage({
        initial: initial,
        levels: response,
        orderBy: orderBy,
        desc: order === 'desc' ? 1 : 0,
        text: bake('searchText') ?? '',
        type: currentType,
        cols: bake('fields') ?? defaultCols,
        archiveMode: enableArchive,
      });
    });

    handleResize();
  };
  // bulk edit after data has been verified and processed
  const bulkEdit = (result) => {
    setSpinner(true);
    Api({
      sp: 'entitiesBulkUpdate',
      json: {
        finalData: result.finalJSON,
        keys: result.keys,
        isName: result.isName,
        isLocation: result.isLocation,
      },
    })
      .then((response) => {
        if (response?.length) {
          toast.success(
            response[0]['TotalRows'] + ' entries successfully updated!'
          );
        }
        closeModal();
        fetchData(true);
        setSpinner(false);
      })
      .catch((e) => {
        console.log('Error ' + e);
        setSpinner(false);
        toast.error('An error occured while trying to process your request.');
      });
  };

  const bulkUpload = (
    finalData,
    keys,
    query,
    parentId,
    typeId,
    fileName,
    parentEntityList,
    entityType,
    entityStatus,
    giveComment
  ) => {
    setSpinner(true);
    Api({
      sp: 'bulkUpload',
      json: {
        finalData: finalData,
        keys: keys,
        query: query,
        parentId: parentId,
        typeId: typeId,
        matFlag: props.level === 'Material' ? 1 : 0,
        ssdType: entityType === 'SSD' ? 1 : 0,
        locFlag: props.level === 'Location' ? 1 : 0,
        status: entityStatus,
        giveComment: giveComment,
      },
    })
      .then((response) => {
        let uploadedRows = response.filter((res) => res.Dup_Row === 0);
        let dups = response.filter((res) => res.Dup_Row === 1);

        if (uploadedRows.length > 0) {
          // Add new entities to entityLineage container in Cosmos
          if (['Component'].includes(entityType)) {
            // Transform response to match entityLineage container format
            const documentArr = response.map((obj) => {
              const {
                Entity_DBID: entityDBID,
                LotQuantity: lotQuantity,
                Transaction_DBID: transactionDBID,
              } = obj;

              return {
                entityDBID,
                lotQuantity,
                parents: [],
                createdDate: new Date(),
                pk: entityType.toLowerCase(),
                ...(transactionDBID && { transactionDBID }), // Only add transactionDBID property if part of Bulk Upload Give It
              };
            });

            Cosmos({
              cosmosApi: 'addDocuments',
              containerId: 'entityLineage',
              documentArr: documentArr,
            });
          }

          // Set Give Its
          let giveIts = {};
          if (uploadedRows[0].Transaction_DBID) {
            uploadedRows.map((row) => {
              let giveId = row.Transaction_DBID;
              if (giveId in giveIts) {
                giveIts[giveId].push(row.Entity_DBID);
              } else {
                giveIts[giveId] = [];
                giveIts[giveId].push(row.Entity_DBID);
              }
            });
            setGiveIts(giveIts);
          }

          setUploaded(uploadedRows);

          setSpinner(false);

          fetchData(true);
        } else {
          setDuplicates(dups);
          setSpinner(false);
        }
      })
      .catch((e) => {
        console.log('Error ' + e);
        setSpinner(false);
        toast.error('An error occured while trying to process your request.');
      });
  };

  const getPage = (data) => {
    let cols = data.cols ?? bake('fields') ?? defaultColumns;
    setFilteredRows([]);
    if (!data.paging) {
      setTotalRows(0);
    }
    setLoading(true);
    const json = getQueryJSON(
      data.levels ?? levels,
      data,
      cols,
      data.type ?? currentEntityType,
      data.archiveMode ?? archiveMode
    );
    let group = data.group ?? Number(bake('groupId'));
    cols = !group || group === -1 ? cols : groupColumns;

    cols = cols.filter((x) => x.id !== 'Menu');

    cols.push(menuCol);
    setColumns(cols);

    if (!group || group === -1) {
      setGroupedColumns([]);
      Api({
        sp: 'searchQuery',
        json: json,
      }).then((response) => {
        // Pop row count from the response and use it to set total rows
        let rowCountIndex = response.findIndex(
          (obj) => obj.EntityName === 'Row Count' && obj.Entity_DBID === -1
        );
        let rowCount = response.splice(rowCountIndex, 1);
        // TODO: Need to create one more ticket as searchQuery is getting called twice on every event on page
        if (rowCount[0].EntityType_DBID === 0) {
          toast.info(
            'No Records found. Check column filters and site-wide filter settings'
          );
        }

        setTotalRows(+rowCount[0].EntityType_DBID);
        let rows = [];
        response.forEach((r) => {
          // format the values here and then decide whether to create new array or push to existing
          const entityMetaValue =
            r.DataType === 'Binary'
              ? r.EntityMetaValue === '1'
                ? 'True'
                : null
              : r.DataType === 'Date'
                ? datePickerToShortDate(r.EntityMetaValue)
                : r.EntityMetaValue;
          if (rows.some((x) => x.Entity_DBID === r.Entity_DBID)) {
            let row = rows.filter((x) => x.Entity_DBID === r.Entity_DBID)[0];
            if (r.EntityMetaType === 'EntityStatus') {
              row['Status'] = entityMetaValue;
            }
            if (r.EntityMetaType === 'EntityOwnerName') {
              row['Owner'] = entityMetaValue;
            }
            row[r.EntityMetaType] = row[r.EntityMetaType] ?? [];
            row[r.EntityMetaType].push(entityMetaValue || '');
          } else {
            const name =
              props.level === 'Material'
                ? 'MAT' + String(r.Entity_DBID).padStart(8, 0)
                : r.EntityName;
            let row = {
              Entity_DBID: r.Entity_DBID,
              EntityName: name,
              Name: name,
              Type: r.EntityType,
              ArchivedBy: r.ArchivedBy,
              ArchiveDate: r.ArchiveDate,
              EntityType_DBID: r.EntityType_DBID,
              Path: r.Location,
              Location: r.Location?.split('>'),
              Status:
                r.EntityMetaType === 'EntityStatus'
                  ? r.EntityMetaValue
                  : undefined,
              Owner:
                r.EntityMetaType === 'EntityOwnerName'
                  ? r.EntityMetaValue
                  : undefined,
            };

            row[r.EntityMetaType] = [entityMetaValue];
            rows.push(row);
          }
        });
        setFilteredRows(rows);
        setSelectedRows(
          selectedRows.filter((x) =>
            rows.some((y) => x.Entity_DBID === y.Entity_DBID)
          )
        );
        setLoading(false);
      });
    } else {
      setGroupedColumns(cols);
      let payload = getGroupPayload(data, group, cols);
      Api({
        sp: 'getPageByGroup',
        json: payload,
      }).then((response) => {
        setTotalRows(response.length ? response[0].TotalRows : 0);
        response.forEach((x) => {
          x.Available = x.Total - x.Reserved ?? 0;
          x.Reserved = x.Reserved ?? 0;
        });
        let rowsTemp = response.filter((x) => x.EntityMetaType !== 'Total');
        setFilteredRows(rowsTemp);
        setSelectedRows(
          selectedRows.filter((x) =>
            rowsTemp.some((y) => x.EntityMetaValue === y.EntityMetaValue)
          )
        );
        setLoading(false);
      });
    }
  };

  const getGroupPayload = (data, group, cols) => {
    let rpp = parseInt(bake('rowsPerPage'), 10);
    rpp = isNaN(rpp) ? 15 : rpp;
    return {
      level: data.level ?? props.level,
      entityType: props.type,
      page: data.page ?? page,
      rows: data.rows ?? rpp,
      text: data.text ?? searchText,
      orderBy: data.orderBy ?? orderBy,
      desc: (data.desc ?? order === 'desc') ? 1 : 0,
      cols: cols,
      filters: data.filters ?? savedFilters,
      group: group,
      groupLocationFilter:
        data.groupLocationFilter ?? Number(bake('groupLocationFilter')),
      owner: data.requestOwnerId ?? Number(bake('requestOwnerId')),
    };
  };

  const refreshReserved = (data) => {
    let rows = filteredRows;
    rows.forEach((r) => {
      data.reserved.forEach((d) => {
        if (r.EntityMetaValue === d.EntityMetaValue) {
          r.Available -= d.reserve;
          r.Reserved += parseInt(d.reserve);
        }
      });
    });
  };

  const closeRequestModal = () => {
    setCurrentModalRef(null);
    setCartActive(false);

    getPage({
      group: bake('groupId'),
      groupLocationFilter: bake('groupLocationFilter'),
      requestOwnerId: bake('requestOwnerId'),
    });
  };

  const updateRequest = (data) => {
    setRequest(data);
  };

  const closeModal = (edit) => {
    //turn off spinner when closing modal
    setSpinner(false);
    if (edit) {
      setCurrentRow(edit);
      setCurrentModalRef('EntityEdit');
      setActionModalOpen(true);
    } else {
      setCurrentModalRef(null);
      if (!groupId || groupId === -1) {
        getPage({});
      }
    }
  };

  const handleClick = (e, row) => {
    switch (e.detail) {
      case 2:
        setCurrentRow(row);
        if (
          (!groupId || groupId === -1) &&
          !archiveMode &&
          currentModalRef !== 'EntityEdit'
        ) {
          setCurrentModalRef('EntityEdit');
          setActionModalOpen(true);
        }
        break;
      default:
        toggleSelected(row, e);
        return;
    }
  };

  const toggleSelected = (row, event) => {
    let rows = selectedRows;
    // sort rows array according to table sort
    let property = !groupedColumns.length ? 'Entity_DBID' : 'EntityMetaValue';
    if (rows.length) {
      // if control key is pressed, toggle current row selection without affecting other selected rows
      if (event.ctrlKey && !archiveMode) {
        if (rows.some((x) => x[property] === row[property])) {
          setSelectedRows(rows.filter((x) => x[property] !== row[property]));
        } else {
          rows.push(row);
          setCurrentRowHover(null);
          setSelectedRows(rows);
        }
      } else if (event.shiftKey && !archiveMode) {
        // get index of anchor row
        const anchorRow = filteredRows.findIndex(
          (o2) => o2[property] === anchorSelection[property]
        );
        // get index of current row
        const currentRow = filteredRows.findIndex(
          (o2) => o2[property] === row[property]
        );
        const firstSelectedRow =
          currentRow < anchorRow ? currentRow : anchorRow;
        const lastSelectedRow = currentRow > anchorRow ? currentRow : anchorRow;
        let newRows = [];
        // select all rows from first to last
        filteredRows.forEach((r, i) => {
          if (i >= firstSelectedRow && i <= lastSelectedRow) {
            newRows.push(r);
          }
        });
        setSelectedRows(newRows);
      } else {
        // if control key is not pressed and no rows are selected, select current row
        setSelectedRows([row]);
        setAnchorSelection(row);
      }
    } else {
      // toggle current row selection and clear any other selected rows
      setSelectedRows([row]);
      setAnchorSelection(row);
    }
  };

  const setRowHover = (row) => {
    // NOTE: This function causes the entire component to re-render on every hover
    if (!currentModal) {
      setCurrentRowHover(row);
    }
  };

  const clearRowHover = () => {
    // NOTE: This function causes the entire component to re-render on every hover
    if (!currentModal) {
      setCurrentRowHover(null);
    }
  };

  const isSelected = (row) => {
    let property = !groupedColumns.length ? 'Entity_DBID' : 'EntityMetaValue';
    return selectedRows.some((x) => x[property] === row[property]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getPage({ page: newPage, paging: true });
  };

  const handleChangeRowsPerPage = (event) => {
    // Save selected rows per page to cookies
    bake('rowsPerPage', parseInt(event.target.value, 10));

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    getPage({ rows: parseInt(event.target.value, 10), page: 0 });
  };

  // const clearCurrentRow = () => {
  //   setCurrentRow(null);
  //   setCurrentModalRef(null);
  //   getPage();
  // };

  const handleContextClick = (event, row) => {
    setCurrentRow(row);
    let property = !groupedColumns.length ? 'Entity_DBID' : 'EntityMetaValue';
    if (!selectedRows.some((x) => x[property] === row[property])) {
      setSelectedRows([]);
    }
    event.stopPropagation();

    setActionContextAnchor(event.currentTarget);
    setContextRow(currentRowHover);
  };

  const handleActionContextClose = (refresh, modal) => {
    setActionModalOpen(modal ? true : false);
    setCurrentModalRef(modal);
    setActionContextAnchor(null);
    setContextRow(null);
    if (refresh) {
      getPage({});
    }
  };
  const setGroup = (e) => {
    // Save groupId, groupLocationFilter and owner to cookies
    bake('groupId', e.EntityMetaType_DBID);
    bake('groupLocationFilter', e.GroupLocationFilter);
    bake('requestOwnerId', e.User_DBID);
    setGroupId(e.EntityMetaType_DBID);
    setGroupLocationFilter(e.GroupLocationFilter);
    setRequestOwnerId(e.User_DBID);
    setCurrentModalRef(null);
    setSelectedRows([]);
    setCurrentRow(null);
    setRequest({ reserved: [] });
    getPage({
      group: e.EntityMetaType_DBID,
      groupLocationFilter: e.GroupLocationFilter,
      requestOwnerId: e.User_DBID,
    });
  };

  const updateColumns = (cols) => {
    cols = cols.map((x) => ({
      id: x.EntityMetaType,
      value: x.EntityMetaType_DBID,
      label: x.EntityMetaType,
    }));
    cols = [...cols.filter((x) => x.id !== 'Menu')];

    cols.push(menuCol);
    setColumns(cols);
    bake(
      'fields',
      cols.filter((x) => x.id !== 'Menu')
    );
    let trimmedFilters = bake('filters')?.filter((f) =>
      cols.some((c) => c.id === f.key)
    );
    bake('filters', trimmedFilters);
    let localOrderBy = cols.some((x) => x.id === orderBy)
      ? orderBy
      : cols[0].id;
    setOrderBy(localOrderBy);
    bake('orderBy', localOrderBy);
  };

  const updateGroups = () => {
    setLoading(false);
    setFilteredRows([]);
    let cols = groupedColumns;
    cols = cols.filter((x) => x.label !== currentColumn.label);
    if (cols.length === groupedColumns.length) {
      cols.push(currentColumn);
    }
    setGroupedColumns(cols);
    setHeaderContextAnchor(null);
    if (!cols.length) {
      setColumns(columns.filter((x) => x.id !== 'Total'));
      getPage({
        cols: columns.filter((x) => x.id !== 'Total'),
      });
      return;
    }

    // Save selected sort column & order to cookies
    bake('orderBy', currentColumn.id);
    bake('order', 'asc');
    setOrder('asc');
    setOrderBy(currentColumn.id);
    setPage(0);

    getGroupedColumnData(cols, currentColumn.id, 'asc');
  };

  const getGroupedColumnData = (cols, orderBy, order) => {
    const body = BuildGroupQuery(cols, props.level, orderBy, order);
    Query({ query: body }).then((response) => {
      let cols = columns.filter((x) => x.id !== 'Total');
      cols.splice(cols.length - 1, 0, {
        id: 'Total',
        value: null,
        label: 'Total',
      });
      setFilteredRows(response);
      setColumns(cols);
      setLoading(false);
    });
  };

  const closeColumnFilter = (selected, column) => {
    let filters = bake('filters');
    filters = filters ? filters.filter((x) => x.key !== column.id) : [];
    let colFilter = [];
    selected.forEach((s) => {
      colFilter.push({
        key: column.id,
        field: column.id,
        label: s.EntityMetaValue,
        value: s.EntityMetaValue,
        DBID: s.DBID ?? column.value,
      });
    });
    let newFilters = filters.concat(colFilter);
    setCurrentModalRef(null);
    // if no filter changes, do nothing
    if (JSON.stringify(bake('filters')) === JSON.stringify(newFilters)) {
      return;
    }
    bake('filters', newFilters);
    setSavedFilters(newFilters);
    setPage(0);
    getPage({ filters: newFilters, page: 0 });
  };

  const resetColumns = () => {
    clearStorage(props, 'fields');
  };

  const headerSortClick = (col) => {
    let localOrder = bake('order');
    let localOrderBy = bake('orderBy');
    if (col.id === localOrderBy) {
      localOrder = localOrder === 'asc' ? 'desc' : 'asc';
      bake('order', localOrder);
      setOrder(localOrder === 'asc' ? 'desc' : 'asc');
    } else {
      localOrderBy = col.id;
      localOrder = 'asc';
      bake('orderBy', localOrderBy);
      bake('order', localOrder);
      setOrder(localOrder);
      setOrderBy(localOrderBy);
    }

    getPage({
      orderBy: localOrderBy,
      order: localOrder,
      // page: 0,
    });
  };

  const getQueryJSON = (levels, data, cols, currentType, archiveMode) => {
    let levelIds =
      props.level === ''
        ? [2, 3, 4]
        : [levels.filter((x) => x.Level === props.level)[0]?.Level_DBID];

    let invalidEntityLevelIds =
      props.level === 'Material'
        ? levels.filter((x) => x.Level === 'EPO').map((a) => a.Level_DBID)
        : [];

    // set whether these columns exist in order to search their values
    let searchEntityName = cols.some(
      (x) => x.id === 'EntityName' || x.id === 'Name'
    );
    let searchArchivedBy = cols.some((x) => x.id === 'ArchivedBy');
    let searchArchiveDate = cols.some((x) => x.id === 'ArchiveDate');

    let searchEntityType = cols.some(
      (x) => x.id === 'EntityType' || x.id === 'Type'
    );
    let searchLocation = cols.some((x) => x.id === 'Location');
    let searchMeta = cols.some((x) => x.value !== null);

    let meta = [];
    let localOrderBy = data.orderBy ?? orderBy ?? cols[0].id;
    let exactEntityTypeIds = [];
    let exactEntityNames = [];
    let exactArchivedBy = [];
    let exactArchiveDate = [];
    let orderById = -1;
    let filters = data.filters ?? bake('filters');
    let metaDict = {};
    let searchExactMeta = false;
    let exactMetaCount = -1;
    let exactLocations = [];
    let metaIds = '';
    // set order
    if (
      !['Name', 'Type', 'Location', 'ArchiveDate', 'ArchivedBy'].includes(
        localOrderBy
      )
    ) {
      orderById = cols.filter((x) => x.id === localOrderBy)[0]?.value;
      localOrderBy = 'Meta';
    } else {
      localOrderBy =
        localOrderBy === 'Location' ? localOrderBy : 'Entity' + localOrderBy;
    }

    // get columns
    cols.forEach((col) => {
      if (col.value && !(col.value in metaDict)) {
        metaDict[col.value] = [];
      }
    });

    // get filters
    if (filters) {
      filters.forEach((filter) => {
        if (filter.key === 'Location') {
          exactLocations.push(filter.value);
        } else if (filter.key === 'Type') {
          exactEntityTypeIds.push(filter.DBID);
        } else if (filter.key === 'Name') {
          exactEntityNames.push(filter.value);
        } else if (filter.key === 'ArchivedBy') {
          exactArchivedBy.push(filter.value);
        } else if (filter.key === 'ArchiveDate') {
          exactArchiveDate.push(filter.value);
        } else {
          searchExactMeta = true;
          if (!(filter.DBID in metaDict)) {
            metaDict[filter.DBID] = [filter.value];
          } else {
            if (metaDict[filter.DBID].indexOf(filter.value) === -1) {
              metaDict[filter.DBID].push(filter.value);
            }
          }
        }
      });
    }

    for (const [key, value] of Object.entries(metaDict)) {
      searchMeta = true;
      let metaObj = {};
      metaObj['metaId'] = parseInt(key);
      if (value !== undefined && value.length !== 0) {
        exactMetaCount += 1;
      }
      metaObj['exactValues'] = value;
      if (metaObj['metaId'] > 0) {
        meta.push(metaObj);
      }
    }

    // special rules for materials that are already filtered by type
    searchEntityType = props.type ? true : searchEntityType;
    exactEntityTypeIds = props.type ? [currentType] : exactEntityTypeIds;

    if (data.export) {
      metaIds = meta.map((m) => m.metaId);
    }

    return {
      searchTerm: data.text ?? searchText,
      searchEntityName: searchEntityName,
      exactEntityNames: exactEntityNames,
      searchArchivedBy: searchArchivedBy,
      exactArchivedBy: exactArchivedBy,
      searchArchiveDate: searchArchiveDate,
      exactArchiveDate: exactArchiveDate,
      searchEntityType: searchEntityType,
      exactEntityTypeIds: exactEntityTypeIds,
      searchLocation: searchLocation,
      exactLocations: exactLocations,
      meta: meta,
      searchExactMeta: searchExactMeta,
      exactMetaCount: exactMetaCount,
      searchMeta: searchMeta,
      orderBy: localOrderBy,
      orderById: orderById,
      orderByDirection: data.order ?? bake('order') ?? 'asc',
      page: data.page ?? page,
      limit: data.rows ?? parseInt(bake('rowsPerPage'), 10) ?? 15,
      levelIds: levelIds,
      invalidEntityLevelIds: invalidEntityLevelIds,
      metaIds: metaIds,
      locationFilter: bake(true, 'locationFilter'),
      archiveMode: archiveMode,
    };
  };

  const isHovered = (row) => {
    const hovered =
      (currentRowHover?.Entity_DBID === row.Entity_DBID &&
        !groupedColumns.length) ||
      (groupedColumns.length &&
        currentRowHover?.EntityMetaValue === row.EntityMetaValue);
    return hovered;
  };

  const handleDragStart = (event) => {
    setDragging(true);
    // setSavedRows(filteredRows);
    // setFilteredRows([]);
    setCurrentColumn(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id && over.id !== 'Menu') {
      let cols = columns;
      let startIndex = cols.findIndex((x) => x.id === active.id);
      let endIndex = cols.findIndex((x) => x.id === over.id);
      let item = cols.splice(startIndex, 1);
      cols.splice(endIndex, 0, item[0]);
      setColumns(cols);
      bake(
        'fields',
        cols.filter((x) => x.id !== 'Menu')
      );
    }
    setDragging(false);
    setCurrentColumn(null);
    // setFilteredRows(savedRows);
    // setSavedRows([]);
  };

  const handleDragCancel = () => {
    setCurrentColumn(null);
    // setFilteredRows(savedRows);
    // setSavedRows([]);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 15,
      },
    })
  );

  const enableBulkUpload = () => {
    setCurrentModalRef('EntityBulkUpload');
    setUploaded([]);
    setDuplicates([]);
    setGiveIts(null);
  };

  const enableBulkEdit = () => {
    setCurrentModalRef('EntityBulkEdit');
  };

  const fetchArchivalView = (archiveFlag) => {
    setArchiveMode(archiveFlag);
    fetchData(true, undefined, undefined, archiveFlag);
  };

  return (
    <div className="main-container">
      <div className="mt-2 d-flex justify-content-between align-items-center">
        {/* Page Title */}
        <h2>
          {props.level === 'Material'
            ? props.type + 's'
            : props.level
              ? props.level + 's'
              : 'Equipment'}{' '}
          {loadedFilter ? ' - ' + loadedFilterName : null}
          {loadedFilter ? (
            <span>
              <Hint
                placement="top"
                delay={{ show: 250, hide: 400 }}
                title="Update Filter"
              >
                <FolderSymlink
                  className={'filter-save-icon'}
                  size={30}
                  onClick={() => {
                    saveFilter(loadedFilterName);
                  }}
                ></FolderSymlink>
              </Hint>
              <Hint
                placement="top"
                delay={{ show: 250, hide: 400 }}
                title="Discard Changes"
              >
                <ArrowCounterclockwise
                  className={'filter-save-icon'}
                  size={30}
                  onClick={() => {
                    loadFilter();
                  }}
                ></ArrowCounterclockwise>
              </Hint>
              <Hint
                placement="top"
                delay={{ show: 250, hide: 400 }}
                title="Share Filter"
              >
                <Share
                  className={'filter-save-icon'}
                  size={30}
                  color={'blue'}
                  onClick={() => {
                    shareFilter();
                  }}
                ></Share>
              </Hint>
              <Hint
                placement="top"
                delay={{ show: 250, hide: 400 }}
                title="Delete Filter"
              >
                <Trash
                  className={'filter-save-icon'}
                  size={30}
                  color={'red'}
                  onClick={() => {
                    setCurrentModalRef('FilterDelete');
                    setHeaderContextAnchor(null);
                  }}
                ></Trash>
              </Hint>
            </span>
          ) : null}
        </h2>

        {props.level === 'Material' && (
          <NavTabs
            navItems={[
              { name: 'INVENTORY', route: '/' + props.type.toLowerCase() },
              {
                name: 'TRANSACTIONS',
                route: '/transactions/' + props.type.toLowerCase(),
              },
            ]}
          />
        )}
      </div>

      <Stack direction="horizontal" gap={3}>
        {/* Search Bar */}
        <DebounceInput
          value={searchText}
          className="form-control"
          minLength={0}
          debounceTimeout={700}
          onChange={(event) => handleTextChange(event)}
          placeholder="Search"
        />

        {/* Quick Buttons Menu */}
        <ButtonGroup>
          {props.level !== 'EPO' && canEdit && !archiveMode ? (
            <>
              {/* Add Entity Button */}
              <Hint
                placement="top"
                delay={{ show: 250, hide: 400 }}
                title="Add Entity"
              >
                <Button
                  className="icon-btn"
                  variant="outline-primary"
                  onClick={() => setCurrentModalRef('EntityCreate')}
                >
                  <PlusLg size={24} />
                </Button>
              </Hint>

              {/* Bulk Upload Button */}
              <Hint
                placement="top"
                delay={{ show: 250, hide: 400 }}
                title="Bulk Upload"
              >
                <Button
                  className="icon-btn"
                  variant="outline-primary"
                  onClick={() => enableBulkUpload()}
                >
                  <Upload size={24} />
                </Button>
              </Hint>

              {/* Bulk Edit Button */}
              <Hint
                placement="top"
                delay={{ show: 250, hide: 400 }}
                title="Bulk Edit"
              >
                <Button
                  className="icon-btn"
                  variant="outline-primary"
                  onClick={() => enableBulkEdit()}
                >
                  <PencilSquare size={24} />
                </Button>
              </Hint>
            </>
          ) : null}

          <Hint
            placement="top"
            delay={{ show: 250, hide: 400 }}
            title="Export to Excel"
          >
            <Button
              className="icon-btn"
              type="radio"
              variant="outline-primary"
              onClick={() => setCurrentModalRef('ExportCSV')}
            >
              <Download className="export" size={20} />
            </Button>
          </Hint>

          <Hint
            placement="top"
            delay={{ show: 250, hide: 400 }}
            title="Save Filter"
          >
            <Button
              className="icon-btn"
              variant="outline-primary"
              onClick={() => openFilterModal('save')}
            >
              <FolderPlus size={24} />
            </Button>
          </Hint>

          <Hint
            placement="top"
            delay={{ show: 250, hide: 400 }}
            title="Load Filter"
          >
            <Button
              className="icon-btn"
              variant="outline-primary"
              onClick={() => openFilterModal('load')}
            >
              <Folder2Open size={24} />
            </Button>
          </Hint>

          {props.level === 'Material' && !archiveMode ? (
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Group View"
            >
              <Button
                className="icon-btn"
                variant="outline-primary"
                onClick={() => setCurrentModalRef('GroupedView')}
              >
                {!groupId || groupId === -1 ? (
                  <Funnel size={24} />
                ) : (
                  <FunnelFill size={24} />
                )}
              </Button>
            </Hint>
          ) : null}

          {!groupId || groupId === -1 ? (
            <>
              <Hint
                placement="top"
                delay={{ show: 250, hide: 400 }}
                title="Archive View"
              >
                <Button
                  className="icon-btn"
                  variant="outline-primary"
                  onClick={() => fetchArchivalView(!archiveMode)}
                >
                  {archiveMode ? (
                    <ArchiveFill size={23} />
                  ) : (
                    <Archive size={23} />
                  )}
                </Button>
              </Hint>

              {!archiveMode ? (
                <Hint
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  title="Scan items for location confirmation"
                >
                  <Button
                    className="icon-btn"
                    variant="outline-primary"
                    onClick={() => setCurrentModal('EntityLocationScan')}
                  >
                    <UpcScan size={23} />
                  </Button>
                </Hint>
              ) : null}
            </>
          ) : null}

          {props.level === 'Material' && groupId !== -1 ? (
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="View Request Cart"
              open={hovering}
            >
              <Button
                disabled={request?.reserved?.length ? false : true}
                variant="outline-primary"
                onMouseOver={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                onClick={() => {
                  if (request.reserved.length) {
                    setCurrentModalRef('RequestCreate');
                    setCartActive(true);
                  }
                }}
              >
                <Cart size={24} />
              </Button>
            </Hint>
          ) : null}
        </ButtonGroup>
      </Stack>

      {/* Material Table */}
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer
          id="tab-container"
          component={Paper}
          // className={dragging ? "tableContainer no-scroll" : "tableContainer"}
          className="tableContainer"
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            padding="none"
          >
            {/* Column Headers */}
            <TableHead>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
                modifiers={[
                  restrictToHorizontalAxis,
                  restrictToFirstScrollableAncestor,
                ]}
              >
                <SortableContext items={columns} strategy={() => {}}>
                  <TableRow className="header-div">
                    {columns
                      //Archival Fields should always be displayed last and should not display in grouped view
                      //The order of columns is defined by the field "value" in groupColumns field and the order
                      //of default columns, including arhival fields, is fetched from the stored procedure.
                      .filter((column) => {
                        return !archiveMode
                          ? column.value > archivalFieldOrderStart ||
                              column.value === null
                          : column.value >= archivalFieldOrderEnd ||
                              column.value === null;
                      })
                      .map((column, i) => {
                        return (
                          <SortableHeader
                            key={column.id}
                            column={column}
                            columns={columns}
                            order={bake('order')}
                            orderBy={bake('orderBy') ?? columns[0].id}
                            index={i}
                            groupedColumns={groupedColumns}
                            filters={bake('filters') ?? []}
                            headerContextAnchor={headerContextAnchor}
                            sort={headerSortClick}
                            level={props.level}
                            updateColumns={updateColumns}
                            updateGroups={updateGroups}
                            getPage={getPage}
                            closeColumnFilter={closeColumnFilter}
                            resetColumns={resetColumns}
                            currentModal={currentModal}
                            grouped={
                              groupId && groupId !== -1
                                ? (groupId && groupId !== -1).toString()
                                : 'false'
                            }
                            type={props.type}
                          />
                        );
                      })}
                  </TableRow>
                </SortableContext>
                <DragOverlay>
                  {dragging ? (
                    <SortableHeader
                      column={columns.find((x) => x.id === currentColumn)}
                      columns={columns}
                      index={columns.findIndex(
                        (x) => x.id === currentColumn.id
                      )}
                    />
                  ) : null}
                </DragOverlay>
              </DndContext>
            </TableHead>

            {/* Table Content */}
            <TableBody>
              {/* Loading Spinner */}
              {loading ? (
                <div
                  className="spinner-border position-fixed top-50 start-50"
                  role="status"
                ></div>
              ) : null}

              {filteredRows.map((row, i) => {
                return (
                  <TableRow
                    onClick={(e) => handleClick(e, row)}
                    size="small"
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    onMouseEnter={() => setRowHover(row)}
                    onMouseLeave={clearRowHover}
                  >
                    {columns
                      .filter((column) => {
                        return !archiveMode
                          ? column.value > archivalFieldOrderStart ||
                              column.value === null
                          : column.value >= archivalFieldOrderEnd ||
                              column.value === null;
                      })
                      .map((column, i) => {
                        const value = row[column.id ?? column.label];
                        let tableCellClass =
                          i === columns.length - 1 ? 'sticky-last-col' : '';
                        tableCellClass += ' search-table-col';
                        tableCellClass += isHovered(row)
                          ? ' search-row-hover'
                          : '';
                        tableCellClass += isSelected(row)
                          ? ' search-row-selected'
                          : '';
                        return (
                          <TableCell
                            size="small"
                            key={i}
                            align={column.align}
                            className={tableCellClass}
                          >
                            {/* Actions Menu*/}
                            {column.id === 'Menu' ? (
                              <List
                                size={24}
                                className="pointer row-menu-icon"
                                onClick={(e) => handleContextClick(e, row)}
                              />
                            ) : null}

                            {/* Split array values with nice boxes  */}
                            {Array.isArray(value) ? (
                              value.length > 1 ? (
                                value.map((v, i) => {
                                  return (
                                    <span key={i} className="search-meta-value">
                                      {isDate(v) ? shortDate(v) : '' + v}
                                    </span>
                                  );
                                })
                              ) : isDate(value[0]) ? (
                                '' + shortDate(value[0])
                              ) : value[0]?.length > 40 ? (
                                <Hint
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  title={value[0]}
                                >
                                  <span>
                                    {value[0]?.substring(0, 40).concat('...')}
                                  </span>
                                </Hint>
                              ) : (
                                value
                              )
                            ) : column.format && typeof value === 'number' ? (
                              column.format(value)
                            ) : isDate(value) ? (
                              '' + shortDate(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="horizontal">
          {/* Selected Rows Count */}
          {selectedRows.length > 0 && (
            <p className="mat-table-rows-selected">
              Rows Selected: {selectedRows.length}
            </p>
          )}

          <TablePagination
            className="ms-auto"
            labelRowsPerPage="Rows:"
            rowsPerPageOptions={ROWSPERPAGE}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      </Paper>
      {currentModal === 'EntityCreate' ? (
        <EntityCreate
          level={props.level}
          type={props.type}
          srcLevel={props.level}
          creating={currentModal === 'EntityCreate'}
          closeModal={closeModal}
          epoSource={currentRow}
          epoSources={selectedRows.length > 0 ? selectedRows : [currentRow]}
        ></EntityCreate>
      ) : null}

      {currentModal === 'EntityBulkUpload' ? (
        <EntityBulkUpload
          level={props.level}
          type={props.type}
          rows={filteredRows}
          createBulk={currentModal === 'EntityBulkUpload'}
          closeModal={closeModal}
          saveData={bulkUpload}
          savedData={uploaded}
          giveIts={giveIts}
          duplicates={duplicates}
          spinner={spinner}
        ></EntityBulkUpload>
      ) : null}

      {currentModal === 'EntityBulkEdit' ? (
        <EntityBulkEdit
          level={props.level}
          type={props.type}
          createBulk={currentModal === 'EntityBulkEdit'}
          closeModal={closeModal}
          saveData={bulkEdit}
          spinner={spinner}
          canEdit={canEdit}
        ></EntityBulkEdit>
      ) : null}

      {currentModal === 'ExportCSV' ? (
        <ExportCSV
          csvData={filteredRows}
          fileName={
            (props.level ? props.level : 'Equipment') +
            (props.type ? '_' + props.type : '') +
            '_' +
            getDateTime()
          }
          columns={columns}
          order={order}
          orderBy={orderBy}
          exportCSV={currentModal === 'ExportCSV'}
          closeExport={closeModal}
          getGroupPayload={getGroupPayload}
          groupColumns={groupColumns}
          groupId={groupId}
          levels={levels}
          level={props.level}
          getQueryJSON={getQueryJSON}
          totalRows={totalRows}
          entityType={currentEntityType}
        />
      ) : null}

      {currentModal === 'FilterCRUD' ? (
        <FilterCRUD
          close={closeModal}
          action={currentFilterAction}
          open={currentModal === 'FilterCRUD'}
          crudAction={filterAction}
          level={props.level}
          type={props.type}
        ></FilterCRUD>
      ) : null}
      {currentModal === 'FilterDelete' ? (
        <FilterDelete
          open={currentModal === 'FilterDelete'}
          close={closeModal}
          name={loadedFilterName}
          delete={deleteFilter}
        ></FilterDelete>
      ) : null}
      {currentModal === 'GroupedView' ? (
        <GroupSelect
          grouping={currentModal === 'GroupedView'}
          closeModal={closeModal}
          update={setGroup}
          fieldId={Number(groupId)}
          locationId={Number(groupLocationFilter)}
          ownerId={Number(requestOwnerId)}
          matType={props.type}
        />
      ) : null}
      {currentModal === 'RequestCreate' ? (
        <TransactionRequestCreate
          requesting={currentModal === 'RequestCreate'}
          closeModal={closeRequestModal}
          update={updateRequest}
          request={request}
          refresh={refreshReserved}
          location={Number(groupLocationFilter)}
          entityOwner={Number(requestOwnerId)}
          isClassStoreMember={IsRole('Material Storage')}
          cartActive={cartActive}
          matType={props.type}
          items={
            selectedRows.length > 0
              ? selectedRows.filter((x) => x.Available > 0)
              : [currentRow]
          }
        />
      ) : null}
      {currentModal === 'EntityLocationScan' ? (
        <EntityLocationScan
          open={currentModal === 'EntityLocationScan'}
          close={closeModal}
          scanMode={true}
          // managers and planners can confirm location for the drives owned by other users
          canEdit={IsRole(['Material Manager', 'Planner', 'Admin'])}
        />
      ) : null}
      {actionContextAnchor || actionModalOpen ? (
        <ActionContext
          level={props.level}
          canEdit={canEdit}
          isClassStoreMember={IsRole('Material Storage')}
          grouped={groupId && groupId !== -1}
          currentRow={currentRow}
          columns={columns}
          contextAnchor={actionContextAnchor}
          handleActionContextClose={handleActionContextClose}
          selectedRows={selectedRows}
          contextRow={contextRow}
          currentModal={currentModal}
          closeModal={closeModal}
          request={request}
          matType={props.type}
          imgServer={bake(props, 'imgServer')}
          archiveMode={archiveMode}
        ></ActionContext>
      ) : null}
    </div>
  );
}
