import moment from 'moment';

const shortDate = (date) => {
  return moment.utc(date).format('MM/DD/YYYY');
};

const shortDtm = (date) => {
  return moment(date).format('MM/DD/YYYY hh:mm:ss');
};

const getDate = () => {
  return moment().format('MM/DD/YYYY');
};

const getDateTime = () => {
  return moment().format('MM/DD/YYYY_hh:mm:ss');
};

const isDate = (str) => {
  if (isNaN(str)) {
    return moment(str, moment.ISO_8601, true).isValid();
  } else {
    return false;
  }
};

const isDateValid = (val) => {
  return moment.isDate(val);
};
const datePickerToShortDate = (date) =>
  date !== 'null' ? shortDate(new Date(date).toUTCString()) : '';

const toDate = (date) => {
  let newDate = new Date(date);
  return newDate;
};

const diffMinutes = (dt2, dt1) => {
  let diff = Math.floor(Math.abs(dt2 - dt1) / 1000 / 60);
  // console.log(dt2, dt1, diff);
  return diff;
};

const getDaysArray = (s, e) => {
  // adding one extra day at range end to include last day's data in MongoDB
  e.setDate(e.getDate() + 1);
  for (
    var a = [], d = new Date(s);
    d <= new Date(e);
    d.setDate(d.getDate() + 1)
  ) {
    a.push(new Date(d));
  }
  return a;
};

const getDateFromDtm = (d) => {
  let dtm = new Date(d);
  return dtm.getMonth() + 1 + '/' + dtm.getDate() + '/' + dtm.getFullYear();
};
export {
  shortDate,
  shortDtm,
  isDate,
  getDate,
  datePickerToShortDate,
  toDate,
  diffMinutes,
  getDaysArray,
  getDateFromDtm,
  isDateValid,
  getDateTime,
};
