import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { clearCookies } from './common';
import { Autocomplete, TextField, Box, Chip } from '@mui/material';
import { Api } from './interface';
import debounce from 'lodash/debounce';
import { useOutletContext } from 'react-router-dom';

export default function UserPreferences() {
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [currentOption, setCurrentOption] = useState([]);
  // const [currentSelection, setCurrentSelection] = useState([]);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    Api({
      sp: 'getUserPrefs',
      json: {},
    }).then((response) => {
      const sel = JSON.parse(
        response.filter((x) => x.Type === 'Location')[0]?.Value
      );
      setSelectedLocations(sel);
    });
  }, []);

  const getOptionsDelayed = useCallback(
    debounce((text) => {
      setLocations([]);
      if (text.length) {
        Api({
          sp: 'getLocationPaths',
          json: { text: text.toLowerCase() },
        }).then((response) => {
          setLocations(response);
        });
      }
    }, 700),
    []
  );

  useEffect(() => {
    getOptionsDelayed(filterText, () => {
      // setLocations(filteredOptions);
    });
  }, [filterText, getOptionsDelayed]);

  const updateLocationFilterPref = (sel) => {
    Api({
      sp: 'updateUserPrefs',
      json: { type: 'Location', val: sel },
    }).then(() => {
      setSelectedLocations(sel);
    });
  };

  const updateFields = () => {
    let sel = selectedLocations;
    // let opt = currentOption.Path.trim();
    if (currentOption) {
      sel.push(currentOption);
      updateLocationFilterPref(sel);
    }
  };

  const onDelete = (val) => () => {
    let sel = selectedLocations.filter((v) => v.Path !== val);
    updateLocationFilterPref(sel);
  };

  const updateCurrentOption = (e, o) => {
    setCurrentOption(o);
  };

  const appContext = useOutletContext();

  const clearCache = () => {
    clearCookies();
    appContext.afterClearCache();
  };

  return (
    <div className="main-container">
      <div className="mt-2 d-flex justify-content-between">
        <h2>User Preferences</h2>

        <Button className={'primary mb-2'} onClick={clearCache}>
          Clear Cache
        </Button>
      </div>

      <h4 className="mt-2">Location Filter</h4>

      <Autocomplete
        size="lg"
        multiple
        filterSelectedOptions
        autoHighlight={true}
        id="filter-options"
        options={locations ?? []}
        noOptionsText={'Start typing to search'}
        getOptionLabel={(option) => option.Path}
        onHighlightChange={(e, o) => updateCurrentOption(e, o)}
        onChange={(event, newValue) => updateFields(event, newValue)}
        onInputChange={(e, newInputValue) => setFilterText(newInputValue)}
        renderTags={() => null}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField autoFocus {...params} label="Locations" />
        )}
      />
      <Box
        mt={1}
        className={'modal-box'}
        sx={{
          '& > :not(:last-child)': { mr: 1 },
          '& > *': { mr: 1, mt: 2 },
        }}
      >
        {selectedLocations.map((v, i) => (
          <Chip key={i} label={v.Path} onDelete={onDelete(v.Path)} />
        ))}
      </Box>
    </div>
  );
}
