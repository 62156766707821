import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Location from './entityLocation';
import Timeline from './entityTimeline';
import FieldView from './fieldView';
import GraphPicker from './graphPicker';
import HistoryView from './historyView';
import ImageView from './imageView';
import { Api, Sas } from './interface';

export default function Dash() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const entityId = useQuery().get('id');
  const [entity, setEntity] = useState({});
  const [report] = useState('Status Percentage');
  const [hostName, setHostName] = useState('');
  const [sas, setSas] = useState('');

  useEffect(() => {
    Sas().then((response) => {
      setSas(response.SAS_STRING);
    });
    Api({
      sp: 'getEntityNetworkHostName',
      json: { entityId: entityId },
    }).then((response) => {
      let entityMetaRow = response[0];
      setEntity(entityMetaRow);
      setHostName(entityMetaRow.NetworkHostName);
    });
  }, []);

  return (
    <>
      <div>
        <div className="mt-2 d-flex justify-content-between">
          <h2>{entity?.EntityName}</h2>
        </div>
      </div>
      <div className="d-flex justify-content-center widget-container">
        <div className="widget-section">
          <FieldView item={entity} />
        </div>
        <div className="widget-section">
          <Location item={entity} />
        </div>
        <div className="widget-section">
          <ImageView item={entity} sas={sas} />
        </div>
      </div>
      <div className="d-flex justify-content-center widget-container">
        <div className="widget-section">
          <div className="report">
            <div className="report-text text-center p-2">
              <h4>{report}</h4>
            </div>
          </div>

          <GraphPicker
            report={report}
            item={entity}
            data={[]}
            hostName={hostName}
          />
        </div>
        <div className="widget-section">
          <HistoryView item={entity} />
        </div>
        <div className="widget-section">
          <Timeline item={entity} />
        </div>
      </div>
    </>
  );
}
