import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const BasicModal = (props) => {
  return (
    <Modal
      size={props.size ?? 'md'}
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header closeButton="true">
        <Modal.Title>{props.modalTitleText}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{props.modalBodyText}</Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.handleClose}>
          {props.closeButtonText ?? 'Close'}
        </Button>

        <Button variant="primary" onClick={props.handleSubmit}>
          {props.submitButtonText ?? 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BasicModal;
