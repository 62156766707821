import { useLocation } from 'react-router-dom';
import { Api } from './interface';
import Excel from 'exceljs';
import { useEffect } from 'react';
import * as FileSaver from 'file-saver';
import { getDateTime } from './date';

export default function PendingTrs() {
  const useQuery = () => new URLSearchParams(useLocation().search);

  const days = useQuery().get('days');
  const workbook = new Excel.Workbook(); // Create a new workbook
  const worksheet = workbook.addWorksheet('data');
  let colList = [
    { header: 'Transaction_DBID', key: 'Transaction_DBID' },
    { header: 'Type', key: 'TransactionType' },
    { header: 'Status', key: 'Status' },
    { header: 'Comments', key: 'Comments' },
    { header: 'NewOwner', key: 'NewOwner' },
    { header: 'NewOwnerEmail', key: 'NewOwnerEmail' },
    { header: 'CurrentOwner', key: 'CurrentOwner' },
    { header: 'CurrentOwnerEmail', key: 'CurrentOwnerEmail' },
    { header: 'CreatedBy', key: 'CreatedBy' },
    { header: 'CreatedByUserEmail', key: 'CreatedByUserEmail' },
    { header: 'CreatedDtm', key: 'CreatedDtm' },
    { header: 'Location', key: 'Location' },
    { header: 'List', key: 'List' },
  ];
  worksheet.columns = colList;

  useEffect(() => {
    Api({
      sp: 'getInTransitTrasByDays',
      json: { days: days },
    }).then((response) => {
      worksheet.addRows(response);
      workbook.xlsx.writeBuffer().then(function (data) {
        var blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(
          blob,
          'Pending_Transaction_By_' +
            days +
            '_Days_On_' +
            getDateTime() +
            '.xlsx'
        );
      });
    });
  }, []);

  return (
    <>
      {' '}
      <div>
        <b>Pending Transactions Report for {days}</b>
      </div>
    </>
  );
}
