import { Outlet, NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import { ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Api } from './components/interface';
import Logo from './elm3.png';
// import { IsRole } from "./components/validator";
import { Hint, LINKS } from './components/constants';

import {
  handleResize,
  checkVersion,
  getSas,
  setEnvironmentVariables,
  oven,
} from './components/common';
import { BellFill, Magic, HouseFill, Globe } from 'react-bootstrap-icons';
import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';

export default function App(props) {
  const [user, setUser] = useState({});
  const [locationFilter, setLocationFilter] = useState(true);
  const [alertClick, setAlertClick] = useState(false);
  const [devMode, setDevMode] = useState(undefined);
  const [key, setKey] = useState(LINKS.HOME);
  const [isKiosk, setIsKiosk] = useState(props.isKiosk);
  const [roles, setRoles] = useState([]);
  // eslint-disable-next-line no-unused-vars

  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    authenticateUser();
  }, [authState, oktaAuth]);

  const bake = (g, c, v) => {
    if (typeof g === 'string') {
      return oven(props, false, g, c);
    } else {
      return oven(props, true, c, v);
    }
  };

  const authenticateUser = () => {
    // skip logging in with okta authentication if it's kiosk setup
    // kiosk is defined by "<elm home url 'elmxxxx.solidapps.net'>/kiosk"
    if (!props.isKiosk) {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        console.log('User is not logged in. Logging in.');
        setUser(null);
        oktaAuth.signInWithRedirect({ originalUri: '/' });
      } else {
        console.log('User is logged in.');

        oktaAuth
          .getUser()
          .then((info) => {
            Api({
              sp: 'addUserDetails',
              json: {
                idsid: info.IDSID,
                oid: info.oid,
                name: info.name,
                email: info.preferred_username,
                empid: info.employeeNumber,
              },
            }).then((response) => {
              info.oid = response[0]['@OID'];
              setUser(info);
              bake(true, 'user', info);
              login();
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } else {
      //if the url is kiosk set user info to kiosk
      //when badge is scanned while peforming any operation, user info will be sent to the api directly
      localStorage.clear();
      let user = { name: 'kiosk', unique_name: 'kiosk', oid: 'kiosk' };
      setUser(user);
      bake(true, 'user', user);
      bake(true, 'kioskUserOID', user.oid);
      setEnvironmentVariables();
    }
  };

  const updateLocationFilter = () => {
    setLocationFilter(!locationFilter);
    if (!locationFilter) {
      bake(true, 'locationFilter', 'enabled');
    } else {
      bake(true, 'locationFilter', 'disabled');
    }
  };

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    window.addEventListener('resize', handleResize);

    setIsKiosk(props.isKiosk);
    bake(true, 'isKiosk', props.isKiosk);

    setLocationFilter(bake(true, 'locationFilter') === 'enabled');

    // setUser({ name: "kiosk", unique_name: "kiosk", oid: "kiosk" });
    // bake(true, "user", { name: "kiosk", unique_name: "kiosk", oid: "kiosk" });
    // bake(true, "kioskUserOID", "kiosk");
    // login();
  };

  const afterClearCache = () => {
    setIsKiosk(props.isKiosk);
    bake(true, 'isKiosk', props.isKiosk);
    setLocationFilter(bake(true, 'locationFilter') === 'enabled');
    authenticateUser();
  };

  const login = () => {
    checkVersion();
    getRoles();
    getSas();
    setEnvironmentVariables();
    // roleUpdate();
  };

  const viewUpdates = () => {
    setAlertClick(true);
    setKey(LINKS.RELEASENOTES);
  };

  const toggleDevMode = () => {
    let dev =
      !bake('devMode') || bake('devMode') === 'false' ? 'true' : 'false';
    setDevMode(dev === 'true' ? true : undefined);
    bake('devMode', dev);
  };

  const handleItemClick = (e, loc) => {
    setKey(loc);
    if (e) {
      let element = document.getElementsByClassName('MuiPopover-paper')[0];
      element.classList.add('hide');
    }
  };

  const getRoles = () => {
    Api({
      sp: 'getRolesByUser',
      json: {},
    }).then((response) => {
      bake(true, 'roles', response);
      setRoles(response);
    });
  };

  const hasRole = (list) => {
    let match = false;
    list.forEach((l) => {
      roles?.forEach((r) => {
        if (r.Role === l) {
          match = true;
        }
      });
    });
    return match;
  };

  return (
    <div className="main-div">
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        sticky="top"
        className={
          window.location.href.indexOf('elmo.') !== -1 ||
          window.location.href.indexOf('elm.') !== -1
            ? 'intel-solidigm-gradient'
            : window.location.href.indexOf('elmo-qa') !== -1 ||
                window.location.href.indexOf('elm-qa') !== -1
              ? 'intel-solidigm-gradient-qa'
              : 'intel-solidigm-gradient-dev'
        }
      >
        <Container>
          <LinkContainer to="/home" onClick={() => setKey(LINKS.HOME)}>
            <Navbar.Brand>
              <img
                width="20px"
                height="auto"
                className="img-responsive logo"
                src={Logo}
                alt="logo"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {!isKiosk ? (
                <>
                  {/* Equipment Section*/}
                  <Nav.Item>
                    <Nav.Link
                      as={NavLink}
                      to="/equipment"
                      state={{
                        locationFilter: locationFilter,
                      }}
                      onClick={() => setKey(LINKS.EQUIPMENT)}
                    >
                      Equipment
                    </Nav.Link>
                  </Nav.Item>

                  {/* Location Section */}
                  <NavDropdown
                    title="Locations"
                    active={
                      key === LINKS.LOCATION || key === LINKS.CORPSERVICE
                        ? true
                        : false
                    }
                  >
                    {' '}
                    <NestedMenuItem
                      className={
                        key === LINKS.CORPSERVICE
                          ? 'activeRoute nested-menu-item'
                          : 'nested-menu-item'
                      }
                      label={'Corporate Services'}
                      parentMenuOpen={true}
                    >
                      <MenuItem
                        className="dropdown-item"
                        as={NavLink}
                        to="/geo"
                        onClick={(e) => handleItemClick(e, LINKS.CORPSERVICE)}
                      >
                        Geos
                      </MenuItem>
                      <MenuItem
                        className="dropdown-item"
                        as={NavLink}
                        to="/site"
                        onClick={(e) => handleItemClick(e, LINKS.CORPSERVICE)}
                      >
                        Sites
                      </MenuItem>
                      <MenuItem
                        className="dropdown-item"
                        as={NavLink}
                        to="/building"
                        onClick={(e) => handleItemClick(e, LINKS.CORPSERVICE)}
                      >
                        Buildings
                      </MenuItem>
                      <MenuItem
                        className="dropdown-item"
                        as={NavLink}
                        to="/floor"
                        onClick={(e) => handleItemClick(e, LINKS.CORPSERVICE)}
                      >
                        Floors
                      </MenuItem>
                    </NestedMenuItem>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/lab"
                      onClick={() => setKey(LINKS.LOCATION)}
                    >
                      Labs
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/location"
                      onClick={() => setKey(LINKS.LOCATION)}
                    >
                      Locations
                    </NavDropdown.Item>
                  </NavDropdown>

                  {/* Material Section */}
                  <NavDropdown
                    title="Material"
                    active={key === LINKS.MATERIAL ? true : false}
                  >
                    <NavDropdown.Item
                      as={NavLink}
                      to="/ssd"
                      onClick={() => setKey(LINKS.MATERIAL)}
                    >
                      SSDs
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/component"
                      onClick={() => setKey(LINKS.MATERIAL)}
                    >
                      Components
                    </NavDropdown.Item>

                    {/* <NavDropdown.Item
                      as={NavLink}
                      to="/epo"
                      onClick={() => setKey(LINKS.MATERIAL)}
                    >
                      EPO
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={NavLink}
                      to="/request"
                      onClick={() => setKey(LINKS.MATERIAL)}
                    >
                      EPO Requests
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={NavLink}
                      to="/priority"
                      onClick={() => setKey(LINKS.MATERIAL)}
                    >
                      EPO Priorities
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={NavLink}
                      to="/flow"
                      onClick={() => setKey(LINKS.MATERIAL)}
                    >
                      Flow
                    </NavDropdown.Item> */}
                  </NavDropdown>

                  {/* Operations Section */}
                  {/* <NavDropdown
                    title="Operations"
                    active={key === LINKS.OPERATIONS ? true : false}
                  >
                    <NavDropdown.Item
                      as={NavLink}
                      to="/events"
                      onClick={() => setKey(LINKS.OPERATIONS)}
                    >
                      Events
                    </NavDropdown.Item>
                  </NavDropdown> */}

                  {/* Reports Section */}
                  <NavDropdown
                    title="Reports"
                    active={key === LINKS.REPORTS ? true : false}
                  >
                    <NavDropdown.Item
                      as={NavLink}
                      to="/tabularReports"
                      onClick={() => setKey(LINKS.REPORTS)}
                    >
                      Tabular Reports
                    </NavDropdown.Item>
                  </NavDropdown>

                  {hasRole(['Dev', 'Admin']) ? (
                    <NavDropdown
                      title="Workflows"
                      active={key === LINKS.WORKFLOW ? true : false}
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="/statusWorkflow"
                        onClick={() => setKey(LINKS.WORKFLOW)}
                      >
                        Status
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : null}

                  {/* Help Section */}
                  <NavDropdown title="Help" active={false}>
                    <NavDropdown.Item
                      href="https://npsg-jira.elements.local/secure/CreateIssueDetails!init.jspa?pid=26170&issuetype=11800&summary=Please%20summarize%20your%20feedback%20request%20or%20bug%20report&description=Please%20describe%20your%20feedback%20or%20bug%20report%20in%20more%20detail&labels=elm&labels=feedback&priority=8"
                      target="_blank"
                      active={false}
                      onClick={() => setKey(LINKS.HOME)}
                    >
                      Submit feedback
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      href="https://nandps.sharepoint.com/teams/ELM"
                      target="_blank"
                      active={false}
                      onClick={() => setKey(LINKS.HOME)}
                    >
                      Training
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={NavLink}
                      to="/releaseNotes"
                      onClick={() => setKey(LINKS.RELEASENOTES)}
                    >
                      Release Notes
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/apiDocs"
                      onClick={() => setKey(LINKS.HOME)}
                    >
                      API Documentation
                    </NavDropdown.Item>
                  </NavDropdown>

                  {/* Admin Section */}
                  {hasRole([
                    'Dev',
                    'Admin',
                    'Equipment Manager',
                    'Material Manager',
                    'Planner',
                    'Material Storage',
                  ]) ? (
                    <NavDropdown
                      title="Admin"
                      active={key === LINKS.ADMIN ? true : false}
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="/fields"
                        onClick={() => setKey(LINKS.ADMIN)}
                        roles={roles}
                      >
                        Fields
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        to="/groups"
                        roles={roles}
                        onClick={() => setKey(LINKS.ADMIN)}
                      >
                        Groups
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        to="/templates"
                        roles={roles}
                        onClick={() => setKey(LINKS.ADMIN)}
                      >
                        Templates
                      </NavDropdown.Item>

                      {hasRole(['Admin']) ? (
                        <NavDropdown.Item
                          as={NavLink}
                          to="/default"
                          onClick={() => setKey(LINKS.ADMIN)}
                        >
                          Default Fields
                        </NavDropdown.Item>
                      ) : null}

                      <NavDropdown.Item
                        as={NavLink}
                        to="/hierarchy"
                        onClick={() => setKey(LINKS.ADMIN)}
                      >
                        Hierarchy
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        to="/admin"
                        onClick={() => setKey(LINKS.HOME)}
                      >
                        User Roles
                      </NavDropdown.Item>
                      {hasRole(['Dev']) ? (
                        <NavDropdown.Item
                          as={NavLink}
                          roles={roles}
                          to="/apiManagement"
                          onClick={() => setKey(LINKS.ADMIN)}
                        >
                          API Management
                        </NavDropdown.Item>
                      ) : null}
                    </NavDropdown>
                  ) : null}
                </>
              ) : (
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/kiosk"
                    onClick={() => setKey(LINKS.KIOSK)}
                  >
                    Kiosk
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>

            {/* User Preferences Section */}
            <Nav>
              {hasRole(['Dev']) ? (
                <Nav.Link as={NavLink} onClick={() => viewUpdates()}>
                  <Hint
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    title="Toggle Dev Mode"
                  >
                    <Magic
                      className={devMode ? 'dev-active' : ''}
                      size={devMode ? 20 : 16}
                      onClick={() => {
                        toggleDevMode();
                      }}
                    ></Magic>
                  </Hint>
                </Nav.Link>
              ) : null}
              {bake(true, 'userVersion') !== bake('version') && !alertClick ? (
                <Nav.Link
                  as={NavLink}
                  to="/releaseNotes"
                  onClick={() => viewUpdates()}
                >
                  <Hint
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    title="View Release Notes"
                  >
                    <BellFill></BellFill>
                  </Hint>
                </Nav.Link>
              ) : null}
              <Nav.Link onClick={() => updateLocationFilter()}>
                <Hint
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  title={
                    locationFilter
                      ? 'Disable Location Filter'
                      : 'Enabled Location Filter'
                  }
                >
                  {locationFilter ? <HouseFill></HouseFill> : <Globe></Globe>}
                </Hint>
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/userPreferences"
                onClick={() => setKey(LINKS.HOME)}
              >
                {user.name}{' '}
                {window.location.href.indexOf('elm.') !== -1 ? (
                  ''
                ) : window.location.href.indexOf('elm-qa') !== -1 ? (
                  <span>(QA)</span>
                ) : (
                  <span>(DEV)</span>
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Sitewide Banner */}
      <div className="sitewide-banner">
        To avoid issues with the latest release, please make sure to click the
        Clear Cache button within user preferences (this can be accessed by
        clicking on your name in the top right of ELM).
      </div>

      <Container>
        <ToastContainer autoClose={2000} />
        <Outlet
          context={{
            locationFilter: locationFilter,
            init: init,
            afterClearCache: afterClearCache,
          }}
        />
      </Container>
    </div>
  );
}
