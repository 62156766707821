import { matchIsValidTel } from 'mui-tel-input';
import { toast } from 'react-toastify';
// import { ERRORS, COLUMNS } from "./constants";
import { isDateValid } from './date';
import { uploadError, oven } from './common';
import { Api } from './interface';
const Validate = (val, item) => {
  // if (
  //   item.EntityMetaType === COLUMNS.TOTAL_QTY &&
  //   (IsNull(val) || val === "0")
  // ) {
  //   toast.error(ERRORS.TOTAL_QTY);
  //   return false;
  // }
  // if (item.EntityMetaType === COLUMNS.QTY && IsNull(val)) {
  //   toast.error(ERRORS.QTY);
  //   return false;
  // }

  // if (
  //   item.EntityMetaType === COLUMNS.TOTAL_QTY &&
  //   quantityError(val, currentEntity[COLUMNS.QTY])
  // ) {
  //   toast.error(ERRORS.QTY_INVALID);
  //   return false;
  // }

  // if (
  //   item.EntityMetaType === COLUMNS.QTY &&
  //   quantityError(currentEntity[COLUMNS.TOTAL_QTY], val)
  // ) {
  //   toast.error(ERRORS.QTY_INVALID);
  //   return false;
  // }

  return validateType(val, item);
};

function IsNull(val) {
  return val === null || val.trim() === '' ? true : false;
}

function isInteger(val) {
  return !isNaN(val) && Number.isInteger(parseFloat(val));
}

function isFloat(val) {
  var expression = /^[+-]?\d+(\.\d+)?$/;
  var regex = new RegExp(expression);
  if (typeof val === 'number') {
    val = '' + val;
  }
  var result = val.match(regex);
  return result;
}

const bake = (c, v) => {
  return oven({}, true, c, v);
};

function isEmailFormat(val) {
  // eslint-disable-next-line no-useless-escape
  var expression =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  var regex = new RegExp(expression);
  return val.match(regex);
}

function isURLFormat(val) {
  var expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  var regex = new RegExp(expression);
  return val.match(regex);
}
function isImageFormat(val) {
  var expression = /\.(gif|jpe?g|png|webp|bmp)$/i;
  var regex = new RegExp(expression);
  return val.match(regex);
}

function isValidBinary(val) {
  if (val === 0 || val === 1) {
    return true;
  } else {
    return false;
  }
}

// function will only not work for international phone numbers
function isPhoneNumberFormat(val) {
  return matchIsValidTel(val);
}

function displayErrorToast(item, errorContext) {
  toast.error(
    `Please ensure ${item.EntityMetaType} is a valid ${item.DataType} format. ${errorContext}`,
    {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
}

const IsRole = (list, userRoles) => {
  const roles = userRoles ?? bake('roles') ?? [];

  // dev mode access for everything
  if (roles.some((r) => r.Role === 'Dev') && bake('devMode') === 'true') {
    return true;
  }

  return roles.some((r) => list.indexOf(r.Role) >= 0);
};

const IsDevMode = () => {
  return bake('devMode') ?? 'false';
};

const IsDuplicate = (
  type,
  newItemName,
  localItemObjList,
  oldItemName = null,
  showMsg = true
) => {
  if (newItemName === '') {
    toast.error(`Unable to process an empty name.`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return true;
  }

  if (oldItemName) {
    if (newItemName === oldItemName) {
      return true;
    }
  }

  let found = [];
  newItemName = newItemName.toLowerCase();
  // maybe rework to pass in property to check instead of switching on type
  if (type == 'Entity') {
    found = localItemObjList.filter(
      (x) => x.EntityName.toLowerCase() === newItemName
    );
  } else if (type == 'Field') {
    found = localItemObjList.filter(
      (x) => x.EntityMetaType.toLowerCase() === newItemName
    );
  } else if (type == 'Group') {
    found = localItemObjList.filter(
      (x) => x.EntityMetaValue.toLowerCase() === newItemName
    );
  } else if (type == 'Template') {
    found = localItemObjList.filter(
      (x) => x.EntityType.toLowerCase() === newItemName
    );
  }

  if (oldItemName) {
    if (found.length >= 1 && newItemName === oldItemName.toLowerCase()) {
      return false;
    }
  }
  if (found.length > 0) {
    if (showMsg) {
      toast.error(
        `Unable to process '${newItemName}' as there is already an ${type} with that name`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
    return true;
  }
  return false;
};

const validateData = (col, value, i) => {
  let inValidMeta = [];
  if (col.DataType === 'Date' && !isDateValid(value)) {
    inValidMeta.push(
      uploadError(i, value, col.EntityMetaType, 'e.g. 12/30/1991 (mm/dd/yyyy)')
    );
  } else if (col.DataType === 'Binary' && !isValidBinary(value)) {
    inValidMeta.push(uploadError(i, value, col.EntityMetaType, 'e.g. 0 or 1'));
  } else if (col.DataType === 'Integer' && !isInteger(value)) {
    inValidMeta.push(uploadError(i, value, col.EntityMetaType, 'e.g. 1'));
  }
  if (col.DataType === 'Float' && !isFloat(value)) {
    inValidMeta.push(uploadError(i, value, col.EntityMetaType, 'e.g. 1.0'));
  } else if (col.DataType === 'Email' && !isEmailFormat(value)) {
    inValidMeta.push(
      uploadError(i, value, col.EntityMetaType, 'sample@intel.com')
    );
  } else if (col.DataType === 'URL' && !isURLFormat(value)) {
    inValidMeta.push(
      uploadError(i, value, col.EntityMetaType, 'https://sample.com')
    );
  } else if (col.DataType === 'Phone Number' && !isPhoneNumberFormat(value)) {
    inValidMeta.push(uploadError(i, value, col.EntityMetaType, '012-345-6789'));
  }

  return inValidMeta;
};

// const quantityError = (totalQty, availableQty) => {
//   if (parseInt(totalQty) < parseInt(availableQty)) {
//     return true;
//   } else {
//     return false;
//   }
// };

const validateType = (val, item) => {
  // allow empty values to pass without going through validation
  if (val === '') {
    return true;
  }
  let errorContext = '';
  if (item.DataType === 'Integer' && !isInteger(val)) {
    errorContext = 'e.g. 1';
    displayErrorToast(item, errorContext);
    return false;
  } else if (item.DataType === 'Float' && !isFloat(val)) {
    errorContext = 'e.g. 1.0';
    displayErrorToast(item, errorContext);
    return false;
  } else if (item.DataType === 'Email' && !isEmailFormat(val)) {
    errorContext = 'e.g. sample@intel.com';
    displayErrorToast(item, errorContext);
    return false;
  } else if (item.DataType === 'URL' && !isURLFormat(val)) {
    errorContext = 'e.g. https://sample.com';
    displayErrorToast(item, errorContext);
    return false;
  } else if (item.DataType === 'Image' && !isImageFormat(val)) {
    errorContext = 'Accepted Formats: gif, jpeg, png, webp & bmp';
    displayErrorToast(item, errorContext);
    return false;
  } else if (item.DataType === 'Phone Number' && !isPhoneNumberFormat(val)) {
    displayErrorToast(item, errorContext);
    return false;
  }

  return true;
};

const checkUnique = (item, val) => {
  Api({
    sp: 'checkUnique',
    json: {
      entityId: item.Entity_DBID,
      fieldId: item.EntityMetaType_DBID,
      val: val,
    },
  }).then((response) => {
    return response;
  });
};

export {
  isImageFormat,
  displayErrorToast,
  Validate,
  IsDuplicate,
  IsRole,
  IsNull,
  validateData,
  validateType,
  isInteger,
  checkUnique,
  IsDevMode,
};
