import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useState } from 'react';
import { getComparator } from './common';

// Generic Material Table implementation that takes a JSON response and renders as a table
// Sticky headers, sorting, and pagination are included in this table
const BasicMatTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  const rows = props.data;

  let columns = [];
  for (const key in props.data[0]) {
    columns.push({ id: key, label: key });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 500 }} className="tableContainer">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sortDirection={orderBy === column.id ? order : false}
                  className="table-header"
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => {
                      handleRequestSort(column.id);
                    }}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];

                      // Render UTC dates in a clean date time format
                      if (column.id.includes('Date')) {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {new Date(value).toLocaleString()}
                          </TableCell>
                        );
                      }

                      // Render links as a clickable icon
                      if (column.id.includes('Link')) {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <a
                              style={{ textDecoration: 'none' }}
                              key={column.id}
                              href={value}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              🔗
                            </a>
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default BasicMatTable;
