import { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { Api } from './interface';
// import { Hint } from "./constants";

const ImageView = (props) => {
  const [images, setImages] = useState([]);
  const [imageServer, setImageServer] = useState('');
  const [imgIndex, setImgIndex] = useState(0);
  // const [hover, setHover] = useState(false);

  useEffect(() => {
    Api({ sp: 'getImgServer', json: {} }).then((response) => {
      setImageServer(response[0].server);
    });
    Api({
      sp: 'getImages',
      json: { entityId: props.item.Entity_DBID },
    }).then((response) => {
      setImages(response);
    });
  }, [props.item]);

  return images.length > 0 ? (
    <div className="img-view">
      <div className="d-flex justify-content-between text-center">
        {images.length > 1 ? (
          <ChevronLeft
            color="blue"
            className="pointer mt-3"
            size={20}
            onClick={() =>
              setImgIndex(imgIndex <= 0 ? images.length - 1 : imgIndex - 1)
            }
          />
        ) : (
          <span></span>
        )}
        <div>
          <h4 className="mb-0 mt-2">{images[imgIndex]?.EntityMetaType}</h4>
        </div>
        {images.length > 1 ? (
          <ChevronRight
            color="blue"
            className="pointer mt-3"
            size={20}
            onClick={() =>
              setImgIndex(imgIndex >= images.length - 1 ? 0 : imgIndex + 1)
            }
          />
        ) : (
          <span></span>
        )}
      </div>
      <hr />

      <a className="pointer">
        <img
          src={
            imageServer + images[imgIndex]?.EntityMetaValue + '?' + props.sas
          }
          // onMouseEnter={setHover(true)}
          // onMouseLeave={setHover(false)}
          onClick={() => {
            window.open(
              imageServer + images[imgIndex]?.EntityMetaValue + '?' + props.sas,
              '_blank'
            );
          }}
        />
        {/* <Hint
          placement="left"
          delay={{ show: 250, hide: 400 }}
          title="Click to delete entry"
        >
          <Trash
            color="red"
            onClick={props.delete}
            size={18}
            className={hover ? "showImgCncl" : "hideImgCncl"}
          />
        </Hint> */}
      </a>
    </div>
  ) : (
    <div className="img-view">
      <div className="text-center">
        <div>
          <h4 className="mb-0 mt-2">No Images</h4>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default ImageView;
