import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import { TableSortLabel } from '@mui/material';
import { ROWSPERPAGE } from './constants';
import { getComparator, handleResize, oven } from './common';

import { ButtonGroup } from 'react-bootstrap';
import {
  Trash,
  EnvelopePlus,
  EnvelopeDash,
  Table as TableIcon,
} from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import { toast } from 'react-toastify';
import { Api, Query } from './interface';

import { BuildReportQuery } from './reportQuery.js';
import BasicModal from './basicModal';
import BasicMatTable from './basicMatTable';
import TabularReportForm from './tabularReportForm';

const TabularReports = () => {
  const bake = (c, v) => {
    return oven({ level: 'TabularReports' }, false, c, v);
  };

  const [rowsPerPage, setRowsPerPage] = bake('rowsPerPage')
    ? useState(Number(bake('rowsPerPage')))
    : useState(15);
  const [orderBy, setOrderBy] = useState(bake('orderBy') ?? 'EntityType');
  const [order, setOrder] = useState(bake('order') ?? 'asc');
  const [searchText, setSearchText] = useState(bake('searchText') ?? '');

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);

  const [currentTabularReport, setCurrentTabularReport] = useState('');

  const [reportFormOpen, setReportFormOpen] = useState(false);
  const [reportFormOptions, setReportFormOptions] = useState({});
  const [reportFormData, setReportFormData] = useState({});

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportSummary, setReportSummary] = useState('');
  const [loadingReport, setLoadingReport] = useState(false);
  const [reportData, setReportData] = useState(null);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const tableCellHover = [
    {
      '&:hover': {
        backgroundColor: '#BDBDBD',
      },
    },
  ];

  const columns = [
    { id: 'TabularReportNickname', label: 'Report Name', minWidth: 170 },
    { id: 'TabularReportName', label: 'Type', minWidth: 170 },
    { id: 'CreatedDtm', label: 'Created Date', minWidth: 100 },
    {
      id: 'SubscriptionFrequency',
      label: 'Subscription Cadence',
      minWidth: 100,
    },
    {
      id: 'ToggleSubscription',
      label: 'Toggle Subscription',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'RunReport',
      label: 'Run Report',
      minWidth: 100,
      align: 'center',
    },
    { id: 'Delete', label: 'Delete', minWidth: 100, align: 'center' },
  ];

  useEffect(() => {
    loadUserTabularReports();

    getFormOptions();
  }, []);

  const loadUserTabularReports = () => {
    Api({ sp: 'getUserReportsAndSubscriptions', json: {} }).then((response) => {
      // Transform subscription details
      response.forEach((x) => {
        if (x.TabularReportSubscription_DBID !== null) {
          x['SubscriptionFrequency'] = x.SubscriptionFrequency + ' Email';
        } else {
          x['SubscriptionFrequency'] = 'Inactive';
        }
      });

      setRows(response);

      // Filter response by searchText saved to cookies
      let filteredRows = filterRowsByText(response, searchText);
      setFilteredRows(filteredRows);

      handleResize();
    });
  };

  const filterList = (filter) => {
    // Save search text to cookies & state
    bake('searchText', filter);
    setSearchText(filter);

    // Filter response by search bar text
    let filteredRows = filterRowsByText(rows, filter);
    setFilteredRows(filteredRows);
    setPage(0);
  };

  const filterRowsByText = (rows, text) => {
    let filteredRows = rows.filter((r) => {
      let objVals = Object.values(r);

      return objVals.some((v) => {
        let strVal = String(v).toLowerCase();

        return strVal.includes(text.toLowerCase());
      });
    });

    return filteredRows;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // Save selected rows per page to cookies
    bake('rowsPerPage', parseInt(event.target.value, 10));

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    // Save selected sort column & order to cookies
    bake('orderBy', property);
    bake('order', isAsc ? 'desc' : 'asc');

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const showTabularReport = (reportSummary, reportJson = {}) => {
    setReportData(null);
    setReportSummary(reportSummary);
    setReportModalOpen(true);

    // Build dynamic SQL query
    const reportSql = BuildReportQuery(reportJson);

    // Call API to execute SQL
    setLoadingReport(true);

    Query({ query: reportSql }).then((response) => {
      setReportData(response);
      setLoadingReport(false);
    });
  };

  const deleteTabularReport = () => {
    Api({
      sp: 'deleteUserTabularReport',
      json: {
        tabularReportId: currentTabularReport.TabularReport_DBID,
        userTabularReportId: currentTabularReport.UserTabularReport_DBID,
      },
    }).then(() => {
      // Remove deleted report from UI
      setRows(
        rows.filter((r) => {
          return (
            r.UserTabularReport_DBID !==
            currentTabularReport.UserTabularReport_DBID
          );
        })
      );

      setFilteredRows(
        filteredRows.filter((r) => {
          return (
            r.UserTabularReport_DBID !==
            currentTabularReport.UserTabularReport_DBID
          );
        })
      );

      // Close modal and display success toast
      setDeleteModalOpen(false);
      toast.success('Report successfully deleted');
    });
  };

  const toggleSubscription = (row) => {
    // Derive subscription frequency based on report time horizon
    let subFrequency = JSON.parse(row.TabularReportParameters).timeHorizon;
    subFrequency =
      subFrequency === 'Day'
        ? 'Daily'
        : subFrequency === 'Week'
          ? 'Weekly'
          : 'Monthly';

    Api({
      sp: 'toggleTabularReportSubscription',
      json: {
        tabularReportId: row.TabularReport_DBID,
        subFrequency: subFrequency,
      },
    }).then(() => {
      toast.success('Subscription updated');
      loadUserTabularReports();
    });
  };

  const getFormOptions = () => {
    Api({ sp: 'getTabularReportTypes', json: {} }).then((response) => {
      // setReportTypeOptions(response);
      setReportFormOptions((prev) => ({ ...prev, reportTypes: response }));
    });

    Api({ sp: 'getNonMaterialPaths', json: {} }).then((response) => {
      // setReportLocOptions(response);
      setReportFormOptions((prev) => ({ ...prev, reportLocations: response }));
    });

    setReportFormOptions({
      ...reportFormOptions,
      reportFrequencies: ['Day', 'Week', 'Month'],
    });

    Api({ sp: 'getUsers', json: {} }).then((response) => {
      // setElmUserOptions(response);
      setReportFormOptions((prev) => ({ ...prev, elmUsers: response }));
    });

    Api({ sp: 'getEntityTypes', json: {} }).then((response) => {
      // setEntityTypeOptions(response);
      setReportFormOptions((prev) => ({ ...prev, entityTypes: response }));
    });

    // Api({ sp: "getTabularReportEntities", json: {} }).then((response) => {
    //   setEntityOptions(response);
    // setReportFormOptions(prev => ({ ...prev, entityNames: response }));
    // });

    Api({ sp: 'getTabularReportMetaFilters', json: {} }).then((response) => {
      // setMetaFilterOptions(response);
      setReportFormOptions((prev) => ({ ...prev, metaFilters: response }));
    });
  };

  return (
    <div className="main-container">
      <div className="mt-2 d-flex justify-content-between">
        {/* Page Title */}
        <h2>Tabular Reports</h2>

        {/* Add Report Button */}
        <ButtonGroup className="mb-2">
          <Button
            variant="primary"
            className="ms-auto text-nowrap"
            onClick={() => {
              setReportFormData({});
              setReportFormOpen(true);
            }}
          >
            Add Report
          </Button>
        </ButtonGroup>
      </div>

      <Stack className="pb-2" direction="horizontal" gap={3}>
        {/* Search Bar */}
        <Form.Control
          value={searchText}
          type="text"
          placeholder="Search"
          onChange={(e) => filterList(e.target.value)}
          autoFocus
        />
      </Stack>

      {/* Material Table */}
      <Paper sx={{ width: '100%' }}>
        <TableContainer
          component={Paper}
          className="tableContainer"
          id="tab-container"
        >
          <Table stickyHeader aria-label="sticky table">
            {/* Column Headers */}
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sortDirection={orderBy === column.id ? order : false}
                    className="table-header"
                  >
                    {/* Non-sortable & sortable column headers */}
                    {['ToggleSubscription', 'RunReport', 'Delete'].includes(
                      column.id
                    ) ? (
                      <span>{column.label}</span>
                    ) : (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={(e) => handleRequestSort(e, column.id)}
                      >
                        <span className="pr-2">{column.label}</span>
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table Content */}
            {filteredRows && (
              <TableBody>
                {filteredRows
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        className="pointer"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.UserTabularReport_DBID}
                        onClick={(e) => {
                          e.stopPropagation();
                          setReportFormData(row);
                          setReportFormOpen(true);
                        }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];

                          if (column.id === 'SubscriptionFrequency') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <span
                                  className={
                                    row.TabularReportSubscription_DBID !== null
                                      ? 'active-subscription'
                                      : 'inactive-subscription'
                                  }
                                >
                                  {row.SubscriptionFrequency}
                                </span>
                              </TableCell>
                            );
                          }

                          if (column.id === 'ToggleSubscription') {
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleSubscription(row);
                                }}
                                sx={tableCellHover}
                              >
                                {row.TabularReportSubscription_DBID !== null ? (
                                  <EnvelopeDash color="red" size={18} />
                                ) : (
                                  <EnvelopePlus color="green" size={18} />
                                )}
                              </TableCell>
                            );
                          }

                          if (column.id === 'RunReport') {
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  showTabularReport(row.TabularReportSummary, {
                                    reportType: row.TabularReportName,
                                    reportParameters: JSON.parse(
                                      row.TabularReportParameters
                                    ),
                                  });
                                }}
                                sx={tableCellHover}
                              >
                                <TableIcon color="blue" size={18} />
                              </TableCell>
                            );
                          }

                          if (column.id === 'Delete') {
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteModalOpen(true);
                                  setCurrentTabularReport(row);
                                }}
                                sx={tableCellHover}
                              >
                                <Trash color="red" size={18} />
                              </TableCell>
                            );
                          }

                          if (column.id.includes('Date')) {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {new Date(value).toLocaleString()}
                              </TableCell>
                            );
                          }

                          return (
                            // Otherwise return basic value formatting
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          labelRowsPerPage="Rows:"
          rowsPerPageOptions={ROWSPERPAGE}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Tabular Report Form */}
      {reportFormOpen === true && (
        <TabularReportForm
          show={reportFormOpen}
          handleClose={() => setReportFormOpen(false)}
          formOptions={reportFormOptions}
          formData={reportFormData}
          reportModalOpen={reportModalOpen}
          showTabularReport={showTabularReport}
          loadUserTabularReports={loadUserTabularReports}
        />
      )}

      {/* Tabular Report Modal */}
      {reportModalOpen && (
        <Modal
          dialogClassName="modal-80w"
          show={reportModalOpen}
          onHide={() => {
            setReportModalOpen(false);
          }}
        >
          <Modal.Header>
            <Modal.Title>Tabular Report</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* Report Summary */}
            <h5>Report Summary</h5>
            <pre className="pre-tag">{reportSummary}</pre>

            {/* Loading Spinner */}
            {loadingReport === true && (
              <div className="spinner-border spinner" role="status"></div>
            )}

            {/* Report Table / No Results Message */}
            {reportData && reportData.length > 0 && (
              <BasicMatTable data={reportData} />
            )}

            {reportData && !reportData.length > 0 && (
              <>
                <h5>The tabular report returned 0 results.</h5>
                <p>
                  Either no data exists for the provided report filters, or the
                  combination of filters is invalid.
                </p>
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => {
                setReportModalOpen(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Delete Confirmation Modal*/}
      {deleteModalOpen && (
        <BasicModal
          show={deleteModalOpen}
          handleClose={() => setDeleteModalOpen(false)}
          modalTitleText={
            'Confirm Deletion Of ' + currentTabularReport.TabularReportNickname
          }
          modalBodyText={
            'This will delete both the report and email subscription if active.'
          }
          closeButtonText={'Cancel'}
          submitButtonText={'Confirm'}
          handleSubmit={deleteTabularReport}
        />
      )}
    </div>
  );
};

export default TabularReports;
