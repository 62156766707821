import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Api } from './interface.js';
import {
  templateDownloadBulkEdit,
  processUploadBulkEdit,
  downloadErrReport,
  prepareExcelData,
} from './bulkUtil.js';
import { Button } from 'react-bootstrap';
import { Hint } from './constants.js';
import { toast } from 'react-toastify';
import readXlsxFile from 'read-excel-file';

export default function EntityBulkEdit(props) {
  const [fields, setFields] = useState([]); // all entitymetatypes
  const [allEntityTypes, setAllEnityTypes] = useState([]); // all entitytypes
  const [entityTypes, setEnityTypes] = useState([]); // entityTypes for dropdown
  const [selectedEntityTypes, setSelectedEnityTypes] = useState([]); // selected entity types
  const [filteredToUpdateFields, setFilteredToUpdateFields] = useState([]); // filtered fields to update to select from dropdown
  const [selectedToUpdateFields, setSelectedToUpdateFields] = useState([]); // selected to update fields
  const [identifierFields, setIdentifierFields] = useState([]); // identifier fields for dropdown
  const [selectedIdentifierFields, setSelectedIdentifierFields] = useState([]); // selected identifier fields

  // uploading file info
  const [file, setFile] = useState();

  // all locations and users
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);

  // in case of any error of invalid values
  const [errorFlag, setErrorFlag] = useState(false);
  const [invalidMeta, setInvalidMeta] = useState();

  // footer to download template
  const [downloadTemplate, setDownloadTemplate] = useState(props.createBulk);

  // spinner to show while processing
  const [spinner, setSpinner] = useState(props.spinner);

  const inputRef = useRef(null);

  useEffect(() => {
    setSpinner(props.spinner);
    // fetch all entity types based on level
    Api({
      sp: 'getEntityTypes',
      json: {},
    }).then((response) => {
      response = response.filter((x) => {
        return props.level.length
          ? props.type.length
            ? x.EntityType === props.type
            : x.Level === props.level
          : // level for equipment is empty so filter types other level
            ![
              'Material',
              'Location',
              'Lab',
              'Site',
              'Geo',
              'Building',
              'Floor',
            ].includes(x.Level);
      });
      setEnityTypes(response);
      setAllEnityTypes(response);
    });
    // fetch all locations
    Api({
      sp: 'getNonMaterialPaths',
      json: {},
    }).then((parents) => {
      setLocations(parents);
    });
    // fetch all users
    Api({
      sp: 'getUsers',
      json: {},
    }).then((response) => {
      setUsers(response);
    });

    // fetch all meta types valid for bulk edit
    Api({
      sp: 'getMetaTypesBulkUpdate',
      json: {},
    }).then((response) => {
      // skip name update for Material as it is auto generated by DB
      if (props.level === 'Material') {
        response = response.filter(
          (field) => field.EntityMetaType !== 'EntityName'
        );
      }
      setFields(response);
    });
  }, []);

  //apply filter based on selecting values for different dropdowns
  const filterList = (
    list,
    selectedList,
    filterType,
    additionalListToFilterFrom
  ) => {
    let response = [];
    let filteredEntityTypes = additionalListToFilterFrom ?? selectedEntityTypes;

    // filter entity type dropdown and remove selected ones from the dropdown to avoid selecting same value multiple times
    switch (filterType) {
      case 'EntityType':
        response = list.filter(
          (obj) =>
            !selectedList
              .map((e) => e.EntityType_DBID)
              .includes(obj.EntityType_DBID)
        );
        break;
      // filter dropdown values for identifier fields dropdown
      // field should be unique
      case 'IdentifierField':
        response = list
          .filter((f) => f.IsUnique)
          .filter((f) => f.DataType !== 'Image')
          // filter identifier list included in selected entity types' templates
          .filter((f) =>
            filteredEntityTypes
              .map((n) => n.EntityType)
              .some((item) => f.Template?.split(',').includes(item))
          )
          // remove the selected fields from the dropdown list
          .filter(
            (obj) =>
              !selectedList
                ?.map((e) => e.EntityMetaType_DBID)
                ?.includes(obj.EntityMetaType_DBID)
          )
          // if the identifier field (e.g., Serial Number for Equipment)
          // is unique by different field (e.g. Model)
          // display that in the dropdown label
          .map((field) => {
            return {
              ...field,
              label:
                field.UniqueEntityMetaType &&
                filteredEntityTypes.filter((x) => x.Level === 'Equipment')
                  .length
                  ? field.EntityMetaType +
                    ' (Unique By ' +
                    field.UniqueEntityMetaType +
                    ')'
                  : field.EntityMetaType,
            };
          });
        break;

      // filter to update list
      case 'ToUpdateField':
        response = list
          .filter((f) => f.DataType !== 'Image')
          // filter to update fields list included in selected entity types' templates
          .filter((f) =>
            selectedEntityTypes
              ?.map((n) => n.EntityType)
              ?.some((item) => f.Template?.split(',').includes(item))
          )

          .filter(
            (obj) =>
              // remove the selected fields from the dropdown list
              !selectedList
                ?.map((e) => e.EntityMetaType_DBID)
                ?.includes(obj.EntityMetaType_DBID) &&
              // remove any field that is selected as identifier
              !additionalListToFilterFrom
                ?.map((e) => e.EntityMetaType_DBID)
                ?.includes(obj.EntityMetaType_DBID)
          );
        break;
    }

    return response;
  };

  // update identifier list and dropdown
  const updateIdentifierFields = (newValue) => {
    //set the selected identifier list including if there is unique by combination (e.g., Serial Number and Model for Equipment)
    setIdentifierFields(filterList(fields, newValue, 'IdentifierField'));
    setSelectedIdentifierFields(newValue);

    // filter "to update" dropdown list to remove the selected identifiers
    setFilteredToUpdateFields(
      filterList(fields, newValue, 'ToUpdateField', selectedToUpdateFields)
    );

    // if any identifier field selected is part of selected "to update" list, remove
    setSelectedToUpdateFields(
      filterList(selectedToUpdateFields, newValue, 'ToUpdateField')
    );
  };

  // update "to update" list and dropdown
  const updateSelectedToUpdateFields = (newValue) => {
    // update dropdown for "to update"
    setFilteredToUpdateFields(
      filterList(fields, newValue, 'ToUpdateField', selectedIdentifierFields)
    );

    // if selected "to update" field is unique by different field,
    // add that field as well in the list to ensure that the combination is present in excel
    // and new value provided for the combination is valid
    let selectedList = fields
      .filter((field) => {
        return (
          newValue
            .map((value) => value.EntityMetaType_DBID)
            .includes(field.EntityMetaType_DBID) ||
          newValue
            .map((value) => value.UniqueEntityMetaType_DBID)
            .includes(field.EntityMetaType_DBID)
        );
      })
      // ensure that the "unique by" field is part of selected entities' template
      .filter((f) =>
        selectedEntityTypes
          ?.map((n) => n.EntityType)
          ?.some((item) => f.Template?.split(',').includes(item))
      );

    setSelectedToUpdateFields(selectedList);
  };

  //update entity type list and dropdown
  const updateSelectedEntityType = (newValue) => {
    // filter dropdown list for entity type
    setEnityTypes(filterList(allEntityTypes, newValue, 'EntityType'));
    setSelectedEnityTypes(newValue);

    // if selected entity type list is update, update the selected identifier fields for all entity types' template
    // the selected field must be a part of at least one selected entity type
    // and remove the identifier field selected if it's not a part of any selected entity type template
    setSelectedIdentifierFields(
      selectedIdentifierFields.filter((field) =>
        newValue
          ?.map((n) => n.EntityType)
          ?.some((item) => field.Template?.split(',').includes(item))
      )
    );
    // filter identifier fields dropdown based on selected entity type
    setIdentifierFields(
      filterList(fields, selectedIdentifierFields, 'IdentifierField', newValue)
    );
  };

  // disable/enable download error report
  const shouldDownload = () => {
    return (
      spinner ||
      !selectedEntityTypes ||
      selectedEntityTypes?.length < 1 ||
      !selectedIdentifierFields ||
      selectedIdentifierFields?.length < 1 ||
      !selectedToUpdateFields ||
      selectedToUpdateFields?.length < 1
    );
  };

  // disabe/enable save button when processing or if any dropdown is empty
  const saveButtonDisabled = () => {
    return (
      spinner ||
      file === '' ||
      !file ||
      !selectedEntityTypes ||
      selectedEntityTypes?.length < 1 ||
      !selectedIdentifierFields ||
      selectedIdentifierFields?.length < 1 ||
      !selectedToUpdateFields ||
      selectedToUpdateFields?.length < 1
    );
  };

  // download the template for selected values
  const templateDownload = () => {
    // mark the identifier fields as Identifier
    let identifyingFields = selectedIdentifierFields.map((field) => {
      return { ...field, Identifier: true };
    });

    // add any "unique by" field as part of identifier fields, if applicable
    fields
      .filter((field) =>
        identifyingFields
          .map((selectedField) => selectedField.UniqueEntityMetaType_DBID)
          .includes(field.EntityMetaType_DBID)
      )
      .forEach((uniqueBy) =>
        identifyingFields.push({ ...uniqueBy, Identifier: true })
      );

    // prepare excel and download
    templateDownloadBulkEdit(
      fields,
      identifyingFields,
      selectedEntityTypes,
      selectedIdentifierFields,
      selectedToUpdateFields,
      locations,
      users
    );
  };

  const bulkEditOnUpload = () => {
    // clear out any past error report
    setInvalidMeta([]);
    setSpinner(true);
    // mark the identifier fields as Identifier
    let identifyingFields = selectedIdentifierFields.map((field) => {
      return { ...field, Identifier: true };
    });

    fields
      .filter((field) =>
        identifyingFields
          .map((selectedField) => selectedField.UniqueEntityMetaType_DBID)
          .includes(field.EntityMetaType_DBID)
      )
      .forEach((uniqueBy) =>
        // add any "unique by" field as part of identifier fields, if applicable
        // and update the label
        identifyingFields.push({
          ...uniqueBy,
          Identifier: true,
          label: uniqueBy.EntityMetaType + ' (Identified By)',
        })
      );

    // fetch all options for Grouped fields such as Model, Build ID etc.
    // fetch only those applicable to selected entity types
    // this will be used to verify in case user has provided any invalid value by bypassing the template excel validation
    Api({
      sp: 'getMetaOptionsByTypeBulkUpdate',
      json: { types: selectedEntityTypes },
    }).then((metaOptions) => {
      // read excel and prepare data
      onUploadBulkEdit(
        identifyingFields,
        metaOptions,
        selectedEntityTypes,
        selectedToUpdateFields
      );
    });
  };

  const onUploadBulkEdit = (
    identifyingFields,
    metaOptions,
    selectedEntityTypes,
    selectedToUpdateFields
  ) => {
    readXlsxFile(file).then((rows) => {
      // uploaded file should have more than one row (header row by default)
      if (rows.length < 2) {
        toast.error('Uploaded file does not have data');
        setSpinner(false);
        return 'Error';
      } else {
        // get the columns from the excel, in case updated
        let colList = rows[0];
        // prepare excel data and verify any violations such as entities not found, duplicate values fro unique meta, etc.
        let excelData = prepareExcelData(rows, identifyingFields);

        if (
          !excelData ||
          !excelData?.excelRows ||
          !excelData.excelRows?.length
        ) {
          toast.error(
            'Unknown Error occurred while trying to read the excel. Please try again.'
          );
          return;
        }

        // fetch the entities based on selected identifier data
        Api({
          sp: 'getEntitiesByIdentifiersBulkUpdate',
          json: {
            identifyingList: excelData?.identifyingList,
            uniqueByIdentifyingList: excelData?.uniqueByIdentifyingList,
          },
        }).then((entitiesFound) => {
          if (!entitiesFound || entitiesFound?.length < 1) {
            let errMsg =
              'None of the rows match the existing identification data';
            setInvalidMeta([errMsg]);
          }

          // perform validations, if none found, prepare the excel data as objects
          let result = processUploadBulkEdit(
            selectedEntityTypes,
            metaOptions,
            excelData.excelRows,
            colList,
            selectedToUpdateFields,
            props.level,
            entitiesFound,
            locations,
            users,
            props.canEdit
          );

          // if any violation found, show he error report
          if (!result || !result.Status || result.Status === 'Error') {
            setDownloadTemplate(false);
            setErrorFlag(true);
            setInvalidMeta(result.data);
            setSpinner(false);
          } else {
            // if there is/are any unique field (or combination thereof) is selected as part to "to update"
            // additional verification required in database against exisitng records
            if (result.uniqueToUpdateMetaType) {
              // if no violation found proceed with verifying
              // that the provided new values to be updated (if unique) should not pre-exist in database
              Api({
                sp: 'getEntitiesByUniqueFieldsBulkUpdate',
                json: {
                  uniqueToUpdateMetaType: result.uniqueToUpdateMetaType,
                  excelRows: result.excelRows,
                },
              }).then((response) => {
                // if any result found other than the identified entities in excel
                // show error report
                if (response?.length) {
                  let errMsg =
                    'Row(s) :' +
                    response.map((entry) => entry.rowNum + 1) +
                    ' have values for (' +
                    result.uniqueToUpdateMetaType.uniqueToUpdateField +
                    (result.uniqueToUpdateMetaType.uniqueByField
                      ? ',' + result.uniqueToUpdateMetaType.uniqueByField
                      : '') +
                    ') that already exist and should be unique. ';
                  setInvalidMeta([errMsg]);
                  setErrorFlag(true);
                  setDownloadTemplate(false);
                  setSpinner(false);
                } else props.saveData(result); // proceed with updating the meta if no duplicate entries found
              });
            } else props.saveData(result); // proceed with updating the meta if there is/are no unique fields to be updated
          }
        });
      }
    });
  };

  // whenever the selceted file has been changed
  const handleFileChange = (event) => {
    // verify uploaded file is of acceptable format
    if (
      event.target.files[0].type !==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      toast.error('Only xlsx files are supported');
      inputRef.current.value = null;
      setFile();
      setInvalidMeta([]);
      return 'Error';
    } else {
      inputRef.current = event.target;
      setFile(event.target.files[0]);
    }
    setInvalidMeta([]);
    setSpinner(false);
  };

  // when error report is displayed restore the default modal
  const goBack = () => {
    inputRef.current.value = null;
    setFile();
    setInvalidMeta([]);
    setDownloadTemplate(true);
    setErrorFlag(false);
    setSpinner(false);
  };

  return (
    <Modal
      size="lg"
      show={props.createBulk}
      onHide={() => props.closeModal()}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Bulk Edit </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Dropdown for Entity Types*/}
        <Autocomplete
          autoHighlight={true}
          disablePortal
          multiple
          id="type-ahead"
          size="small"
          options={entityTypes}
          disableClearable
          value={selectedEntityTypes || []}
          sx={{ width: '100%' }}
          getOptionLabel={(option) => option.EntityType}
          onChange={(event, newValue) => {
            updateSelectedEntityType(newValue);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.EntityType_DBID}>
                {option.EntityType}
              </li>
            );
          }}
          disabled={spinner}
          renderInput={(params) => (
            <TextField {...params} label="Select Types" />
          )}
        />
        <br />
        {/* Dropdown for Identifying Fields*/}
        <Autocomplete
          autoHighlight={true}
          disablePortal
          multiple
          id="type-ahead"
          size="small"
          options={identifierFields}
          disableClearable
          value={selectedIdentifierFields || []}
          sx={{ width: '100%' }}
          getOptionLabel={(option) => {
            return option.EntityMetaType;
          }}
          onChange={(event, newValue) => {
            updateIdentifierFields(newValue);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.EntityMetaType_DBID}>
                {option.label}
              </li>
            );
          }}
          disabled={spinner}
          renderInput={(params) => (
            <TextField {...params} label="Identifier Field(s)" />
          )}
        />

        <br />
        {/* Dropdown for "To Update" Types*/}
        <Autocomplete
          autoHighlight={true}
          disablePortal
          multiple
          id="type-ahead"
          size="small"
          options={filteredToUpdateFields}
          disableClearable
          value={selectedToUpdateFields || []}
          sx={{ width: '100%' }}
          getOptionLabel={(option) => {
            return option.label;
          }}
          onChange={(event, newValue) => {
            updateSelectedToUpdateFields(newValue);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.EntityMetaType_DBID}>
                {option.label}
              </li>
            );
          }}
          disabled={spinner}
          renderInput={(params) => (
            <TextField {...params} label="To Update Field(s)" />
          )}
        />
        <br />
        {/* File Upload*/}
        <input
          type="file"
          onChange={(event) => handleFileChange(event)}
          disabled={spinner}
          ref={inputRef}
        />
        {/* display if there is any error report*/}
        <div className={errorFlag ? 'display' : 'hide'}>
          <table>
            {invalidMeta?.map((meta, i) => {
              return (
                <tr key={'inMeta' + i}>
                  <td>{meta}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* Enable template download. Disable when error report is shown or any empty dropdowns*/}
        <div className={downloadTemplate ? 'display' : 'hide'}>
          {spinner ? (
            <div className="spinner-border spinner" role="status"></div>
          ) : (
            ''
          )}

          <div className={'template-download'}>
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title={'Download Template'}
            >
              <span>
                <Button
                  className="icon-btn"
                  type="radio"
                  variant="outline-primary"
                  onClick={() => templateDownload()}
                  disabled={shouldDownload()}
                >
                  Download Template{' '}
                  {/*<Download className="export" size={15} />*/}
                </Button>
              </span>
            </Hint>
          </div>

          {/* Save button to perform validation and update the meta*/}
          <div className="upload-btns">
            <Button
              variant="primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                bulkEditOnUpload();
              }}
              disabled={saveButtonDisabled()}
            >
              Save
            </Button>
          </div>
        </div>
        {/* If error report is displayed, reset to default modal when clicked*/}
        <div className={errorFlag ? 'display' : 'hide'}>
          {errorFlag ? (
            <div className="error-btn-grp1">
              <Button variant="outline-primary" onClick={() => goBack()}>
                Go Back
              </Button>{' '}
            </div>
          ) : (
            ''
          )}
          {/* Download any error report*/}
          <div className="error-btn-grp2">
            {errorFlag ? (
              <Button
                variant="outline-primary"
                onClick={() => downloadErrReport(invalidMeta)}
              >
                Download
              </Button>
            ) : null}

            <Button
              className="ms-2"
              variant="outline-primary"
              onClick={() => props.closeModal()}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
