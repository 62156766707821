import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { Api } from './interface';

export default function ColumnManager(props) {
  const [fields, setFields] = useState([]);
  const [selected, setSelected] = useState([]);
  let inputRef;

  useEffect(() => {
    inputRef.focus();
    Api({
      sp: 'getMetaTypesByLevel',
      json: {
        level: props.level,
        type: props.type,
      },
    }).then((response) => {
      let sel = [];
      props.columns.forEach((c) => {
        response.forEach((r) => {
          if (r.EntityMetaType === c.id) {
            sel.push(r);
          }
        });
      });
      setSelected(sel);
      let f = props.type
        ? response.filter((x) => x.EntityMetaType !== 'Type')
        : response;

      setFields(f);
    });
  }, []);

  const updateFields = (event, val) => {
    if (event.key === 'Backspace') {
      return;
    }
    const map = new Map(val.map((pos) => [pos.EntityMetaType_DBID, pos]));
    const uniques = [...map.values()];
    setSelected(uniques);
  };

  const onDelete = (val) => () => {
    if (selected.length > 1) {
      setSelected((selected) =>
        selected.filter((v) => v.EntityMetaType !== val)
      );
    }
  };

  return (
    <Modal
      show={props.open}
      onHide={() => props.close(selected)}
      className="entity-modal"
      size="lg"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Select Columns</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          value={selected}
          options={fields}
          autoHighlight={true}
          getOptionLabel={(option) => option.EntityMetaType}
          onChange={(event, newValue) => updateFields(event, newValue)}
          renderTags={() => null}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Columns"
              inputRef={(input) => {
                inputRef = input;
              }}
            />
          )}
          renderOption={(props, option) => {
            const { EntityMetaType, Color } = option;
            return (
              <span {...props} style={{ color: Color }}>
                {EntityMetaType}
              </span>
            );
          }}
        />
        <Box
          mt={1}
          sx={{
            '& > :not(:last-child)': { mr: 1 },
            '& > *': { mr: 1, mt: 1 },
          }}
        >
          {selected.map((v) => (
            <Chip
              key={v.EntityMetaType_DBID}
              label={v.EntityMetaType}
              onDelete={onDelete(v.EntityMetaType)}
            />
          ))}
        </Box>
      </Modal.Body>
    </Modal>
  );
}
