import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form';
import { Api } from './interface';
import { toast } from 'react-toastify';

class EntityArchive extends Component {
  constructor(props) {
    super(props);
    this.comment = React.createRef();

    this.state = {
      reasons: [],
      reason: null,
      note: '',
      archiveComment: null,
      splitComponent: false,
      maxComponentQty: 0,
      selectedSplitComponentQuantity: 0,
    };
  }

  componentDidMount() {
    Api({
      sp: 'getArchivalReasonsByLevel',
      json: { level: this.props.level },
    }).then((reasons) => {
      this.setState({
        reasons: reasons,
      });
      // check if the archival process for splitting a single component
      if (
        this.props.level === 'Material' &&
        this.props.matType === 'Component' &&
        this.props.items.length === 1
      ) {
        this.setState({
          splitComponent: true,
          maxComponentQty: parseInt(this.props.items[0]['Quantity'][0] ?? '0'),
        });
      }
    });
  }

  updateComponentSplitQuantity = (selectedQty) => {
    selectedQty = isNaN(selectedQty) ? 0 : selectedQty;
    //check that the selected quantity is not higher than max quantity of the component
    if (selectedQty > this.state.maxComponentQty) {
      toast.error('Value cannot be greater than max quantity');
      return;
    }
    this.setState({
      selectedSplitComponentQuantity: selectedQty,
    });
  };
  archiveEntity = () => {
    const ids = this.props.items.map((x) => x.Entity_DBID);

    Api({
      sp: 'archiveEntity',
      json: {
        entityIds: ids,
        reasonId: this.state.reason?.Reason_DBID,
        comments: this.state.archiveComment,
        // values to be passed for inherent sproc call "archiveSplitComponent"
        matType: this.props.matType,
        selectedSplitComponentQuantity:
          this.state.selectedSplitComponentQuantity,
        maxComponentQty: this.state.maxComponentQty,
      },
    }).then((ids) => {
      toast.success(
        ids.length > 1
          ? `Successfully archived ${ids.length} items`
          : `${ids[0].EntityName} successfully archived`
      );
      this.props.close(true);
    });
  };

  updateReason = (reason) => {
    this.setState({
      reason: reason,
    });
  };

  archiveDisabled = () => {
    //disbale confirm button if the value of quantity is not valid (applicable only for components)
    let splitCheck =
      this.props.matType === 'Component'
        ? this.state.splitComponent &&
          this.state.selectedSplitComponentQuantity > 0 &&
          this.state.selectedSplitComponentQuantity <=
            this.state.maxComponentQty
        : true;
    if (!this.state.reason) {
      return true;
    } else {
      return !splitCheck;
    }
  };

  render = () => {
    return (
      <Modal
        show={this.props.open}
        onHide={this.props.close}
        className="entity-modal"
      >
        <Modal.Header>
          <Modal.Title>
            Confirm Archival of{' '}
            {this.props.items.length > 1
              ? 'multiple items'
              : this.props.items[0].EntityName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            {this.props.locationsUsed ? (
              <>
                Cannot Archive. Selected Location(s) have assets assigned to
                them! <br /> Please move those assets before archiving the
                selected locations.
              </>
            ) : (
              <>
                This will permanently archive the item(s) and any associated
                data!
              </>
            )}
          </h5>
          {!this.props.locationsUsed ? (
            <>
              <div>
                Archival Reason
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="entity-type"
                  value={this.state.reason}
                  options={this.state.reasons}
                  getOptionLabel={(option) => option.Reason_Label}
                  hiddenlabel="true"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Reason"
                    />
                  )}
                  onChange={(_event, reason) => {
                    this.updateReason(reason);
                  }}
                />
              </div>

              <div className="archive-note">
                <span>{this.state.reason?.Reason_Text}</span>
              </div>
              {/* form to enter quantity of the component to be split and archived */}
              {this.state.splitComponent ? (
                <>
                  <div>
                    Quantity
                    <Form.Control
                      // ref={this.selectedQuantity}
                      value={this.state.selectedSplitComponentQuantity ?? 0}
                      type="text"
                      placeholder="Enter Quantity"
                      onChange={(e) =>
                        this.updateComponentSplitQuantity(
                          parseInt(e.target.value.trim())
                        )
                      }
                    />
                  </div>
                  <p className="maxQty">
                    Max Quantity: {this.state.maxComponentQty}
                  </p>
                  <br />
                </>
              ) : null}
              <div>
                Comment
                <Form.Control
                  ref={this.comment}
                  type="text"
                  placeholder="Enter Comment"
                  onChange={(e) =>
                    this.setState({ archiveComment: e.target.value.trim() })
                  }
                />
              </div>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => this.props.close()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.currentTarget.disabled = true;
              this.archiveEntity();
            }}
            disabled={this.archiveDisabled()}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
}

export default EntityArchive;
