import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Api } from './interface';
import FieldList from './fieldList';
import { Validate } from './validator';

import { toast } from 'react-toastify';
// import { COLUMNS } from "./constants";
import { datePickerToShortDate } from './date';
import { Hint } from './constants.js';
class EntityEdit extends Component {
  constructor(props) {
    super(props);
    this.tableCellHover = [
      {
        '&:hover': {
          backgroundColor: '#BDBDBD',
        },
      },
    ];

    this.textInput = React.createRef();
    this.commentInput = React.createRef();
    this.historyRef = React.createRef();
    this.bottomRef = React.createRef();

    this.state = {
      entities: [],
      parentEntityList: [],
      filteredItems: [],
      entityTypes: [],
      template: [],
      filteredTemplate: [],
      statuses: [],
      metaOptions: [],
      fieldArray: [],
      isOpen: false,
      editing: false,
      cloning: false,
      renaming: false,
      deleting: false,
      statusEdit: -1,
      commenting: false,
      showHistory: true,
      comment: '',
      //sourceList: [],
      //epoListQty: [],
      //epoChildren: [],
      currentGroupChildren: [],
      currentHistory: [],
      filteredHistory: [],
      currentFilterText: '',
      filterTerm: '',
      filteredRows: [],
      items: props.item,
      imgServer: props.imgServer,
      templatesDict: {},
      refresh: false,
      currentEntityName: props.items[0].EntityName || '',
    };
  }

  componentDidMount() {
    Api({
      sp: 'getUsers',
      json: {},
    }).then((response) => {
      this.setState({ users: response });
    });
    this.getData();
  }

  updateTemplate(totalItems, allFields, groups) {
    let templateTemp = [];

    let commonValues = [];
    let commonValue = { EntityMetaValue: null };

    // get Lot ID and Quanity for Components
    let lotObj = allFields.find((f) => f.EntityMetaType === 'Lot ID');
    if (lotObj) {
      templateTemp.push(lotObj);
    }

    let qtyObj = allFields.find((f) => f.EntityMetaType === 'Quantity');
    if (qtyObj) {
      templateTemp.push(qtyObj);
    }

    let srObj = allFields.find((f) => f.EntityMetaType === 'Serial Number');
    if (srObj) {
      templateTemp.push(srObj);
    }
    groups.forEach((group) => {
      let fieldInTemplate = templateTemp.filter(
        (x) => x.EntityMetaType_DBID === group.EntityMetaType_DBID
      );

      if (!fieldInTemplate.length) {
        if (!group.AllowMultiple) {
          commonValues = allFields.filter(
            (x) => x.EntityMetaType_DBID === group.EntityMetaType_DBID
          );
          commonValue = commonValues[0];

          if (group.Count !== totalItems) {
            commonValue.EntityMetaValue = null;
          }
          templateTemp.push(commonValue);
        }

        if (group.AllowMultiple) {
          let filteredGroups = groups.filter(
            (x) =>
              x.EntityMetaType_DBID === group.EntityMetaType_DBID &&
              x.Count === totalItems
          );
          if (!filteredGroups.length) {
            commonValues = allFields.filter(
              (x) => x.EntityMetaType_DBID === group.EntityMetaType_DBID
            );
            let commonValue = commonValues[0];
            commonValue.EntityMetaValue = null;
            templateTemp.push(commonValue);
          } else {
            filteredGroups.forEach((x) => {
              commonValues = allFields.filter(
                (r) =>
                  r.EntityMetaType_DBID === x.EntityMetaType_DBID &&
                  r.EntityMetaValue === x.EntityMetaValue
              );
              let commonValue = commonValues[0];
              templateTemp.push(commonValue);
            });
          }
        }
      }
    });

    return templateTemp;
  }

  getData() {
    Api({
      sp: 'getTemplatesByEntities',
      json: {
        ids: this.props.items.map((a) => a.Entity_DBID),
        level: this.props.level,
      },
    }).then((response) => {
      let allTemplateFields = response;
      allTemplateFields.forEach((x) => {
        x.initialValue = x.EntityMetaValue;
      });
      this.setState({ allTemplateFields: response });
      let template = response;
      let totalItems = this.props.items.length;

      // if (
      //   this.props.level === "Material" &&
      //   this.props.items.find((item) => item.EntityType === "SSD")
      // ) {
      //   template = template.filter(
      //     (temp) =>
      //       temp.EntityMetaType !== "AvailableQuantity" &&
      //       temp.EntityMetaType !== "TotalQuantity"
      //   );
      // }

      // get all the grouped and nested data for the selected entities
      Api({
        sp: 'getNestedDataForEntities',
        json: {
          selectedList: this.props.items,
          totalEntities: this.props.items.length,
        },
      }).then((g) => {
        const groups = this.makeTree(
          g.filter(
            (x) =>
              !(
                x.Count === 1 &&
                !x.EntityMetaValue &&
                x.ParentEntityMetaType_DBID
              )
          ),
          (node) => node.ParentEntityMetaType_DBID,
          (node, next) => ({
            ...node,
            children: next(node.EntityMetaType_DBID),
          })
        );
        template = this.updateTemplate(totalItems, allTemplateFields, groups);

        template.forEach((t) => {
          t.children = t.children || [];
          groups.forEach((g) => {
            if (
              t.EntityMetaType_DBID === g.EntityMetaType_DBID &&
              t.EntityMetaValue === g.EntityMetaValue
            ) {
              t.children = g.children;
              if (g.Count === this.props.items.length) {
                t.EntityMetaValue = g.EntityMetaValue;
              }
            }
          });
        });

        Api({
          sp: 'getMetaOptionsByEntities',
          json: {
            ids: this.props.items.map((a) => a.Entity_DBID),
          },
        }).then((response) => {
          let optionsDict = {};

          response.forEach((option) => {
            if (!(option.EntityMetaOption in optionsDict)) {
              optionsDict[option.EntityMetaOption] = 1;
            } else {
              optionsDict[option.EntityMetaOption] += 1;
            }
            option['count'] = optionsDict[option.EntityMetaOption];
          });

          template.forEach((x) => {
            if (!x.options) {
              x.options = [];
            }
            if (x.AllowMultiple) {
              x['values'] = [];
            }

            response.forEach((y) => {
              if (
                x.EntityMetaType_DBID === y.EntityMetaType_DBID &&
                // Avoid adding duplicate options
                !x.options.some(
                  (o) => o.EntityMetaOption === y.EntityMetaOption
                )
              ) {
                x.options.push(y);
              }
            });
          });

          // if (this.props.level === "Material") {
          //   this.getMaterialEPOQty(this.props.items[0]);
          // }

          //if (this.props.level === "EPO") {
          //  this.getEPOSources(this.props.items[0]);
          //}
          this.setState({
            template: template,
            filteredTemplate: template.filter(
              (f) =>
                f.EntityMetaType.toLowerCase().includes(
                  this.state.currentFilterText.toLocaleLowerCase()
                ) ||
                (f.EntityMetaValue &&
                  f.EntityMetaValue.toLowerCase().includes(
                    this.state.currentFilterText.toLocaleLowerCase()
                  )) ||
                this.state.currentFilterText === ''
            ),
            editing: true,
            metaOptions: response,
            groups: groups,
          });
        });
      });
    });
  }

  makeTree(input, indexer, maker, root = 0) {
    const index = this.makeIndex(input, indexer);
    const many = (all = []) => all.map(one); // 1
    const one = (single = {}) => maker(single, (r) => many(index.get(r))); // 2
    return many(index.get(root));
  }

  makeIndex = (items, indexer) => {
    const insert = (r, k, v) => r.set(k, (r.get(k) ?? []).concat([v]));
    return items.reduce((r, i) => insert(r, indexer(i), i), new Map());
  };

  updateMeta = (val, item) => {
    // if (
    //   this.props.level === "Material" &&
    //   (item.EntityMetaType === COLUMNS.QTY ||
    //     item.EntityMetaType === COLUMNS.TOTAL_QTY)
    // ) {
    //   let qtyCheckFlag =
    //     item.EntityMetaType === COLUMNS.QTY
    //       ? parseInt(val) <= parseInt(this.props.item[0][COLUMNS.TOTAL_QTY])
    //       : parseInt(val) >= parseInt(this.props.item[0][COLUMNS.QTY]);
    //   if (!qtyCheckFlag) {
    //     toast.error(
    //       "Available Quantity cannot be gretater than Total Quantity."
    //     );
    //     return;
    //   }
    // }
    val =
      item.DataType === 'Date' || item.DataType === 'Binary' ? val : val.trim();
    if (item.EntityMetaValue === val || (val === '' && !item.EntityMetaValue)) {
      return;
    }
    if (
      item.DataType === 'Date' &&
      datePickerToShortDate(item.EntityMetaValue) === datePickerToShortDate(val)
    ) {
      return; // new and old date values are the same for the meta type
    }

    if (Validate(val, item, this.props.item)) {
      // set val to empty for deletion if binary and 0/false
      val = item.DataType === 'Binary' ? (val === 0 ? '' : val) : val;
      if (item.DataType === 'Image' && val !== '') {
        item.EntityMeta_DBID = -1;
      }
      let entities = [];
      let entitiesToUpdate = [];
      let singleTemplateField = this.state.allTemplateFields.filter(
        (x) => x.EntityMeta_DBID === item.EntityMeta_DBID
      )[0];
      if (item.AllowMultiple) {
        // add new metaId for all the selected entitites
        if (item.EntityMeta_DBID === -1) {
          this.props.items.forEach((entity) => {
            entities.push({
              entityId: entity.Entity_DBID,
              metaId: -1,
            });
          });
        } else {
          // fetch all the pre-existing required metaids of all entities
          entitiesToUpdate = this.state.allTemplateFields.filter(
            (t) =>
              [singleTemplateField.initialValue, item.EntityMetaValue].includes(
                t.EntityMetaValue
              ) && t.EntityMetaType_DBID === item.EntityMetaType_DBID
          );
        }
      } else {
        entitiesToUpdate = this.state.allTemplateFields.filter(
          (x) => x.EntityMetaType === item.EntityMetaType
        );
      }

      entitiesToUpdate.forEach((x) => {
        entities.push({
          entityId: x.Entity_DBID,
          metaId: x.EntityMeta_DBID ?? -1,
        });
      });

      let json = {
        entities: entities,
        typeId: item.EntityMetaType_DBID,
        val: val,
        level: this.props.level,
      };
      if (item.IsUnique || item.UniqueBy) {
        // check for unique values by field
        Api({
          sp: 'checkUnique',
          json: {
            entityId: item.Entity_DBID,
            fieldId: item.EntityMetaType_DBID,
            val: val,
            typeId: item.EntityType_DBID,
            isUnique: item.IsUnique,
          },
        }).then((r) => {
          if (r.length) {
            const unique = r[0].UniqueName
              ? ' for ' + r[0].UniqueName + ': ' + r[0].UniqueVal
              : '';
            toast.error(
              r[0].EntityName +
                ' already has ' +
                r[0].EntityMetaType +
                ': ' +
                r[0].EntityMetaValue +
                unique
            );
            this.getData();
          } else {
            this.executeUpdate(json, item, val, entitiesToUpdate);
          }
        });
      } else {
        this.executeUpdate(json, item, val, entitiesToUpdate);
      }
    }
  };

  executeUpdate = (json, item, val) => {
    this.setState({ refresh: true });
    Api({
      sp: 'updateMetaByEntities',
      json: json,
    }).then(() => {
      if (item.DataType !== 'Image') {
        item.EntityMetaValue = val;
      }

      this.getData();
      // this.props.updateTable(entitiesToUpdate, val);
      // // if (item.DataType === "Image" && val === "") {
      // //   Delete(this.state.imgServer, item.EntityMetaValue).then(() => {});
      // // }
      if (item.DataType === 'Date' && val !== null && val !== '') {
        val = datePickerToShortDate(val);
      }

      toast.success(
        item.EntityMetaType +
          (item.AllowMultiple && !val.length
            ? ' removed'
            : item.DataType === 'Binary'
              ? ' updated to ' + (val ? 'True' : 'False')
              : ' updated to ' + (val.length ? val : '(Blank)'))
      );
    });
  };

  remove = (item) => {
    let template = this.state.template.filter(
      (x) => x.EntityMeta_DBID !== item.EntityMeta_DBID
    );
    let filterTermLower = this.state.currentFilterText.toLowerCase();
    let newItems = template.filter(
      (f) =>
        f.EntityMetaType.toLowerCase().includes(filterTermLower) ||
        (f.EntityMetaValue &&
          f.EntityMetaValue.toLowerCase().includes(filterTermLower)) ||
        this.state.currentFilterText === ''
    );
    this.setState({ template: template, filteredTemplate: newItems });
    this.updateMeta('', item);
  };

  addEntry = (item) => {
    let newItem = {};
    newItem = Object.assign(newItem, item);
    newItem.EntityMeta_DBID = -1;
    newItem.EntityMetaValue = '';
    newItem.children = [];
    delete newItem.values;
    let template = this.state.template;
    let filterTermLower = this.state.currentFilterText.toLowerCase();
    template.push(newItem);
    let newItems = template.filter(
      (f) =>
        f.EntityMetaType.toLowerCase().includes(filterTermLower) ||
        (f.EntityMetaValue &&
          f.EntityMetaValue.toLowerCase().includes(filterTermLower)) ||
        this.state.currentFilterText === ''
    );
    newItems.sort((a, b) => (a.Sort > b.Sort ? 1 : a.Sort < b.Sort ? -1 : 0));
    this.setState({ template: template, filteredTemplate: newItems });
    // if item is at the bottom of the field list and a row is added, scroll to the bottom
    if (
      item.Sort === Math.max(...this.state.filteredTemplate.map((o) => o.Sort))
    ) {
      setTimeout(() => {
        this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  };

  filterTemplate = (filterTerm) => {
    let template = this.state.template;
    let filterTermLower = filterTerm.toLowerCase();
    let newItems = template.filter(
      (f) =>
        f.EntityMetaType.toLowerCase().includes(filterTermLower) ||
        (f.EntityMetaValue &&
          f.EntityMetaValue.toLowerCase().includes(filterTermLower)) ||
        filterTerm === ''
    );
    this.setState({
      filteredTemplate: newItems,
      currentFilterText: filterTerm,
    });
  };

  renameEntity = (val) => {
    val = val.trim();
    let prevName = this.state.currentEntityName;
    this.setState({ refresh: true });

    if (val.length) {
      Api({
        sp: 'renameEntity',
        json: { id: this.props.items[0].Entity_DBID, val: val },
      }).then((response) => {
        if (response[0].error) {
          toast.error(response[0].error);
        } else {
          toast.success(prevName + ' renamed to ' + val);
          this.setState({ renaming: false, currentEntityName: val });
        }
      });
    }
  };

  render = () => {
    return (
      <Modal
        size="lg"
        show={this.props.open}
        onHide={() => this.props.close(this.state.refresh)}
        className="entity-modal"
      >
        <Modal.Header closeButton="true">
          {this.props.items?.length > 1 ? (
            <Modal.Title>Update Configurations</Modal.Title>
          ) : (
            <Modal.Title>
              <div className="renameLabel">
                {this.props.canEdit && this.props.level !== 'Material'
                  ? 'Update'
                  : null}
                &nbsp;
              </div>
              {this.state.renaming ? (
                <div className="renameInput">
                  <Form.Control
                    type="text"
                    className="inputBoxText"
                    autoFocus
                    placeholder="Entity Name"
                    defaultValue={this.state.currentEntityName}
                    onBlur={(e) => this.renameEntity(e.target.value)}
                  />
                </div>
              ) : this.props.canEdit &&
                !['Material'].includes(this.props.level) ? (
                <Hint
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  title={
                    'Click to rename ' +
                    (this.props.level.length ? this.props.level : 'Equipment')
                  }
                >
                  <span
                    className="pointer child-link"
                    onClick={() => this.setState({ renaming: true })}
                  >
                    {this.state.currentEntityName}
                  </span>
                </Hint>
              ) : (
                <span>{this.state.currentEntityName}</span>
              )}{' '}
              Configuration
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Filter"
            value={this.state.currentFilterText}
            onChange={(e) => this.filterTemplate(e.target.value)}
            autoFocus
          />
          <br />
          <div className="fieldList-container">
            <FieldList
              items={this.state.filteredTemplate}
              addEntry={this.addEntry}
              update={this.updateMeta}
              remove={this.remove}
              users={this.state.users}
              disabled={!this.props.canEdit}
              imgServer={this.props.imgServer}
              updateTable={this.props.updateTable}
            />
            <div ref={this.bottomRef}></div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
}
export default EntityEdit;
