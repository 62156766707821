import React, { Component } from 'react';
import Brand from '../elm-text3.png';
import { Api } from './interface';

class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = { list: [], headers: [] };
  }

  componentDidMount() {
    Api({
      sp: 'getLists',
      json: {},
    }).then((lists) => {
      this.setState({ list: lists.filter((x) => x.ListType === 'welcome') });
      this.setState({
        headers: [
          ...new Set(
            lists
              .filter((x) => x.ListType === 'welcome' && x.ListHeader)
              .map((x) => x.ListHeader)
          ),
        ],
      });
    });
  }

  render() {
    return (
      <div>
        <h2>Home</h2>
        <div className="text-center d-flex justify-content-center welcome-container">
          <div className="welcome-text">Welcome to </div>
          <img
            width="150px"
            height="50px"
            className="img-responsive logo-text"
            src={Brand}
            alt="logo"
          />
        </div>
        <div className="text-muted text-center welcome-sub-text">
          {this.state.list
            .filter((x) => !x.ListHeader)
            .map((x, i) => (
              <div key={i}>{x.ListItem}</div>
            ))}
        </div>
        <div className="d-flex justify-content-center">
          <dl className="welcome-list">
            {this.state.headers.map((header, i) => (
              <figure key={i}>
                <dt style={{ '--animation-order': i }}>{header}</dt>
                {this.state.list
                  .filter((x) => x.ListHeader === header)
                  .map((item, j) => (
                    <dd style={{ '--animation-order': i }} key={j}>
                      {item.ListItem}
                    </dd>
                  ))}
              </figure>
            ))}
            {/* {this.state.list
              .filter((x) => x.ListHeader)
              .map((x, i) => (
                <dd key={i}>{x.ListItem}</dd>
              ))} */}
          </dl>
        </div>
      </div>
    );
  }

  const;
}
export default Splash;
