import React, { Component } from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import { shortDate, isDate } from './date';
export default class HistoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChildren: [],
    };
  }

  renderItem = (item, i) => {
    let dtm = new Date(item.CreatedDtm).toLocaleString();
    const { AllowMultiple, CreatedBy, EntityMetaType } = item;
    let { EntityMetaValue, PrevEntityMetaValue } = item;

    EntityMetaValue = isDate(EntityMetaValue)
      ? shortDate(EntityMetaValue)
      : EntityMetaValue;
    PrevEntityMetaValue = isDate(PrevEntityMetaValue)
      ? shortDate(PrevEntityMetaValue)
      : PrevEntityMetaValue;

    return (
      <div key={i}>
        <div className="history-row">
          <div className="d-flex justify-content-between">
            <span>
              {EntityMetaType !== 'EntityComment' ? (
                AllowMultiple ? (
                  <>
                    <span className="history-field">{`${EntityMetaType}:`}</span>{' '}
                    <span className="history-value">
                      {/* {PrevEntityMetaValue ? PrevEntityMetaValue : "(blank)"}
                      <ArrowRight color="blue" /> */}
                      {EntityMetaValue === '(blank)' ? (
                        'Option removed'
                      ) : (
                        <span>
                          <span>{'Option added'}</span>
                          <ArrowRight color="blue" />
                          {EntityMetaValue}
                        </span>
                      )}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="history-field">{`${EntityMetaType}:`}</span>{' '}
                    <span className="history-value">
                      {PrevEntityMetaValue ? PrevEntityMetaValue : '(blank)'}
                      <ArrowRight color="blue" />
                      {EntityMetaValue === '' ? '(blank)' : EntityMetaValue}
                    </span>
                  </>
                )
              ) : (
                <span className="history-comment">{EntityMetaValue}</span>
              )}
            </span>
            <span className="history-timestamp">{dtm}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span></span>
            <span className="history-user">{CreatedBy}</span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.props.items.map((item, i) => this.renderItem(item, i));
  }
}
