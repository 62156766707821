import { useEffect, useState, createRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Hint } from './constants.js';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { Api } from './interface';
import { BuildReportQuery } from './reportQuery.js';
import DebouncedMetaValuesAutocomplete from './debouncedMetaValuesAutocomplete';

const TabularReportForm = (props) => {
  const [reportId, setUserTabularReportId] = useState(-1);
  const [userSubscriptionId, setUserSubscriptionId] = useState(-1);

  // Selected variables refer to the currently selected option object in the form
  const [selectedNickname, setSelectedNickname] = useState('');
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [selectedTimeHorizon, setSelectedTimeHorizon] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedActionBy, setSelectedActionBy] = useState([]);
  const [selectedEntityTypes, setSelectedEntityTypes] = useState([]);
  const [selectedReportEntities, setSelectedReportEntities] = useState([]);
  const [selectedMetaFilters, setSelectedMetaFilters] = useState([
    { field: {}, fieldValues: [] },
  ]);

  let textInput = createRef(); // Used to focus first modal field

  const [openModalSections, setOpenModalSections] = useState({
    basicFilters: true,
    advancedFilters: true,
    reportSummary: true,
  });

  useEffect(() => {
    // If form data is passed, the user is editing an existing tabular report
    // Populate the form with the report data they clicked on
    if (Object.keys(props.formData).length > 0) {
      setForm(props.formData);
    }
  }, []);

  const isSaveReportButtonDisabled = () => {
    return (
      selectedNickname === '' ||
      selectedTimeHorizon === null ||
      selectedReportType === null
    );
  };

  const toggleModalSection = (section) => {
    let value = !openModalSections[section];

    setOpenModalSections({
      ...openModalSections,
      [section]: value,
    });
  };

  const addAdvancedFilter = () => {
    setSelectedMetaFilters([
      ...selectedMetaFilters,
      { field: {}, fieldValues: [] },
    ]);
  };

  const removeAdvancedFilter = (i) => {
    if (i === 0) {
      setSelectedMetaFilters([{ field: {}, fieldValues: [] }]);
    } else {
      let newMetaFilters = [...selectedMetaFilters];
      newMetaFilters.splice(i, 1);
      setSelectedMetaFilters(newMetaFilters);
    }
  };

  const generateReportSummary = () => {
    let reportSummary = '';
    if (selectedReportType != null && selectedTimeHorizon != null) {
      reportSummary += `All ${selectedReportType.TabularReportName.toLowerCase()} in the last ${selectedTimeHorizon.toLowerCase()}`;
    }

    if (selectedLocations.length > 0) {
      reportSummary +=
        '\n • In: ' + selectedLocations.map((x) => x.EntityName).join(', ');
    }

    if (selectedActionBy.length > 0) {
      reportSummary +=
        '\n • By: ' + selectedActionBy.map((x) => x.UserName).join(', ');
    }

    if (selectedEntityTypes.length > 0) {
      reportSummary +=
        '\n • Of Type: ' +
        selectedEntityTypes.map((x) => x.EntityType).join(', ');
    }

    if (selectedReportEntities.length > 0) {
      reportSummary +=
        '\n • Named: ' +
        selectedReportEntities.map((x) => x.EntityName).join(', ');
    }

    if (selectedMetaFilters.length > 0) {
      selectedMetaFilters.forEach((f) => {
        if (f.fieldValues.length > 0) {
          reportSummary += `\n • With ${
            f.field.EntityMetaType
          } = ${f.fieldValues.join(', ')}`;
        }
      });
    }

    return reportSummary;
  };

  const buildReportJson = () => {
    // Build metaFilters array and remove non-valid metaFilters
    let metaFilters = [];
    selectedMetaFilters.forEach((f) => {
      if (
        f.field.EntityMetaType &&
        f.field.EntityMetaType_DBID &&
        f.fieldValues.length > 0
      ) {
        metaFilters.push({
          metaType: f.field.EntityMetaType,
          metaTypeId: f.field.EntityMetaType_DBID,
          metaValues: f.fieldValues,
        });
      }
    });

    // Sort all values in the reportParameters object to maintain report definition consistency
    let reportParameters = {
      timeHorizon: selectedTimeHorizon,
      locationIds: selectedLocations
        .map((x) => x.Entity_DBID)
        .sort((a, b) => a - b),
      actionByIds: selectedActionBy
        .map((x) => x.User_DBID)
        .sort((a, b) => a - b),
      entityTypeIds: selectedEntityTypes
        .map((x) => x.EntityType_DBID)
        .sort((a, b) => a - b),
      entityIds: selectedReportEntities
        .map((x) => x.Entity_DBID)
        .sort((a, b) => a - b),
      metaFilters: metaFilters.sort((a, b) => a.metaTypeId - b.metaTypeId),
    };

    // Build json for report definition
    let reportJson = {
      reportNickname: selectedNickname,
      reportType: selectedReportType.TabularReportName,
      reportTypeId: selectedReportType.TabularReportType_DBID,
      reportParameters: reportParameters,
      reportSummary: generateReportSummary(),
    };

    // Build dynamic SQL query & add to reportJson
    const reportSql = BuildReportQuery(reportJson);
    reportJson['reportSql'] = reportSql;

    return reportJson;
  };

  const addTabularReport = (userTabularReportId, userSubscriptionId) => {
    // Build report JSON object
    let reportJson = buildReportJson();

    // If updating a user report, pass UserTabularReport_DBID
    if (userTabularReportId !== -1) {
      reportJson['userTabularReportId'] = userTabularReportId;

      // If there is a subscription tied to that report, pass TabularReportSubscription_DBID
      reportJson['subscriptionId'] = userSubscriptionId;
    }

    // Add tabular report to the database
    Api({
      sp: 'addUserTabularReport',
      json: reportJson,
    }).then(() => {
      props.loadUserTabularReports();

      // Close modal and display success toast
      props.handleClose();
      toast.success(
        'Report successfully ' +
          (userTabularReportId === -1 ? 'added' : 'updated')
      );
    });
  };

  const setForm = (formData) => {
    // Set all form variables based on the row clicked
    setUserTabularReportId(formData.UserTabularReport_DBID);
    setUserSubscriptionId(formData.TabularReportSubscription_DBID);
    setSelectedNickname(formData.TabularReportNickname);

    const options = props.formOptions;

    setSelectedReportType(
      options.reportTypes.filter((o) => {
        return o.TabularReportType_DBID === formData.TabularReportType_DBID;
      })[0]
    );

    let reportParams = JSON.parse(formData.TabularReportParameters);

    setSelectedTimeHorizon(reportParams.timeHorizon);

    setSelectedLocations(
      options.reportLocations.filter((o) => {
        return reportParams.locationIds.includes(o.Entity_DBID);
      })
    );

    setSelectedActionBy(
      options.elmUsers.filter((o) => {
        return reportParams.actionByIds.includes(o.User_DBID);
      })
    );

    setSelectedEntityTypes(
      options.entityTypes.filter((o) => {
        return reportParams.entityTypeIds.includes(o.EntityType_DBID);
      })
    );

    // setSelectedReportEntities(
    //   options.entityNames.filter((o) => {
    //     return reportParams.entityIds.includes(o.Entity_DBID);
    //   })
    // );

    // Transform report definition to selected meta filters
    let metaFilters = [];

    if (reportParams.metaFilters.length > 0) {
      reportParams.metaFilters.forEach((f) => {
        let fieldOption = options.metaFilters.filter((o) => {
          return o.EntityMetaType_DBID === f.metaTypeId;
        });

        metaFilters.push({ field: fieldOption[0], fieldValues: f.metaValues });
      });
    } else {
      metaFilters.push({ field: {}, fieldValues: [] });
    }

    setSelectedMetaFilters(metaFilters);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className={props.reportModalOpen === true ? 'hidden' : ''}
      dialogClassName="modal-50w"
      onEntered={() => textInput.current.focus()}
    >
      <Modal.Header>
        <Modal.Title>{reportId === -1 ? 'Add' : 'Update'} Report</Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-70h">
        <h5>Pick Report Type & Time Horizon (Required)</h5>
        <div className="mb-2">
          Nickname:
          <Form.Control
            ref={textInput}
            type="text"
            placeholder="Report Nickname"
            value={selectedNickname}
            onChange={(e) => setSelectedNickname(e.target.value)}
            onBlur={(e) => setSelectedNickname(e.target.value.trim())}
          />
        </div>

        <div className="row mb-3">
          <div className="col">
            Type:
            <Autocomplete
              autoHighlight={true}
              className="type-ahead"
              id="report-type"
              options={props.formOptions.reportTypes}
              getOptionLabel={(option) => option.TabularReportName}
              value={selectedReportType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Report Type"
                />
              )}
              onChange={(e, newValue) => {
                setSelectedReportType(newValue);
              }}
            />
          </div>

          <div className="col">
            Time Horizon:
            <Autocomplete
              autoHighlight={true}
              className="type-ahead"
              id="report-frequency"
              options={props.formOptions.reportFrequencies}
              value={selectedTimeHorizon}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Report Time Horizon"
                />
              )}
              onChange={(e, newValue) => {
                setSelectedTimeHorizon(newValue);
              }}
            />
          </div>
        </div>

        {/* Basic Report Filters */}
        {selectedNickname != '' &&
          selectedReportType?.TabularReportName != null &&
          selectedTimeHorizon != null && (
            <div>
              <h5>
                Add Basic Filters (Optional)
                {openModalSections.basicFilters ? (
                  <ExpandLess
                    onClick={() => toggleModalSection('basicFilters')}
                  />
                ) : (
                  <ExpandMore
                    onClick={() => toggleModalSection('basicFilters')}
                  />
                )}
              </h5>

              <Collapse
                in={openModalSections.basicFilters}
                timeout="auto"
                unmountOnExit
              >
                <div className="mb-2">
                  Locations:
                  <Autocomplete
                    autoHighlight={true}
                    multiple
                    id="report-locations"
                    size="small"
                    filterSelectedOptions
                    options={props.formOptions.reportLocations}
                    getOptionLabel={(option) => option.Path}
                    value={selectedLocations}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          selectedLocations.length > 0 ? '' : 'Any Location'
                        }
                      />
                    )}
                    onChange={(e, newValue) => {
                      setSelectedLocations(newValue);
                    }}
                  />
                </div>

                <div className="mb-2">
                  {selectedReportType.TabularReportName === 'Entities Created'
                    ? 'Created By:'
                    : selectedReportType.TabularReportName ===
                        'Entities Updated'
                      ? 'Updated By:'
                      : selectedReportType.TabularReportName ===
                          'Entities Moved'
                        ? 'Moved By:'
                        : ''}

                  <Autocomplete
                    autoHighlight={true}
                    multiple
                    id="report-actionBy"
                    size="small"
                    filterSelectedOptions
                    options={props.formOptions.elmUsers}
                    getOptionLabel={(option) => option.UserName}
                    value={selectedActionBy}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          selectedActionBy.length > 0 ? '' : 'Any Users'
                        }
                      />
                    )}
                    onChange={(e, newValue) => {
                      setSelectedActionBy(newValue);
                    }}
                  />
                </div>

                <div className="mb-2">
                  Entity Types:
                  <Autocomplete
                    autoHighlight={true}
                    multiple
                    id="report-entityTypes"
                    size="small"
                    filterSelectedOptions
                    options={props.formOptions.entityTypes}
                    value={selectedEntityTypes}
                    getOptionLabel={(option) => option.EntityType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          selectedEntityTypes.length > 0
                            ? ''
                            : 'Any Entity Type'
                        }
                      />
                    )}
                    onChange={(e, newValue) => {
                      setSelectedEntityTypes(newValue);
                    }}
                  />
                </div>

                {/* NOTE: Non-distinct entity names makes this tough - push out to later */}
                {false && (
                  <div className="mb-2">
                    Specific Entities
                    <Autocomplete
                      autoHighlight={true}
                      multiple
                      id="report-entityNames"
                      size="small"
                      filterSelectedOptions
                      // options={props.formOptions.entityNames}
                      getOptionLabel={(option) => option.EntityName}
                      value={selectedReportEntities}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={
                            selectedReportEntities.length > 0
                              ? ''
                              : 'Any Entity Name'
                          }
                        />
                      )}
                      onChange={(e, newValue) => {
                        setSelectedReportEntities(newValue);
                      }}
                    />
                  </div>
                )}
              </Collapse>

              {/* Metadata (Advanced) Filters */}
              <h5 className="mt-3">
                Add Advanced Filters (Optional)
                {openModalSections.advancedFilters ? (
                  <ExpandLess
                    onClick={() => toggleModalSection('advancedFilters')}
                  />
                ) : (
                  <ExpandMore
                    onClick={() => toggleModalSection('advancedFilters')}
                  />
                )}
              </h5>

              <Collapse
                in={openModalSections.advancedFilters}
                timeout="auto"
                unmountOnExit
              >
                {selectedMetaFilters.map((f, i) => {
                  return (
                    <div key={i} className="row">
                      {/* Metadata Type Control */}
                      <div className="col mb-1">
                        {i === 0 ? 'Field:' : ''}
                        <Autocomplete
                          autoHighlight={true}
                          disableClearable
                          className="type-ahead"
                          id="report-metaType"
                          options={props.formOptions.metaFilters}
                          getOptionLabel={(option) =>
                            option.EntityMetaType || ''
                          }
                          value={selectedMetaFilters[i].field || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search Fields"
                            />
                          )}
                          onChange={(e, newValue) => {
                            let newMetaFilters = [...selectedMetaFilters];
                            newMetaFilters[i].field = newValue;
                            newMetaFilters[i].fieldValues = [];
                            setSelectedMetaFilters(newMetaFilters);
                          }}
                        />
                      </div>

                      {/* Metadata Values Control */}
                      <div className="col mb-1">
                        {i === 0 ? 'Field Value(s):' : ''}
                        <DebouncedMetaValuesAutocomplete
                          index={i}
                          fieldId={
                            selectedMetaFilters[i].field.EntityMetaType_DBID
                          }
                          fieldValues={selectedMetaFilters[i].fieldValues}
                          selectedMetaFilters={selectedMetaFilters}
                          setSelectedMetaFilters={setSelectedMetaFilters}
                        />
                      </div>

                      {/* Delete Control */}
                      <div
                        className={i === 0 ? 'col mt-4' : 'col mt-1'}
                        style={{
                          maxWidth: '5%',
                          padding: '0px',
                        }}
                      >
                        <Trash
                          color="red"
                          className="pointer"
                          onClick={() => removeAdvancedFilter(i)}
                          size={22}
                        />
                      </div>
                    </div>
                  );
                })}
                <Hint delay={{ show: 250, hide: 400 }} title="Add New Filter">
                  <PlusCircle
                    className="mt-2 ms-1"
                    color="blue"
                    onClick={addAdvancedFilter}
                    size={20}
                  />
                </Hint>
              </Collapse>

              {/* Report Summary */}
              <h5 className="mt-3">
                Report Summary
                {openModalSections.reportSummary ? (
                  <ExpandLess
                    onClick={() => toggleModalSection('reportSummary')}
                  />
                ) : (
                  <ExpandMore
                    onClick={() => toggleModalSection('reportSummary')}
                  />
                )}
              </h5>

              <Collapse
                in={openModalSections.reportSummary}
                timeout="auto"
                unmountOnExit
              >
                <pre className="pre-tag">{generateReportSummary()}</pre>
              </Collapse>
            </div>
          )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.handleClose}>
          Cancel
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            props.showTabularReport(generateReportSummary(), buildReportJson());
          }}
          disabled={isSaveReportButtonDisabled()}
        >
          Preview
        </Button>

        <Button
          variant="primary"
          onClick={(e) => {
            e.currentTarget.disabled = true;
            addTabularReport(reportId, userSubscriptionId);
          }}
          disabled={isSaveReportButtonDisabled()}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TabularReportForm;
