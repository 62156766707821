import React, { Component } from 'react';
//import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { Button, Stack } from 'react-bootstrap';
import { Api } from './interface';
import HistoryList from './historyList';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { Hint } from './constants.js';
import { formatHistoryResponse } from './common';
import { shortDate } from './date';

class EntityHistory extends Component {
  constructor(props) {
    super(props);

    this.tableCellHover = [
      {
        '&:hover': {
          backgroundColor: '#BDBDBD',
        },
      },
    ];

    this.textInput = React.createRef();
    this.commentInput = React.createRef();
    this.historyRef = React.createRef();

    this.state = {
      entities: [],
      parentEntityList: [
        { Entity_DBID: 0, EntityName: 'No Parent', EntityType_DBID: 0 },
      ],
      items: [],
      filteredItems: [],
      entityTypes: [],
      template: [],
      filteredTemplate: [],
      searchText: '',
      statuses: [],
      metaOptions: [],
      fieldArray: [],
      currentEntity: {},
      currentEntityId: -1,
      isOpen: false,
      editing: false,
      cloning: this.props.cloning,
      creating: this.props.creating,
      deleting: false,
      statusEdit: -1,
      commenting: this.props.commenting,
      showHistory: true,
      comment: '',
      currentGroupChildren: [],
      currentHistory: [],
      filteredHistory: [],
      currentFilterText: '',
      filterTerm: '',
      filteredRows: [],
      rawTemplate: [],
      newEntityName: '',
      newEntityParentId: 0,
      newEntityTotalQuantity: '',
      newEntityCurrentQuantity: '',
    };
  }

  componentDidMount() {
    this.startComment();
  }

  startComment = () => {
    Api({
      sp: 'getEntityMetaHistory',
      json: { id: this.props.item.Entity_DBID },
    }).then((response) => {
      response = formatHistoryResponse(response);

      // Add Created Date object to history
      const createdDateObj = {
        EntityMetaType: 'Created Date',
        EntityMetaValue: response[0].EntityCreatedDtm,
        CreatedDtm: response[0].EntityCreatedDtm,
        CreatedBy: response[0].CreatedBy,
        PrevEntityMetaValue: '',
      };
      response.unshift(createdDateObj);

      if (this.props.archiveMode) {
        Api({
          sp: 'getArchiveDetails',
          json: { id: this.props.item.Entity_DBID },
        }).then((res) => {
          response.push({
            EntityMetaType: 'Archive Reason',
            EntityMetaValue: res[0].Reason_Label,
            CreatedDtm: res[0].CreatedDtm,
            CreatedBy: res[0].CreatedBy,
            PrevEntityMetaValue: '',
          });
          if (res[0].Comments !== '' && res[0].Comments !== null) {
            response.push({
              EntityMetaType: 'Archive Comment',
              EntityMetaValue: res[0].Comments,
              CreatedDtm: res[0].CreatedDtm,
              CreatedBy: res[0].CreatedBy,
              PrevEntityMetaValue: '',
            });
          }

          this.setState({
            currentHistory: response,
            filteredRows: response,
            filteredHistory: response,
            currentEntity: this.props.item,
            commenting: true,
          });
          this.historyScrollToBottom('auto');
        });
      } else {
        this.setState({
          currentHistory: response,
          filteredRows: response,
          filteredHistory: response,
          currentEntity: this.props.item,
          commenting: true,
        });
        this.historyScrollToBottom('auto');
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        creating: this.props.creating,
        renaming: this.props.renaming,
        cloning: this.props.cloning,
        commenting: this.props.commenting,
      });
    }
  }

  openHistory = () => {
    this.commentInput.current.focus();
    this.historyScrollToBottom('auto');
  };

  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value });
    if (e.keyCode === 13) {
      this.addComment(e.target.value);
    }
  };

  addComment = (comment) => {
    if (!comment.length) {
      return;
    }
    Api({
      sp: 'addEntityComment',
      json: {
        id: this.state.currentEntity.Entity_DBID,
        val: comment ? comment : this.state.comment,
      },
    }).then((response) => {
      let history = this.state.currentHistory;
      history.push(response[0]);
      this.commentInput.current.value = '';
      this.commentInput.current.focus();
      this.setState(
        {
          currentHistory: history,
          filteredRows: this.state.showHistory
            ? history
            : history.filter((x) => x.EntityMetaType === 'EntityComment'),
          filteredHistory: this.state.showHistory
            ? history
            : history.filter((x) => x.EntityMetaType === 'EntityComment'),
          comment: '',
        },
        () => {
          this.filterList(this.state.searchText);
          this.historyScrollToBottom('smooth');
        }
      );
    });
  };

  filterHistory = (show) => {
    let history = [];
    if (show) {
      this.setState({ searchText: '' });
      history = this.state.currentHistory;
    } else {
      this.setState({ searchText: '' });
      history = this.state.currentHistory.filter(
        (x) => x.EntityMetaType === 'EntityComment'
      );
    }
    this.setState({
      filteredHistory: history,
      filteredRows: history,
      showHistory: !this.state.showHistory,
    });
    this.historyScrollToBottom('auto');
  };

  historyScrollToBottom = (behavior) => {
    setTimeout(() => {
      this.historyRef.current.scrollIntoView({ behavior: behavior });
    }, 0);
  };

  filterList = (filter) => {
    // Save search text to state
    this.setState({ searchText: filter });

    // Filter response by search bar text
    let filteredRows = this.state.filteredHistory.filter(
      (f) =>
        f.EntityMetaValue.toLowerCase().includes(filter.toLowerCase()) ||
        f.CreatedBy.toLowerCase().includes(filter.toLowerCase()) ||
        f.EntityMetaType.toLowerCase().includes(filter.toLowerCase()) ||
        (f.EntityMetaType !== 'EntityComment' &&
          f.PrevEntityMetaValue.toLowerCase().includes(filter.toLowerCase())) ||
        shortDate(f.CreatedDtm).includes(filter) ||
        new Date(f.CreatedDtm).toLocaleString().includes(filter) ||
        filter === ''
    );

    this.setState({ filteredRows: filteredRows, page: 0 });
  };

  render = () => {
    return (
      <Modal
        onEntered={() => this.openHistory()}
        size="lg"
        show={this.props.open}
        onHide={this.props.close}
        className="entity-modal"
      >
        <Modal.Header closeButton="true">
          <Modal.Title>{this.props.item.EntityName} History</Modal.Title>
        </Modal.Header>
        <Stack className="pb-2" direction="horizontal" gap={3}>
          {/* Search Bar */}
          <Form.Control
            className="form-control w100 mt-2 mx-2"
            value={this.state.searchText}
            type="text"
            placeholder="Search"
            onChange={(e) => this.filterList(e.target.value)}
            autoFocus
          />
        </Stack>
        <Modal.Body>
          <div className="fieldList-container">
            <HistoryList items={this.state.filteredRows} />
            <div ref={this.historyRef}></div>
          </div>
          <hr />
          <Stack className="pb-2" direction="horizontal" gap={3}>
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Toggle all activity"
            >
              {this.state.showHistory ? (
                <Eye
                  color="blue"
                  className="pointer"
                  size={24}
                  onClick={() => this.filterHistory(false)}
                />
              ) : (
                <EyeSlash
                  color="blue"
                  className="pointer"
                  size={24}
                  onClick={() => this.filterHistory(true)}
                />
              )}
            </Hint>
            <Form.Control
              className="form-control w100"
              ref={this.commentInput}
              onKeyUp={(e) => this.handleCommentChange(e)}
              type="text"
              placeholder="Comment"
              autoFocus
            />
            <Button
              className="btn btn-primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                this.addComment(this.state.comment);
              }}
              disabled={!this.state.comment.length}
            >
              Post
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>
    );
  };
}
export default EntityHistory;
