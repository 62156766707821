import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Api } from './interface';

export default function EntityOwnerChange(props) {
  const [users, setUsers] = useState([]);
  const [currentOwner] = useState({
    UserName: 'No User Selected',
    User_DBID: 0,
  });

  useEffect(() => {
    Api({
      sp: 'getUsers',
      json: {},
    }).then((response) => {
      setUsers(response);
    });
  }, []);

  const updateLocation = (newValue) => {
    // get current row id or selected row ids
    const ids = props.items.map((x) => x.Entity_DBID);
    Api({
      sp: 'updateEntityOwner',
      json: {
        entityIds: ids,
        owner: newValue.User_DBID,
        ownerName: newValue.UserName,
      },
    }).then((response) => {
      if (response[0].error) {
        toast.error(response[0].error);
      } else {
        toast.success(
          'Successfully updated owner for ' +
            (props.items.length > 1
              ? 'selected equipments'
              : props.items[0].EntityName) +
            '  to ' +
            newValue.UserName
        );
        props.close(true);
      }
    });
  };

  return users.length ? (
    <Modal
      size="lg"
      show={props.open}
      onHide={() => props.close()}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>
          Change Owner For{' '}
          {props.items.length > 1
            ? 'Selected Equipments'
            : props.items[0].EntityName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {users.length ? (
          <Autocomplete
            autoHighlight={true}
            disablePortal
            id="parent-lookup"
            size="small"
            options={users}
            disableClearable
            value={currentOwner}
            sx={{ width: '100%' }}
            getOptionLabel={(option) => {
              return option.UserName;
            }}
            onChange={(event, newValue) => {
              updateLocation(newValue);
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.Entity_DBID}>
                  {option.UserName}
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Owners" />}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  ) : null;
}
