import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Api, SendEmail } from './interface';
import { DebounceInput } from 'react-debounce-input';
import { isInteger } from './validator';

const TransactionRequestCreate = (props) => {
  const [requesting] = useState(props.requesting);
  const [users, setUsers] = useState([{ User_DBID: -1, UserName: 'No Owner' }]);
  const [request, setRequest] = useState(props.request);
  const [items, setItems] = useState(props.items);
  const [newOwner, setNewOwner] = useState({
    User_DBID: -1,
    UserName: 'Select Owner',
  });

  const [totalReserved, setTotalReserved] = useState(0);
  const totalReservedRef = useRef(totalReserved);
  const setTotalReservedRef = (data) => {
    totalReservedRef.current = data;
    setTotalReserved(data);
  };

  useEffect(() => {
    setItems(props.items);
    setRequest(props.request);
    let r = props.request;
    r.reserved = r.reserved ?? [];
    let totalqty = 0;
    r.reserved?.forEach((x) => (totalqty = totalqty + Number(x.reserve ?? 0)));
    r.Total = totalqty;
    setTotalReservedRef(totalqty);
    if (r.owner) {
      setNewOwner(r.owner);
    }
    if (!props.cartActive) {
      items.forEach((x) => {
        if (
          !request.reserved?.some(
            (r) => r.EntityMetaValue === x.EntityMetaValue || x.Available < 1
          )
        ) {
          x.reserve = 0;
          r.reserved.push(x);
        }
      });
    }

    setRequest(r);

    Api({
      sp: 'getUsers',
      json: {},
    }).then((users) => {
      setUsers(users);
    });
  }, [props.items, props.request, request, props.cartActive]);

  const createRequest = () => {
    let req = props.request;
    req.locationId = props.location;
    req.reserved = req.reserved?.filter((x) => x.reserve.toString() !== '0');
    let totalqty = 0;
    req.reserved?.forEach((x) => (totalqty = totalqty + Number(x.reserve)));
    req.Total = totalqty;
    req.entityOwner = users.filter((x) => x.User_DBID === props.entityOwner)[0];
    req.matType = props.matType;

    Api({
      sp: 'addTransactionRequest',
      json: req,
    }).then((response) => {
      toast.success('Request(s) created');

      props.refresh(props.request);
      setRequest({ reserved: [] });
      props.update({ reserved: [] });
      props.closeModal();

      response.forEach((row) => {
        const transactionId = row.Transaction_DBID;
        SendEmail('instantEmailGiveGotRequest', {
          emailSubject: 'Request ' + transactionId.toString().padStart(8, '0'),
          transactionId: transactionId,
          status: '(Blank) → Request',
          action: 'Request',
        });
      });
    });
  };

  const reserve = (e, item) => {
    if (!isInteger(e.target.value)) {
      toast.error('Value must be a number');
      return;
    }
    if (e.target.value < 0) {
      toast.error('The number of units should be more than 0');
      return;
    }
    if (e.target.value > item.Available) {
      toast.error('Cannot reserve more units than are available');
      return;
    }
    let r = request;
    r.reserved.filter(
      (x) => x.EntityMetaValue === item.EntityMetaValue
    )[0].reserve = e.target.value;
    let totalqty = 0;
    r.reserved.forEach((x) => (totalqty = totalqty + Number(x.reserve)));
    r.Total = totalqty;
    setTotalReservedRef(totalqty);
    setRequest(r);
    props.update(r);
  };

  const getConfirmDisabled = () => {
    let requestCheck = true;
    if (
      !newOwner ||
      newOwner.User_DBID === -1 ||
      totalReservedRef.current < 1
    ) {
      requestCheck = false;
    }
    return requestCheck;
  };

  const updateOwner = (val) => {
    let r = request;
    r.owner = val;
    setRequest(r);
    setNewOwner(val);
    props.update(r);
  };

  const comment = (val) => {
    let r = request;
    r.comment = val.target.value;
    setRequest(r);
    props.update(r);
  };

  const clearRequest = () => {
    props.update({ reserved: [] });
    props.closeModal();
  };

  const saveRequest = () => {
    let r = request;
    r.reserved = r.reserved?.filter((x) => x.reserve.toString() !== '0');
    setRequest(r);
    props.update(r);

    props.closeModal();
  };

  return (
    <Modal
      size="lg"
      show={requesting}
      onHide={() => props.closeModal()}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>
          Request Units From {request.reserved[0]?.EntityOwner}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          Requested For
          <Autocomplete
            autoHighlight={true}
            className="type-ahead"
            id="entity-type"
            options={
              props.isClassStoreMember
                ? users
                : users.filter((u) => u.UserName !== 'Material Storage')
            }
            value={request.owner ?? newOwner}
            getOptionLabel={(option) => option.UserName}
            renderOption={(props, option) => (
              <li {...props} key={option.User_DBID}>
                <span>{option.UserName}</span>
              </li>
            )}
            hiddenlabel="true"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Select Owner"
              />
            )}
            onChange={(_event, newType) => {
              updateOwner(newType);
            }}
          />
        </div>
        <div className="request-create-container">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <h4>{items[0]?.EntityMetaType}</h4>
              </div>
              <div className="col text-center">
                <h4>Available</h4>
              </div>
              <div className="col text-center">
                <h4>Reserve</h4>
              </div>
            </div>
          </div>
          {request.reserved.map((x, i) => {
            return (
              <div key={i} className="row mt-2">
                <div className="col text-center">
                  <h6>{x.EntityMetaValue}</h6>
                </div>
                <div className="col text-center">
                  <h6>{x.Available}</h6>
                </div>
                <div className="col text-center">
                  <DebounceInput
                    value={x.reserve}
                    className="form-control"
                    onChange={(event) => {
                      if (event.target.value !== '') {
                        reserve(event, x);
                      }
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <br />
        <div className="mb-2">
          Comments
          <DebounceInput
            className="form-control"
            value={request.comment}
            rows="3"
            element="textarea"
            onChange={(e) => comment(e)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => clearRequest()}>
          Clear
        </Button>
        <Button variant="outline-primary" onClick={() => saveRequest()}>
          Save
        </Button>
        <Button
          variant="primary"
          disabled={!getConfirmDisabled()}
          onClick={(e) => {
            e.currentTarget.disabled = true;
            createRequest(false);
          }}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransactionRequestCreate;
