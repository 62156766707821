import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Api } from './interface';

export default function TokenAdd(props) {
  //   const [maps, setMaps] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [token, setToken] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [sps, setSps] = useState([]);
  const [sp, setSp] = useState(null);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Api({
      sp: 'getpublicAPIs',
      json: {},
    }).then((response) => {
      setSps([...new Set(response.map((x) => x.Procedure))]);
    });
    Api({
      sp: 'getUsers',
      json: {},
    }).then((response) => {
      setUsers(response);
    });
    Api({
      sp: 'getAPIClients',
      json: {},
    }).then((response) => {
      setClients([...new Set(response.map((x) => x.ClientName))]);
    });
    Api({
      sp: 'getAPITokens',
      json: {},
    }).then((response) => {
      setTokens(response);
    });
  };

  const addClient = () => {
    Api({
      sp: 'addAPIClient',
      json: { clientName: client, userId: user.User_DBID },
    }).then(() => {
      toast.success('Client added');
      props.close();
    });
  };

  const addToken = () => {
    Api({
      sp: 'addAPIToken',
      json: { clientName: client },
    }).then(() => {
      toast.success('Token added');
      props.close();
    });
  };

  const addMap = () => {
    Api({
      sp: 'addAPIMap',
      json: {
        tokenId: tokens.find((x) => x.Token === token).Token_DBID,
        sp: sp,
      },
    }).then(() => {
      toast.success('Map added');
      props.close();
    });
  };

  const handleAdd = () => {
    if (props.type === 'Client') {
      addClient();
    } else if (props.type === 'Token') {
      addToken();
    } else if (props.type === 'Map') {
      addMap();
    } else {
      props.close();
    }
  };

  return (
    <Modal
      show={props.open}
      onHide={() => props.close()}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Add {props.type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.type === 'Client' ? (
          <>
            <Form.Control
              type="text"
              onChange={(e) => setClient(e.target.value)}
              autoFocus
              placeholder="Enter Client Name"
              className="mb-2"
            />
            <Autocomplete
              autoHighlight={true}
              className="type-ahead"
              id="user"
              options={users}
              getOptionLabel={(option) => option.UserName}
              hiddenlabel="true"
              value={user}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Owner"
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.User_DBID}>
                  <span>{option.UserName}</span>
                </li>
              )}
              onChange={(_event, newValue) => {
                setUser(newValue);
              }}
            />
          </>
        ) : props.type === 'Token' ? (
          <Autocomplete
            autoHighlight={true}
            className="type-ahead"
            id="clients"
            options={clients}
            getOptionLabel={(option) => option}
            hiddenlabel="true"
            value={client}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Select Client"
              />
            )}
            onChange={(_event, newValue) => {
              setClient(newValue);
            }}
          />
        ) : props.type === 'Map' ? (
          <>
            <Autocomplete
              autoHighlight={true}
              className="type-ahead mb-2"
              id="clients"
              options={clients}
              getOptionLabel={(option) => option}
              hiddenlabel="true"
              value={client}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Client"
                />
              )}
              onChange={(_event, newValue) => {
                setClient(newValue);
                setFilteredTokens(
                  tokens
                    .filter((x) => x.ClientName === newValue)
                    .map((x) => x.Token)
                );
                setToken(null);
              }}
            />
            <Autocomplete
              autoHighlight={true}
              className="type-ahead mb-2"
              id="tokens"
              options={filteredTokens}
              getOptionLabel={(option) => option}
              hiddenlabel="true"
              value={token}
              disabled={!client || !tokens}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Token"
                />
              )}
              onChange={(_event, newValue) => {
                setToken(newValue);
              }}
            />
            <Autocomplete
              autoHighlight={true}
              className="type-ahead"
              id="sps"
              options={sps}
              getOptionLabel={(option) => option}
              hiddenlabel="true"
              value={sp}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Procedure"
                />
              )}
              onChange={(_event, newValue) => {
                setSp(newValue);
              }}
            />
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAdd}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
